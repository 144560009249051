import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { useAppSelector } from 'src/app/hooks'
import { getCurrentLanguage } from 'src/features/main/main.slice'

interface Props {
  type: 'Source' | 'Target'
  sourceLanguageCode?: Nullable<string>
  selectedLanguageCode: Nullable<string> // 배열 ㄴㄴ
  onChange: (option) => void
  disabled?: boolean
  placeholder: string
}

const sourceLanguageList = [
  { label: '한국어', value: 'ko' },
  { label: '영어', value: 'en' },
]

const sourceLanguageListEn = [
  { label: 'Korean', value: 'ko' },
  { label: 'English', value: 'en' },
]

const targetLanguageList = {
  ko: [
    { label: '아랍어', value: 'ar' },
    { label: '네덜란드어', value: 'nl' },
    { label: '영어', value: 'en' },
    { label: '독일어', value: 'de' },
    { label: '일본어', value: 'ja' },
    { label: '러시아어', value: 'ru' },
    { label: '중국어 간체', value: 'zh-Hans' },
    { label: '태국어', value: 'th' },
    { label: '터키어', value: 'tr' },
    { label: '베트남어', value: 'vi' },
    { label: '중국어 번체', value: 'zh-Hant' },
  ],
  en: [
    { label: '한국어', value: 'ko' },
    { label: '프랑스어', value: 'fr' },
    { label: '독일어', value: 'de' },
    { label: '러시아어', value: 'ru' },
    { label: '스웨덴어', value: 'sv' },
    { label: '태국어', value: 'th' },
    { label: '포르투갈어', value: 'pt' },
  ],
}

const targetLanguageListEn = {
  ko: [
    { label: 'Arabic', value: 'ar' },
    { label: 'Dutch', value: 'nl' },
    { label: 'English', value: 'en' },
    { label: 'German', value: 'de' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Russian', value: 'ru' },
    { label: 'Simplified Chinese', value: 'zh-Hans' },
    { label: 'Thai', value: 'th' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Vietnamese', value: 'vi' },
    { label: 'Traditional Chinese', value: 'zh-Hant' },
  ],
  en: [
    { label: 'Korean', value: 'ko' },
    { label: 'French', value: 'fr' },
    { label: 'German', value: 'de' },
    { label: 'Russian', value: 'ru' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Thai', value: 'th' },
    { label: 'Portuguese', value: 'pt' },
  ],
}

export default function TranslationLanguageSelect(props: Props) {
  const currentLanguage = useAppSelector(getCurrentLanguage)

  const options = useMemo(() => {
    if (currentLanguage === 'ko') {
      return props.type === 'Source'
        ? sourceLanguageList
        : targetLanguageList[props?.sourceLanguageCode] || []
    } else {
      return props.type === 'Source'
        ? sourceLanguageListEn
        : targetLanguageListEn[props?.sourceLanguageCode] || []
    }
  }, [props.type, props.sourceLanguageCode, currentLanguage])

  return (
    <Select
      options={options}
      value={
        options.find(
          (language) => language.value === props.selectedLanguageCode,
        ) || null
      }
      isDisabled={props?.disabled || false}
      isClearable
      onChange={props.onChange}
      placeholder={props.placeholder}
      styles={{
        control: (base) => {
          return {
            ...base,
            borderColor: `${
              props.selectedLanguageCode ? '#1a61f7' : '#aaaaaa'
            }`,
          }
        },
      }}
    />
  )
}

const Container = styled.div``
