import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { createProjectGroup, uploadLocalVideoProcess } from './request.service'

export type ProjectType = 'SUBTITLE_HUB'
export type TaskType = 'TRANSLATION' | 'TRANSCRIPTION'

interface YoutubeThumbnail {
  height: number
  width: number
  url: string
}

interface SourceData {
  id: string
  kind: string
  etag: string
  title: string
  description: string
  descriptionLength: number
  publishedAt: string
  channelId: string
  categoryId: string
  thumbnail: YoutubeThumbnail
  defaultLanguage: string
  defaultAudioLanguage: string
  runtime: number
  caption: boolean
  url: string
}

export interface RequestProject {
  // id: number // auto increment, 1 ~ 10
  id: string
  projectType: ProjectType
  taskType: TaskType
  genre?: string
  fileName?: string
  subtitles: any
  amount: number
  type: number
  runtime: number // youtube
  thumbUrl: string // youtube
  sourceUrl: string // youtube
  titleSource: string // youtube
  descriptionSource: string // youtube
  sourceLanguageCode: string // youtube
  targetLanguageCode: string[]
  dueAt?: Date // 결제 완료할때 모든 request가 동일한 dueAt이 적용되어야함
  autoCaptionUpload: boolean
  descriptionTranslationRequested: boolean
  descriptionPricePerLength?: number
  finalAmount: number
  usableBalance: number
  rate: {
    translation: number
    transcription: number
    descMore: number
    descLess: number
  }

  duration?: number
  isHub: boolean
  currency: 'KRW'
}

interface RequestState {
  isLoading: boolean
  requests: RequestProject[]
  dueAt?: Date
  projectGroupId: number
  rate: any
}

const initialState: RequestState = {
  isLoading: false,
  requests: [],
  projectGroupId: null,
  rate: {
    translation: 5000,
    trascription: 2000,
    descMore: 4000,
    descLess: 2000,
  },
}

const TRANSLATION_AMOUNT = 5000
const TRANSCRIPTION_AMOUNT = 2000

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    initRequest: (state) => {
      state.requests = []
    },
    setRequestVideoData: (
      state,
      action: PayloadAction<{
        id: string
        type?: number
        sourceData: SourceData
        autoCaptionUpload: boolean
        balance: number
        rate: {
          translation: number
          transcription: number
          descMore: number
          descLess: number
        }
      }>,
    ) => {
      const { id, type, sourceData, autoCaptionUpload, balance, rate } =
        action.payload
      const minute = Math.ceil(sourceData.runtime / 60)
      const amount = minute * rate.translation
      const sourceLanguageCode =
        sourceData.defaultLanguage || sourceData.defaultAudioLanguage
      const newRequest: RequestProject = {
        id: id ?? sourceData.id + state.requests.length + 1,
        projectType: 'SUBTITLE_HUB',
        taskType: 'TRANSLATION',
        type,
        subtitles: [],
        amount,
        titleSource: sourceData.title,
        descriptionSource: sourceData.description,
        descriptionTranslationRequested: false,
        sourceLanguageCode,
        autoCaptionUpload,
        targetLanguageCode: [],
        runtime: sourceData.runtime,
        sourceUrl: sourceData.url,
        thumbUrl: sourceData.thumbnail?.url,
        descriptionPricePerLength: 0,
        isHub: true,
        currency: 'KRW',
        finalAmount: amount,
        usableBalance: balance,
        rate: rate,
      }
      state.requests = [...state.requests, newRequest]
    },
    setAllRequests: (
      state,
      action: PayloadAction<{ requests: RequestProject[] }>,
    ) => {
      state.requests = [...action.payload.requests]
    },
    setAllRequestsDueAt: (state) => {
      const dueAt = new Date()
      state.requests = state.requests.map((request) => {
        return {
          ...request,
          dueAt,
        }
      })
    },
    setOneRequest: (
      state,
      action: PayloadAction<{
        newRequest: RequestProject
      }>,
    ) => {
      const { newRequest } = action.payload
      const copyNewRequest = { ...newRequest }
      state.requests = state.requests.map((request) => {
        const minute = Math.ceil(request.runtime / 60)
        if (request.id === copyNewRequest.id) {
          if (copyNewRequest.taskType === 'TRANSCRIPTION') {
            copyNewRequest.amount = minute * request.rate.transcription
          } else {
            const amount =
              minute * request.rate.translation +
              (copyNewRequest.descriptionTranslationRequested
                ? copyNewRequest.descriptionPricePerLength
                : 0)

            copyNewRequest.amount = amount
          }
          return copyNewRequest
        }
        return request
      })
    },
    deleteOneRequest: (state, action: PayloadAction<{ requestId: string }>) => {
      state.requests = state.requests.filter(
        (request) => request.id !== action.payload.requestId,
      )
    },
    setProjectGroupId: (
      state,
      action: PayloadAction<{ projectGroupId: number }>,
    ) => {
      state.projectGroupId = action.payload.projectGroupId
    },
    setDueAt: (state, action: PayloadAction<{ dueAt: Date }>) => {
      state.dueAt = action.payload.dueAt
    },
    setIsLoading: (state, action: PayloadAction<{ loading: boolean }>) => {

      state.isLoading = action.payload.loading
    },
    setRate: (
      state,
      action: PayloadAction<{
        rate: {
          translation: number
          transcription: number
          descMore: number
          descLess: number
        }
      }>,
    ) => {
      state.rate = action.payload.rate
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProjectGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createProjectGroup.fulfilled, (state, { payload }: any) => {
        state.isLoading = false
      })
      .addCase(createProjectGroup.rejected, (state, { payload, error }) => {
        state.isLoading = false
      })
      .addCase(uploadLocalVideoProcess.pending, (state) => {
        state.isLoading = true
      })
      .addCase(uploadLocalVideoProcess.fulfilled, (state, { payload }: any) => {
        state.isLoading = false
      })
      .addCase(uploadLocalVideoProcess.rejected, (state, { payload, error }) => {
        state.isLoading = false
      })
  },
})

export const {
  initRequest,
  setRequestVideoData,
  setAllRequests,
  setAllRequestsDueAt,
  setOneRequest,
  deleteOneRequest,
  setProjectGroupId,
  setDueAt,
  setIsLoading,
} = requestSlice.actions

const selfState = (state: RootState) => state.request

export const getProjectGroupId = createSelector(
  selfState,
  (requestState) => requestState.projectGroupId,
)

export const getRates = createSelector(
  selfState,
  (requestState) => requestState.rate,
)

export const getProjectGroupNo = createSelector(
  selfState,
  (requestState) =>
    requestState.projectGroupId?.toString().padStart(4, '0') || '0000',
)

export const getDueAt = createSelector(
  selfState,
  (requestState) => requestState.dueAt,
)

export const getIsLoading = createSelector(
  selfState,
  (requestState) => requestState.isLoading,
)

export const getRequests = createSelector(selfState, (requestState) => {
  return requestState.requests
})

export const getRequestsLength = createSelector(
  selfState,
  (requestState) => requestState.requests.length,
)

export const getTranslationRequests = createSelector(
  selfState,
  (requestState) =>
    requestState.requests.filter(
      (request) => request.taskType === 'TRANSLATION',
    ),
)

export const getTranscriptionRequests = createSelector(
  selfState,
  (requestState) =>
    requestState.requests.filter(
      (request) => request.taskType === 'TRANSCRIPTION',
    ),
)

/**
 *      summary selectors
 *            1. 번역요청 개수 : getTranslationLength
 *            2. 자막요청 개수 : getTranscriptionLength
 *            3. 총 요청건수 : getRequestLength
 *            4. 총 영상길이 : getAllRuntime
 *            5. 총 결제금액 : getAllAmount
 */

export const getTranslationLength = createSelector(
  selfState,
  (requestState) =>
    requestState.requests.filter(
      (request) => request.taskType === 'TRANSLATION',
    ).length,
)

export const getTranscriptionLength = createSelector(
  selfState,
  (requestState) =>
    requestState.requests.filter(
      (request) => request.taskType === 'TRANSCRIPTION',
    ).length,
)

export const getAllRuntime = createSelector(selfState, (requestState) =>
  requestState.requests.reduce(
    (result, request) => result + Math.ceil(request.runtime / 60),
    0,
  ),
)

export const getAllAmount = createSelector(selfState, (requestState) => {
  return requestState.requests.reduce(
    (result, request) => result + request.amount,
    0,
  )
})

export const getAllDescription = createSelector(selfState, (requestState) => {
  return requestState.requests.filter(
    (request) => request.descriptionTranslationRequested,
  ).length
})

export const getUsableBalance = createSelector(
  selfState,

  (requestState) => {
    const balance = requestState.requests.map(
      (request) => request.usableBalance,
    )[0]
    const result = requestState.requests.reduce(
      (result, request) => result + request.amount,
      0,
    )
    return balance >= result ? result : balance
  },
)

export const getFinalAmount = createSelector(
  selfState,

  (requestState) => {
    const balance = requestState.requests.map(
      (request) => request.usableBalance,
    )[0]
    const result = requestState.requests.reduce(
      (result, request) => result + request.amount,
      0,
    )

    const usableBalance = balance >= result ? result : balance

    return result - usableBalance
  },
)

export default requestSlice.reducer
