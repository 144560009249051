import React from 'react'
import { useTranslation } from 'react-i18next'
import RoundButton from 'src/common/components/button/round-button'
import {
  ModalButtonGroup,
  ModalWrapper,
} from 'src/common/modals/modal-container'

import styled from 'styled-components'

interface Props {}

export default function OutOfBalanceModal(props: Props) {
  const { t } = useTranslation()
  const handleNavigate = () => {
    window.location.href = `/creator/request`
  }
  return (
    <ModalWrapper>
      <Header>
        <h2>{t(`my.leave.credit.text-4`)}</h2>
      </Header>
      <Body>
        <p>
        {t(`payment.outOfBalance-1`)}
          <br />
          {t(`payment.outOfBalance-2`)}
          <br />
          {t(`payment.outOfBalance-3`)}
        </p>
      </Body>
      <ModalButtonGroup>
        <RoundButton onClick={handleNavigate}>{t(`common.button.ok`)}</RoundButton>
      </ModalButtonGroup>
    </ModalWrapper>
  )
}

const Header = styled.div`
  text-align: center;
  h2 {
    font-size: 18px;
    font-weight: 700;
  }
`

const Body = styled.div`
  margin-top: 10px;
  color: #111111;
  text-align: center;
  p {
    font-size: 16px;
    line-height: 20px;
  }
`
