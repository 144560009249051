import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Pretendard' !important;

    &:not(.scrollbar-open)::-webkit-scrollbar {
      display: none;
    }

    &:not(.scrollbar-open) {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }

  body {
    background-color: #EEF3F8;
    font-size: 0.875rem;
  }

  .capitalize {
    text-transform: capitalize;
  }

  // mobile
  @media screen and (max-width: 959px) {
    .hide-on-bush {
      display: none !important;
    }
  }

  .scrollbar-open::-webkit-scrollbar {
    width: 3px;
  }

  .scrollbar-open::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 4.5px;
  }

  .scrollbar-open::-webkit-scrollbar-track {
    // background: rgba(11, 11, 11, .1);
  }
`

export default GlobalStyle
