type PostInfo = {
  buyerId: number | null
  buyerName: string
  buyerEmail: string
  orderId: string
  orderedAt: string
  productId: number
  productType: string
  productName: string
  productPrice: number
  creditPrice: number
  totalPrice: number
  currency: string
}

type PaymentParamType = {
  orderId: string
  creditPrice: number
  totalPrice: number
  productPrice: number
  productId: number
  productName: string
}

export default function paymentInfoTransformer(
  profile,
  payment: PaymentParamType,
): PostInfo {
  const obj = {
    buyerId: null,
    buyerName: '',
    buyerEmail: '',
    orderId: '',
    orderedAt: '',
    productId: 0,
    productType: 'Project Group',
    productName: '',
    productPrice: 0,
    creditPrice: 0,
    totalPrice: 0,
    currency: 'WON',
  }

  obj.buyerId = profile?.id
  obj.buyerName = profile?.nickname
  obj.buyerEmail = profile?.email
  obj.orderId = payment.orderId
  obj.orderedAt = new Date().toString()
  obj.productId = payment.productId
  obj.productName = payment.productName
  obj.productPrice = payment.productPrice
  obj.creditPrice = payment.creditPrice
  obj.totalPrice = payment.totalPrice

  return obj
}
