// src/locale/i18n.ts 파일

import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import ko from './translations/ko.json'
import en from './translations/en.json'

export const DEFAULT_LANGUAGE = 'ko'

export type LanguageType = 'ko' | 'en'

export const LANGUAGE_LIST = ['ko', 'en']

const resources = {
  en: {
    translation: en,
  },
  ko: {
    translation: ko,
  },
}

i18next.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
})

export default i18next
