import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { parseCSV, parseSrt } from 'src/common/functions/file.function'
import styled from 'styled-components'

interface Props {
  onRemoveFile: () => void
  onUpload: (subtitles, fileName) => void
  onCancel: () => void
}

export default function UploadSubtitleModal(props: Props) {
  const [uploadedFile, setUploadedFile] = useState(null)
  const [subtitles, setSubtitles] = useState(null)
  const { t } = useTranslation()

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.srt, .csv, .vtt',
    onDrop: (files) => {
      if (!files.length) return
      setUploadedFile(files[0])
    },
  })

  useEffect(() => {
    if (uploadedFile) {
      parseSubtitle(uploadedFile)
    }
  }, [uploadedFile])

  const parseSubtitle = (file) => {
    const reader = new FileReader()
    const extentions = file.name.split('.')
    const extention = extentions[extentions.length - 1]
    reader.onload = () => {
      let content = reader.result as string
      content = content.replaceAll(/\r/g, '')
      if ('srt' === extention) {
        const result = parseSrt(content)
        setSubtitles(result)
      } else if ('csv' === extention) {
        const result = parseCSV(content)
        setSubtitles(result)
      } else {
        // @todo 어떻게 하지
      }
    }

    reader.readAsText(file)
  }

  const handleDeleteFiles = () => {
    setUploadedFile(null)
    setSubtitles(null)
    props.onRemoveFile()
  }

  const handleUpload = () => {
    props.onUpload(subtitles || [], uploadedFile?.name)
    props.onCancel()
  }

  return (
    <Container>
      <Header>
        <h2>
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/icon-import-black.svg`}
            alt="file upload"
          />
          {t(`request.text-41`)}
        </h2>
      </Header>
      <Body>
        <TabContents>
          <div className="contents-upload">
            {!uploadedFile ? (
              <div {...getRootProps({ className: 'dropzone' })}>
                <p>
                  {t(`request.text-42`)}
                  <br />
                  {t(`request.text-422`)}
                </p>
                <button>{t(`request.text-43`)}</button>
                <input {...getInputProps()} />
              </div>
            ) : (
              <div className="uploaded">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/icon-file.svg`}
                  alt="file icon"
                />
                <div>
                  <p>{uploadedFile.name}</p>
                  <img
                    onClick={handleDeleteFiles}
                    src={`${process.env.PUBLIC_URL}/assets/img/delete-grey.svg`}
                    alt="delete icon"
                  />
                </div>
              </div>
            )}
          </div>
        </TabContents>
      </Body>
      <Footer>
        <NegativeButton onClick={props.onCancel}>
          {t(`request.text-44`)}
        </NegativeButton>
        <PositiveButton onClick={handleUpload}>
          {t(`request.text-45`)}
        </PositiveButton>
      </Footer>
    </Container>
  )
}

const Container = styled.div`
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;

  // desktop
  @media screen and (min-width: 959px) {
    width: 50%;
  }

  // mobile
  @media screen and (max-width: 959px) {
    width: 100%;
    margin: 0 25px;
  }
`

const Header = styled.div`
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;

  h2 {
    color: #111;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 1.4;

    img {
      margin-right: 5px;
    }
  }
`

const Body = styled.div`
  margin-top: 30px;
`

const TabContents = styled.div`
  padding: 15px;
  text-align: center;
  background: #f8f8f8;

  & > div.contents-upload {
    padding: 14px 0;

    button {
      margin-top: 16px;
      height: 36px;
      width: 200px;
      background-color: transparent;
      border: 1px solid #1a61f7;
      border-radius: 18px;
      color: #1a61f7;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.75px;
      line-height: 1.5;

      &:hover {
        background-color: #d1dffd;
      }
    }

    & > div.uploaded {
      & > div {
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        img {
          cursor: pointer;
        }
      }
    }
  }
`

const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 16px;

  & > button {
    flex: 1;
  }
`

const NegativeButton = styled.button`
  height: 48px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #1a61f7;
  border-radius: 24px;
  color: #1a61f7;
  cursor: pointer;
  font-family: SpoqaHanSans;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.25px;
  line-height: 48px;
  padding: 0 14px;

  &:hover {
    background-color: #d1dffd;
  }
`

const PositiveButton = styled.button`
  height: 48px;
  width: 100%;
  background-color: #1a61f7;
  border: none;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  font-family: SpoqaHanSans;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.25px;
  line-height: 48px;
  padding: 0 14px;

  &:hover {
    background-color: #19449e;
  }
`
