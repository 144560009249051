import parser from 'subtitles-parser'

export const parseCSV = (content) => {
  let result = content.split('\n').splice(1)
  const regex = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi
  result = result
    .map((line) => {
      let temp: string[] = line.match(
        /(?<order>\d+),"(?<start>[\d:,]+)\s+-*\>\s+(?<end>[\d:,]+){1}","(?<text>[\s\S]*?(?="{1}|$))/g,
      )
      if (!!temp) {
        const strings = /\d,"(.*)\s+-*\>\s+(\d+:\d+:\d+,\d+)","(.*)/.exec(
          temp[0],
        )
        if (!strings) return {}
        return {
          start: parseInt(strings[1].replace(regex, '')),
          end: parseInt(strings[2].replace(regex, '')),
          text: strings[3],
        }
      }
    })
    .filter(
      (subtitle) => subtitle?.start !== null && subtitle?.start !== undefined,
    )
  return result
}

export const parseSrt = (content) => {
  let result = parser.fromSrt(content, true)
  if (result) {
    return result.map((subtitle) => ({
      start: subtitle.startTime,
      end: subtitle.endTime,
      text: subtitle.text,
    }))
  }
  return null
}
