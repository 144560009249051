import { createSlice } from '@reduxjs/toolkit'

const youtubeSlice = createSlice({
  name: 'youtube',
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {},
})

export default youtubeSlice.reducer
