import axios from 'src/app/axios-client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { toSeconds } from 'duration-fns'

import mockChannelVideos from 'src/assets/data/mock-youtube-channel.json'

import mockDownSubtitle from 'src/assets/data/mock-down-subtitle.json'

import { makeQuery } from 'src/shared/helper/queryMaker'

interface YoutubeInfoParams {
  url: string
}

interface YoutubeListParams {
  q?: string
  pageToken?: string
  playListId?: string
}

export const getYoutubeInfo = createAsyncThunk(
  'youtube/info',
  async (params: YoutubeInfoParams, thunkAPI) => {
    try {
      const response = await axios.get(
        `/api/zarcian/youtube/info?video=${params.url}`,
      )
      const data = response.data.items[0]
      if (!data) throw new Error('NO_ITEMS')

      if (data.contentDetails.duration === '')
        throw new Error('DURATION_IS_EMPTY')
      const runtime = toSeconds(data.contentDetails.duration)
      const minute = Math.ceil(runtime / 60)
      if (runtime === 0) throw new Error('RUNTIME_IS_ZERO')
      else if (minute > 60) throw new Error('OVER_60_MINUTES')

      return {
        sourceData: {
          id: data.id,
          kind: data.kind,
          etag: data.etag,
          title: data.snippet.title,
          description: data.snippet.description,
          descriptionLength:
            data.snippet.description === ''
              ? 0
              : data.snippet.description.length,
          // description:
          //   '최대 두줄까지만 표시됩니다.최대 두줄까지만 표시됩니다.최대 두줄까지만 표시됩니다. 최대 두줄까지만 표시됩니다.최대 두줄까지만 표시됩니다.최대 두줄까지만 표시됩니다.최대 두줄까지만 표시됩니다.최대 두줄까지만 표시됩니다.최대 두줄까지만 표시됩니다. 최대 두줄까지만 표시됩니다.최대 두줄까지만 표시됩니다.최대 두줄까지만 표시됩니다.',
          publishedAt: data.snippet.publishedAt,
          channelId: data.snippet.channelId,
          categoryId: data.snippet.categoryId,
          thumbnail: data.snippet.thumbnails.medium,
          defaultLanguage: data.snippet.defaultLanguage,
          defaultAudioLanguage: data.snippet.defaultAudioLanguage,
          runtime,
          caption: data.contentDetails.boolean,
          url: params.url,
        },
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
    // }
  },
)

export const getYoutubeChannelVideos = createAsyncThunk(
  'youtube/my-channel-videos',
  async (params: YoutubeListParams, thunkAPI) => {
    if (window.location.href.includes('1ocalhost')) {
      const data = mockChannelVideos as any
      return {
        videoInfo: {
          channelTitle: data.items[0].snippet.channelTitle,
          sourceData: data.items.map((data) => {
            return {
              id: data.id?.videoId,
              kind: data.kind,
              etag: data.etag,
              title: data.snippet.title,
              description: data.snippet.description,
              publishedAt: data.snippet.publishedAt,
              channelId: data.snippet.channelId,
              thumbnail: data.snippet.thumbnails?.high?.url,
              url: `https://www.youtube.com/watch?v=${data.id?.videoId}`,
            }
          }),
          pageInfo: {
            totalResults: data.pageInfo.totalResults,
            resultsPerPage: data.pageInfo.resultsPerPage,
            nextPageToken: data?.nextPageToken,
            prevPageToken: data?.prevPageToken,
          },
        },
      }
    } else {
      try {
        const response = await axios.get(
          `/api/zarcian/youtube/caption/channel/videos?${makeQuery(params)}`,
          {
            headers: {
              gat: sessionStorage.getItem('gat'),
            },
          },
        )
        const data = response.data
        return {
          videoInfo: {
            channelTitle: data.items[0].snippet.channelTitle,
            sourceData: data.items.map((data) => {
              return {
                id: data.contentDetails?.videoId,
                kind: data.kind,
                etag: data.etag,
                title: data.snippet.title,
                description: data.snippet.description,
                publishedAt: data.snippet.publishedAt,
                channelId: data.snippet.channelId,
                thumbnail: data.snippet.thumbnails?.high?.url,
                url: `https://www.youtube.com/watch?v=${data.contentDetails?.videoId}`,
              }
            }),
            pageInfo: {
              totalResults: data.pageInfo.totalResults,
              resultsPerPage: data.pageInfo.resultsPerPage,
              nextPageToken: data?.nextPageToken,
              prevPageToken: data?.prevPageToken,
            },
          },
        }
      } catch (error) {
        return thunkAPI.rejectWithValue(error)
      }
    }
  },
)

export const getYoutubeSubtitle = createAsyncThunk(
  'youtube/subtitle',
  async (params: YoutubeInfoParams, thunkAPI) => {
    try {
      const response = await axios.get(
        `/api/zarcian/youtube/caption/list?videoLink=${params.url}`,
        {
          headers: {
            gat: sessionStorage.getItem('gat'),
          },
        },
      )
      const data = response.data.items
      return {
        subtitles: data.map((item) => {
          return {
            id: item.id,
            videoId: item.snippet.videoId,
            language: item.snippet.language,
          }
        }),
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const downYoutubeSubtitle = createAsyncThunk(
  'youtube/download/subtitle',
  async (params: YoutubeInfoParams, thunkAPI) => {
    if (window.location.href.includes('localhost')) {
      const data = mockDownSubtitle.data
      return data
    } else {
      const response = await axios.get(
        `/api/zarcian/youtube/caption/download?c=${params.url}`,
        {
          headers: {
            gat: sessionStorage.getItem('gat'),
          },
        },
      )
      return response.data.data
    }
  },
)

export const getYoutubeAvailability = createAsyncThunk(
  'youtube/available',
  async (url: string) => {
    return {
      result: false,
    }
  },
)

export const disconnectYoutube = createAsyncThunk(
  'youtube/disconnect',
  async (_, thunkAPI) => {
    try {
      const response = await axios.put(
        `/api/zarcian/youtube/caption/disconnect`,
        null,
        {
          headers: {
            gat: sessionStorage.getItem('gat'),
          },
        },
      )
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

// export const getYoutubeChannelInfo = createAsyncThunk(
//   'youtube/channel-info',
//   async (_, thunkAPI) => {
//     if (window.location.href.includes('1ocalhost')) {
//       const data = (mockChannelInfo as any).items[0]
//       return data?.contentDetails?.relatedPlaylists?.uploads
//     } else {
//       try {
//         const { data } = await axios.get(
//           `/api/zarcian/youtube/caption/channel/info`,
//           {
//             headers: {
//               gat: sessionStorage.getItem('gat'),
//             },
//           },
//         )
//         console.log(
//           'channel-info : ',
//           data?.items[0]?.contentDetails?.relatedPlaylists?.uploads,
//         )
//         return data?.items[0]?.contentDetails?.relatedPlaylists?.uploads
//       } catch (error) {
//         return thunkAPI.rejectWithValue(error)
//       }
//     }
//   },
// )

export const getYoutubeChannelInfo = createAsyncThunk(
  'youtube/get-channel-info',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `/api/zarcian/youtube/caption/channel/info`,
        {
          headers: {
            gat: sessionStorage.getItem('gat'),
          },
        },
      )
      const item = response.data?.items?.[0]

      return {
        channel: {
          id: item.id,
          title: item.snippet.title,
          description: item.snippet?.description || '',
          customUrl: item.snippet?.customUrl || '',
          profileImage: item.snippet?.thumbnails?.medium?.url || '',
          playListId: item?.contentDetails?.relatedPlaylists?.uploads,
        },
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
