import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { DEFAULT_LANGUAGE } from 'src/locale/i18n'

type LanguageType = 'ko' | 'en'

interface MainState {
  language: LanguageType
}

const initialState: MainState = {
  language: DEFAULT_LANGUAGE,
}

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<{ language: 'ko' | 'en' }>) => {
      state.language = action.payload.language
    },
  },
})

export const { setLanguage } = mainSlice.actions

const selfSelector = (state: RootState) => state.main

export const getCurrentLanguage = createSelector(
  selfSelector,
  (state) => state.language,
)

export default mainSlice.reducer
