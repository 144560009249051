import React, { useEffect, useState, useContext } from 'react'
import { RouteProps, useLocation, useNavigate } from 'react-router-dom'
import { authenticationService } from 'src/service/auth/auth.service'
import { ModalContext } from 'src/providers/modal.provider'
import NavigateLoginModal from 'src/common/modals/navigate-login-modal'

/**
 * @description 토큰 확인 -> 프로필 정보 조회 -> 목적지 이동 하거나 로그인 화면으로
 */
export default function ProtectedRoute({ element, ...rest }: RouteProps) {
  const { setModal } = useContext(ModalContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoggedIn, setIsLoggedIn] = useState(authenticationService.isLogin)

  const validation = () => {
    if (!isLoggedIn) {
      window.sessionStorage.setItem(
        'beforeLogin',
        location.pathname + location.search,
      )
      // navigate('/creator/user/login')
      setModal(<NavigateLoginModal />)
    }
  }

  useEffect(() => {
    validation()
  }, [isLoggedIn])

  if (!isLoggedIn) <React.Fragment></React.Fragment>
  return <React.Fragment>{element}</React.Fragment>
}
