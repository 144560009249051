import styled from 'styled-components'
import { useContext, useEffect, useRef } from 'react'
import { ModalContext } from 'src/providers/modal.provider'

import RoundButton from 'src/common/components/button/round-button'
import {
  ModalButtonGroup,
  ModalWrapper,
} from 'src/common/modals/modal-container'
import { useTranslation } from 'react-i18next'

export default function NavigateLoginModal() {
  const { t } = useTranslation()
  const msg = `<p>${t(`common.login.login-required1`)}<br />${t(`common.login.login-required2`)}</p>`
  const OKLabel=t(`common.button.confirm`)
  const CancelLabel=t(`common.button.cencel`)
  const messageRef = useRef<any>(null)
  const { setModal } = useContext(ModalContext)

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.innerHTML = msg
    }
  }, [msg])
  const handleLogin = () => {
    setModal(null)
    window.location.href = `/creator/user/login`
  }
  const handleOnCancel = () => {
    setModal(null)
  }
  return (
    <ModalContainer>
      <Message ref={messageRef} />
      <ModalButtonGroup>
        <RoundButton variant="outlined" onClick={handleOnCancel}>
          {CancelLabel}
        </RoundButton>
        <RoundButton onClick={handleLogin}>
          {OKLabel}
        </RoundButton>
      </ModalButtonGroup>
    </ModalContainer>
  )
}

export const ModalContainer = styled.div`
  margin: 25px;
  padding: 50px 57px 35px;
  border-radius: 8px;
  background: #fff;

  @media (max-width: 37.5em) {
    padding: 40px 25px 28px;
  }
`

const Message = styled.div`
  margin-bottom: 35px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
`
const BtnGroup = styled.div`
  margin: 0 auto;
  text-align: center;
  button {
    width: 100px;
    padding: 12px 0;
    background: #3462ee;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 30px;
  }
  button + button {
    margin-left: 10px;
  }
`
