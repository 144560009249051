import React from 'react'
import { useTranslation } from 'react-i18next'
import RoundButton from 'src/common/components/button/round-button'
import {
  ModalButtonGroup,
  ModalWrapper,
} from 'src/common/modals/modal-container'
import styled from 'styled-components'

interface Props {
  onClose: any
  onLoad: any
}

export default function LoadSessionModal(props: Props) {
  const { t } = useTranslation()
  return (
    <ModalWrapper>
      <Body>
        <p>
          {t(`request.modal.load-session.text-1`)}
          <br />
          {t(`request.modal.load-session.text-2`)}
        </p>
      </Body>
      <ModalButtonGroup>
        <RoundButton variant="outlined" onClick={props.onClose}>
          {t(`request.modal.load-session.negative`)}
        </RoundButton>
        <RoundButton onClick={props.onLoad}>
          {t(`request.modal.load-session.positive`)}
        </RoundButton>
      </ModalButtonGroup>
    </ModalWrapper>
  )
}

const Body = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #111111;
  text-align: center;
`
