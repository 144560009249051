function mapPadZero(number) {
  return ('0' + number).slice(-2)
}

/**
 * @todo runtime 146 인 경우에 2:26으로 나옴
 *       실제 영상은 2:25임  (https://youtu.be/79lQoOsck-8)
 */
export default function secondsToHhMmSsHelper(runtime: number) {
  const hours = Math.floor(runtime / 3600)
  runtime %= 3600
  const minutes = Math.floor(runtime / 60)
  const seconds = runtime % 60
  return isNaN(hours) || isNaN(minutes) || isNaN(seconds)
    ? '00:00:00'
    : [hours, minutes, seconds].map(mapPadZero).join(':')
}
