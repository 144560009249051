import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

//결제 전 동의받는 모달
export default function RequestConsent({ onOk, onCancel }) {
  const { t } = useTranslation()
  return (
    <Container>
      <h4>{t(`request.text-46`)}</h4>
      <ul>
        <li>{t(`request.text-47`)}</li>
        <li>
          {t(`request.text-48`)}
          <br />
          {t(`request.text-488`)}
        </li>
        <li>
          {t(`request.text-49`)} <br />
          {t(`request.text-499`)}
        </li>
      </ul>
      <div className="button-group">
        <button className="btn cancel" onClick={onCancel}>
          {t(`request.text-50`)}
        </button>
        <button className="btn ok" onClick={onOk}>
          {t(`request.text-51`)}
        </button>
      </div>
    </Container>
  )
}

const Container = styled.div`
  margin: 22px;
  min-width: 300px;
  padding: 50px 57px;
  border-radius: 8px;
  background-color: #fff;
  h4 {
    font-size: 20px;
  }
  ul {
    padding-left: 24px;
    line-height: 22px;
  }
  .button-group {
    display: flex;
    justify-content: right;
    margin-top: 19px;
    gap: 11px;
    .btn {
      padding: 10px;
      width: 200px;
      height: 42px;
      border-radius: 30px;
      font-size: 16px;
    }
    .cancel {
      color: #888;
      background: #ffffff;
      border: 1.5px solid #888888;
    }
    .ok {
      background: #3462ee;
      color: #fff;
      border: none;
    }
  }
  // mobile
  @media screen and (max-width: 959px) {
    padding: 28px 32px;
    .button-group {
      flex-direction: column-reverse;
      .btn {
        width: 100%;
        /* width: 124px; */
        padding: 6px;
        font-size: 13px;
      }
    }
  }
`
