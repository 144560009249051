
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDropzone } from 'react-dropzone'
import { ModalContext } from 'src/providers/modal.provider'
import RequestAlert from './request-alert'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
type Props = {
  onVideoUpload: (file) => void
}

export default function RequestLocalFile(props: Props) {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const [uploadedFile, setUploadedFile] = useState(null)
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.mp4, .mov, .avi, .mkv, .wmv',
    onDrop: (files) => {
      if (!files.length) return
      setUploadedFile(files[0])
    },
  })
  const handleDeleteFiles = () => {
    setUploadedFile(null)
  }
  const handleLocalFileUpload = () => {
    props.onVideoUpload(uploadedFile)
    handleDeleteFiles()
  }

  return (
    <Container>
      <Body>
        <TabContents>
          <div className="contents-upload">
            {!uploadedFile ? (
              <div {...getRootProps({ className: 'dropzone' })}>
                <p className="boldText">{t(`request.localfile-box-message`)}</p>
                <p className="grayText">{t(`request.text-6`)}</p>
                <button>{t(`request.localfile-select-button`)}</button>
                <p><br /></p>
                <p className="smallText">{t(`request.localfile-box-video-format`)}</p>
                <input {...getInputProps()} />
              </div>
            ) : (
              <div className="uploaded">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/icon-file.svg`}
                  alt="file icon"
                />
                <div>
                  <p>{uploadedFile.name}</p>
                  <img
                    onClick={handleDeleteFiles}
                    src={`${process.env.PUBLIC_URL}/assets/img/delete-grey.svg`}
                    alt="delete icon"
                  />
                </div>
              </div>
            )}
          </div>
        </TabContents>
      </Body>
      {uploadedFile ? (
        <Footer>
            <NegativeButton onClick={handleDeleteFiles}>
              {t(`request.localfile-clear-button`)}
            </NegativeButton>
            <PositiveButton onClick={handleLocalFileUpload}>
              {t(`request.localfile-upload-button`)}
            </PositiveButton>
        </Footer>
      ) : (null)}
    </Container>
  )
}

const Container = styled.div`
  background-color: #fff;
  // padding: 30px;
  border-radius: 8px;

  // desktop
  @media screen and (min-width: 959px) {
    width: 100%;
  }

  // mobile
  @media screen and (max-width: 959px) {
    // width: 100%;
    margin: 0 25px;
  }
`

const Header = styled.div`
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;

  h2 {
    color: #111;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 1.4;

    img {
      margin-right: 5px;
    }
  }
`

const Body = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
`

const TabContents = styled.div`
  padding: 15px;
  padding-left: 125px;
  padding-right: 125px;

  text-align: center;
  background: #fff;
  border: 1px dashed #ccc;
  border-radius: 7.15596px;

  & > div.contents-upload {
    padding: 14px 0;
    justify-content: center;
    
    button {
      margin-top: 16px;
      height: 36px;
      width: 200px;
      background-color: transparent;
      border: 1px solid #1a61f7;
      border-radius: 7.15596px;
      color: #1a61f7;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.75px;
      line-height: 1.5;

      &:hover {
        background-color: #d1dffd;
      }
    }

    & > div.uploaded {
      & > div {
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        img {
          cursor: pointer;
        }
      }
    }
  }

  .contents-upload p.boldText {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .contents-upload p.grayText {
    font-size: 16px;
    color: #888888;
  }

  .contents-upload p.smallText {
    font-weight: bold;
    font-size: 14px;
    color: #000000;
  }

  // mobile
  @media screen and (max-width: 959px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 16px;

  & > * {
    max-width: 300px;
  }

  & > button {
    flex: 1;
  }
`

const NegativeButton = styled.button`
  // height: 48px;
  // width: 100%;
  // background-color: #fff;
  // border: 1px solid #1a61f7;
  // border-radius: 24px;
  // color: #1a61f7;
  // cursor: pointer;
  // font-family: SpoqaHanSans;
  // font-size: 16px;
  // font-weight: 700;
  // letter-spacing: 1.25px;
  // line-height: 48px;
  // padding: 0 14px;

  // &:hover {
  //   background-color: #d1dffd;
  // }
  padding: 14px;
  background: #ffffff;
  border-radius: 7.15596px;
  min-width: 125px;
  border: 1px solid #111111;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #111111;
`

const PositiveButton = styled.button`
  // height: 48px;
  // width: 100%;
  // background-color: #1a61f7;
  // border: none;
  // border-radius: 24px;
  // color: #fff;
  // cursor: pointer;
  // font-family: SpoqaHanSans;
  // font-size: 16px;
  // font-weight: 700;
  // letter-spacing: 1.25px;
  // line-height: 48px;
  // padding: 0 14px;

  // &:hover {
  //   background-color: #19449e;
  // }
  padding: 14px;
  background: #111111;
  border-radius: 7.15596px;
  min-width: 125px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #ffffff;
`
