import React from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import AuthProvider from './providers/auth.provider'
import ModalProvider from './providers/modal.provider'
import GlobalStyle from './styles/global-style'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { CookiesProvider } from 'react-cookie'
import '../src/locale/i18n'

import { HelmetProvider } from 'react-helmet-async'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({
      console: true,
      history: true,
      dom: true,
      fetch: true,
      sentry: true,
      xhr: true,
    }),
  ],
  environment: process.env.REACT_APP_BUILD_MODE,
  normalizeDepth: 6,
  release: process.env.REACT_APP_VERSION,
  autoSessionTracking: true,
  tracesSampleRate: 1.0,
})

const element = (
  <CookiesProvider>
    <Provider store={store}>
      <AuthProvider>
        <ModalProvider>
          <GlobalStyle />
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </ModalProvider>
      </AuthProvider>
    </Provider>
  </CookiesProvider>
)

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
if (container.hasChildNodes()) {
  hydrateRoot(container, element)
} else {
  root.render(element)
}

// const root = createRoot(container)

// root.render(
//   <CookiesProvider>
//     <Provider store={store}>
//       <AuthProvider>
//         <ModalProvider>
//           <GlobalStyle />
//           <App />
//         </ModalProvider>
//       </AuthProvider>
//     </Provider>
//   </CookiesProvider>,
// )

reportWebVitals()
