import styled from 'styled-components'

type SqrBorderBtnPropType = {
  addProperty?: any
}

type CheckBoxPropType = {
  size?: number
  margin?: string
  borderRadius?: string
  borderColor?: string
}

//네모 버튼
export const SqrBorderBtn = styled.button`
  ${(props: SqrBorderBtnPropType) =>
    props.addProperty ? `${props.addProperty}` : null};
  //height: 36px;
  padding: 9px 8px;
  border-radius: 4px;
  border: solid 1px ${({ color }) => (color ? color : '#1a61f7')};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: ${({ color }) => (color ? color : '#1a61f7')};
  background-color: #ffffff;
  &:hover {
    color: white;
    background-color: ${({ color }) => (color ? color : '#1a61f7')};
  }
`

//체크박스 디자인
export const CheckBoxInput = styled.input.attrs({
  type: 'checkbox',
})<CheckBoxPropType>`
  display: none;
  & + label {
    display: inline-block;
    position: relative;
    min-width: ${({ size }) => (size ? size + 'px' : '18px')};
    height: ${({ size }) => (size ? size + 'px' : '18px')};
    margin: ${({ margin }) => margin ?? 'unset'};
    border-radius: ${({ borderRadius }) => borderRadius ?? '4px'};
    border: ${({ borderColor }) =>
      borderColor ? `solid 1px ${borderColor}` : 'solid 1px #888'};
    background-color: #fff;
    cursor: pointer;
    &::before {
      content: '';
    }
    ${({ checked }) => (checked ? `border: solid 1.5px #1a61f7` : null)};
    ${({ checked }) =>
      checked
        ? `&::before {content: '\\2713'; position: absolute; left: 3px; bottom: -5px; font-size: 18px; color: #1a61f7;}`
        : null};
  }
`

export const ImageBtn = styled.button`
  width: 124px;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: none;
  border: 1px solid #1a61f7;
  border-radius: 18px;
  background-color: #fff;
  &:hover {
    background-color: rgba(26, 97, 247, 0.2);
  }
`

export const CustomTooltipStyle = {
  backgroundColor: '#EFF0F2',
  boxShadow: '1px 3px 6px 2px rgba(0,0,0,0.3)',
  borderRadious: 'none',
  border: '1px solid #D4D4D4',
  color: '#111',
  maxWidth: 331,
  padding: 19,
  fontSize: 14,
  fontWeight: 400,
}
