import { createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'
import axios from 'src/app/axios-client'
import { setLanguage } from '../main/main.slice'

type SocialPromoCode = {
  code: string
}

interface ProfileParams {
  update?: boolean
}

export const getProfile = createAsyncThunk(
  'uesrInfo/getProfile',
  async (params: ProfileParams, thunkAPI) => {
    try {
      const response = await axios.get(`/api/pika/glohub/user/profile`)
      const serviceLanguage = response.data.serviceLanguage
      if (params.update && serviceLanguage) {
        i18next.changeLanguage(serviceLanguage)
        thunkAPI.dispatch(setLanguage({ language: serviceLanguage }))
      }
      return {
        profile: response.data,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getCredit = createAsyncThunk(
  'profile/getCredit',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/api/pika/glohub/user/credit`)
      return {
        credit: response.data,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const postSocial = createAsyncThunk(
  'profile/postSocial',
  async (params: SocialPromoCode, thunkAPI) => {
    try {
      axios.post(`/api/pika/glohub/user/social`, {
        // isVerified: verifiedValue.isVerified,
        code: params.code,
      })
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

interface EditUserParams {
  firstName: string
  lastName: string
}

export const editUserName = createAsyncThunk(
  'profile/editUserName',
  async (params: EditUserParams, thunkAPI) => {
    try {
      axios.put(`/api/pika/user/profile/personal-information`, params)
      // no response
      return {
        firstName: params.firstName,
        lastName: params.lastName,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

interface UserLanguageParams {
  serviceLanguage: 'ko' | 'en'
}

export const editUserLanguage = createAsyncThunk(
  'profile/editUserLanguage',
  async (params: UserLanguageParams, thunkAPI) => {
    try {
      axios.put(`/api/pika/user/service-language`, params)
      i18next.changeLanguage(params.serviceLanguage)
      thunkAPI.dispatch(setLanguage({ language: params.serviceLanguage }))
      return {
        serviceLanguage: params.serviceLanguage,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

interface CaptionUploadParams {
  autoCaptionUpload: boolean
}

export const toggleCaptionUpload = createAsyncThunk(
  'profile/toggleCaptionUpload',
  async (params: CaptionUploadParams, thunkAPI) => {
    try {
      axios.put(`/api/pika/user/profile/personal-information`, params)
      // no response
      return {
        autoCaptionUpload: params.autoCaptionUpload,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

interface TurnEmailNotiParams {
  status: boolean
}

// emailNotification
export const turnEmailNotification = createAsyncThunk(
  'profile/changeEmailNotification',
  async (params: TurnEmailNotiParams, thunkAPI) => {
    try {
      axios.put(`/api/pika/user/notification/email`, params)
      // no response
      return {
        changedStatus: params.status,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

interface EditProfileImageParams {
  file: FormData
}

export const editProfileImage = createAsyncThunk(
  'profile/editProfileImage',
  async (params: EditProfileImageParams, thunkAPI) => {
    try {
      const response = await axios.put(
        `/api/pika/user/profile/image`,
        params.file,
      )
      const data = response.data
      return {
        profileImage: data?.url || null,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteProfileImage = createAsyncThunk(
  'profile/deleteProfileImage',
  async (_, thunkAPI) => {
    try {
      await axios.delete(`/api/pika/user/profile/image`)
      return true
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

interface GetPossibleLeaveParams {}

export const getPossibleLeave = createAsyncThunk(
  'profile/getProssibleLeave',
  async (params: GetPossibleLeaveParams, thunkAPI) => {
    try {
      const response = await axios.get(`/api/pika/user/delete/possible`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

interface DeleteUserParams {
  reasons: string[]
  verbose: string
}

export const deleteUser = createAsyncThunk(
  'profile/deleteUser',
  async (params: DeleteUserParams, thunkAPI) => {
    try {
      const response = await axios.delete(`/api/pika/user/delete`, {
        data: params,
      })
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

interface ChangePasswordParams {
  password: string
  newPassword: string
}

export const changePassword = createAsyncThunk(
  'profile/changePassword',
  async (params: ChangePasswordParams, thunkAPI) => {
    try {
      const response = await axios.put(`/api/pika/user/password`, params)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

interface VerifyPasswordParams {
  email: string
  password: string
}

export const verifyPassword = createAsyncThunk(
  'profile/verifyPassword',
  async (params: VerifyPasswordParams, thunkAPI) => {
    try {
      await axios.post(`/api/pichu/auth/validate`, params)
      return {
        verify: true,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
