import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'src/app/hooks'
import { getCurrentLanguage } from 'src/features/main/main.slice'

interface Props {
  selectedLanguageCode: Nullable<string>
  onChange: (option) => void
  disabled?: boolean
  placeholder?: string
}

const transcriptionLanguageList = [
  { label: '아랍어', value: 'ar' },
  { label: '네덜란드어', value: 'nl' },
  { label: '영어', value: 'en' },
  { label: '프랑스어', value: 'fr' },
  { label: '독일어', value: 'de' },
  { label: '일본어', value: 'ja' },
  { label: '한국어', value: 'ko' },
  { label: '러시아어', value: 'ru' },
  { label: '중국어 간체', value: 'zh-Hans' },
  { label: '중국어 번체', value: 'zh-Hant' },
  { label: '스웨덴어', value: 'sv' }, // Auto Transcription에는 제공이 안됨
  { label: '태국어', value: 'th' },
  { label: '터키어', value: 'tr' },
  { label: '포르투갈어', value: 'pt' },
]

const transcriptionLanguageListEn = [
  { label: 'Arabic', value: 'ar' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Russian', value: 'ru' },
  { label: 'Simplified Chinese', value: 'zh-Hans' },
  { label: 'Traditional Chinese', value: 'zh-Hant' },
  { label: 'Swedish', value: 'sv' }, // Auto Transcription에는 제공이 안됨
  { label: 'Thai', value: 'th' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Portuguese', value: 'pt' },
]

export default function TranscriptionLanguageSelect(props: Props) {
  const { t } = useTranslation()
  const currentLanguage = useAppSelector(getCurrentLanguage)
  const [languageList, setLanguageList] = useState(transcriptionLanguageList)

  useEffect(() => {
    setLanguageList(
      currentLanguage === 'ko'
        ? transcriptionLanguageList
        : transcriptionLanguageListEn,
    )
  }, [currentLanguage])
  return (
    <Select
      options={languageList}
      value={
        languageList.find(
          (language) => language.value === props.selectedLanguageCode,
        ) || null
      }
      isDisabled={props?.disabled || false}
      isClearable
      onChange={props.onChange}
      placeholder={props.placeholder ? props.placeholder : t(`request.text-60`)}
      styles={{
        control: (base) => {
          return {
            ...base,
            borderColor: `${
              props.selectedLanguageCode ? '#1a61f7' : '#aaaaaa'
            }`,
          }
        },
      }}
    />
  )
}

const Container = styled.div``
