import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import Loading from 'src/common/components/loading/loading'
import { verifyCredit } from 'src/features/credit/credit.service'

import {
  getIsLoading,
  getRequests,
  setAllRequests,
} from 'src/features/request/request.slice'

import { ModalContext } from 'src/providers/modal.provider'

import styled from 'styled-components'
import {
  RequestComplete,
  RequestError,
  RequestPayment,
  RequestYoutube,
} from './components'

import LoadSessionModal from './modals/load-session-modal'

// 자식들이 공통으로 받을것
export interface CommonProps {
  nextStep: () => void
  prevStep: () => void
}

enum RequestStep {
  Youtube = 0,
  Payment = 1,
  Complete = 2,
  Error = 3,
}

export default function Request() {
  const dispatch = useAppDispatch()
  const [step, setStep] = useState(RequestStep.Youtube)
  const requests = useAppSelector(getRequests)

  const { setModal } = useContext(ModalContext)
  const [promotionCode, setPromotionCode] = useSearchParams()
  const isLoading = useAppSelector(getIsLoading)

  useEffect(() => {
    sessionStorage.removeItem('beforePayment-2')
    const requests = JSON.parse(sessionStorage.getItem('requests'))
    if (requests !== null && requests.length) {
      const beforePayment = sessionStorage.getItem('beforePayment')
      sessionStorage.removeItem('beforePayment')
      if (beforePayment) {
        sessionStorage.setItem('beforePayment-2', 'true')
        dispatch(setAllRequests({ requests }))
      } else {
        setModal(
          <LoadSessionModal
            onClose={() => {
              sessionStorage.removeItem('requests')
              setModal(null)
            }}
            onLoad={() => {
              dispatch(setAllRequests({ requests }))
              sessionStorage.removeItem('requests')
              setModal(null)
            }}
          />,
        )
      }
    }
  }, [])

  useEffect(() => {
    if (requests?.length) {
      sessionStorage.setItem('requests', JSON.stringify(requests))
    }
  }, [requests])

  useEffect(() => {
    if (promotionCode.get('code') !== null) {
      dispatch(verifyCredit({ code: promotionCode.get('code') })).then(
        (res: any) => {
          sessionStorage.setItem('isVerified', res.payload.verify)
          sessionStorage.setItem('promotionCode', res.payload.code)
        },
      )
    }
  }, [promotionCode])

  const nextStep = () => {
    setStep((prev) => {
      if (prev < RequestStep.Complete) {
        return prev + 1
      }
    })
  }

  const prevStep = () => {
    setStep((prev) => {
      if (prev > RequestStep.Youtube) {
        return prev - 1
      }
    })
  }

  const renderStepContents = () => {
    switch (step) {
      case RequestStep.Youtube:
        return <RequestYoutube nextStep={nextStep} prevStep={prevStep} />
      case RequestStep.Payment:
        return <RequestPayment nextStep={nextStep} prevStep={prevStep} />
      case RequestStep.Complete:
        return <RequestComplete />
      default:
        return <RequestError />
    }
  }

  // const renderBanner = () => {
  //   if (isLoggedIn) return null
  //   return <RequestBanner />
  // }

  return (
    <Container>
      {isLoading && <Loading />}
      <Wrap>
        {/* TODO : pro, creator 회원 통합되었기 때문에 주석처리. 이후 이 방침 확정되면 코드 삭제하기 */}
        {/* {renderBanner()} */}
        <Form>{renderStepContents()}</Form>
      </Wrap>
    </Container>
  )
}

const Container = styled.div`
  // desktop
  @media screen and (min-width: 959px) {
    padding-bottom: 400px;
  }

  // mobile
  @media screen and (max-width: 959px) {
  }
`

const Wrap = styled.div`
  position: relative;
`

// form -> div
const Form = styled.div`
  // desktop
  @media screen and (min-width: 959px) {
    padding: 0 50px 64px;
  }

  // mobile
  @media screen and (max-width: 959px) {
  }
`
