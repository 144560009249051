import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import {
  deleteOneRequest,
  getAllAmount,
  getRequests,
  getRequestsLength,
  initRequest,
  RequestProject,
  setDueAt,
  setOneRequest,
  setProjectGroupId,
  setRequestVideoData,
  getUsableBalance,
  getIsLoading,
} from 'src/features/request/request.slice'

import {
  getYoutubeChannelInfo,
  getYoutubeChannelVideos,
  getYoutubeInfo,
} from 'src/features/video/video.service'
import styled from 'styled-components'
import {
  Card,
  CardForChannel,
  CardForLink,
  CardInputs,
  CardTitle,
  ToggleBtn,
} from '../styled/card'
import RequestSummary from './request-summary'
import { CommonProps } from '../request'
import RequestYoutubeForm from './request-youtube-form'
import {
  createProjectGroup,
  getRates,
  uploadLocalVideoProcess,
} from 'src/features/request/request.service'
import {
  authenticationService,
  patchGoogleToken,
} from 'src/service/auth/auth.service'
import { useNavigate } from 'react-router-dom'

import RequestList from './request-list'
import { BASEURL } from 'src/App'
import RequestMychannel from './request-mychannel'
import RequestLocalFile from './request-localfile'
import { ModalContext } from 'src/providers/modal.provider'
import RequestAlert from './request-alert'
import {
  getBalance,
  getUserId,
  getUserAutoCaptionUpload,
} from 'src/features/profile/profile.slice'
import { useTranslation } from 'react-i18next'
import { toggleCaptionUpload, getProfile } from 'src/features/profile/profile.service'
import Loading from 'src/common/components/loading/loading'
import NavigateLoginModal from 'src/common/modals/navigate-login-modal'
import uuid from 'react-uuid'
import isTimeInRange, { calculateWeekendHoursInRange } from 'src/shared/helper/workTimeRange'
import calWorkingTime from 'src/shared/helper/workTimeRange'

interface Props extends CommonProps {

}

export enum RequestSelected {
  MyChannel = 0,
  YoutubeLink = 1,
  LocalFile = 2,
}

export default function RequestYoutube(props: Props) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { setModal } = useContext(ModalContext)
  const [url, setUrl] = useState('')
  const [videoIndex, setVideoIndex] = useState<string>(null)
  const [isLoggedIn, setIsLoggedIn] = useState(authenticationService.isLogin)
  const [errorMessage, setErrorMessage] = useState(null)
  const isLoading = useAppSelector(getIsLoading)
  const [progress, setProgress] = useState<number>(0);
  /**
   * requestType: channel, youtube, localfile
   */

  const [requestType, setRequestType] = useState('channel')
  const [rates, setRates] = useState<{
    translation: number
    transcription: number
    descMore: number
    descLess: number
  }>({
    translation: 5000,
    transcription: 2000,
    descMore: 4000,
    descLess: 2000,
  })

  const [selectedVideo, setSelectedVideo] = useState<RequestProject>(null)
  const { channelTitle, subtitles, pageInfo, playListId } = useAppSelector(
    (state) => state.video,
  )
  const autoCaptionUpload = useAppSelector(getUserAutoCaptionUpload)

  const [page, setPage] = useState(0)
  let [original, setOriginal] = useState([])
  const [videos, setVideos] = useState([])
  const { t } = useTranslation()

  /* 채널 연동 성공 시 날라오는 params */
  const gat = new URL(window.location.href).searchParams.get('gat')
  const grt = new URL(window.location.href).searchParams.get('grt')

  // memoized
  const allAmount = useAppSelector(getAllAmount)
  const requests = useAppSelector(getRequests)
  const requestsLength = useAppSelector(getRequestsLength)
  const balance = useAppSelector(getBalance)
  const userId = useAppSelector(getUserId)

  const isAssign = useMemo(() => {
    return (
      allAmount &&
      requestsLength &&
      !requests.some(
        (request) =>
          !(
            request.titleSource &&
            request.sourceLanguageCode &&
            request.taskType &&
            request.targetLanguageCode.length
          ),
      )
    )
  }, [requestsLength, requests])

  const channelLinkFailed = () => {
    setModal(
      <RequestAlert
        onOk={() => navigate('/creator/request')}
        message={`<p>${t(`request.text-32`)}</p>`}
      />,
    )
  }

  useEffect(() => {
    dispatch(initRequest())
    dispatch(getRates())
      .unwrap()
      .then((res) => {
        setRates({...res, descMore: 2000})
      })
  }, [dispatch])

  useEffect(() => {
    if (gat || sessionStorage.getItem('gat')) {
      if (gat) {
        sessionStorage.setItem('gat', gat)
      }
      patchGoogleToken(gat, grt)
      // dispatch(toggleCaptionUpload({ autoCaptionUpload: true }))
      dispatch(getYoutubeChannelInfo())
        .unwrap()
        .then((res) => {
          dispatch(
            getYoutubeChannelVideos({ playListId: res.channel?.playListId }),
          )
            .unwrap()
            .then(({ videoInfo }) => {
              setOriginal([...videoInfo.sourceData])
              setVideos(videoInfo.sourceData?.slice(0, 5))
              setModal(
                <RequestAlert
                  onOk={() => navigate('/creator/request')}
                  message={`<p>${t(`request.text-31`)}1</p>`}
                />,
              )
            })
            .catch((err) => {
              channelLinkFailed()
            })
        })
        .catch((err) => {
          channelLinkFailed()
        })
    }

    return () => setModal(null)
  }, [])

  const handlePageChange = (e, newPage) => {
    setPage(newPage)
    updatePage(newPage)
  }

  const updatePage = (page) => {
    const toCut = page * 5
    let sliced = []
    if (
      original.length < pageInfo.totalResults &&
      toCut + 5 > original.length
    ) {
      if (!pageInfo?.nextPageToken) {
        return
      } else {
        dispatch(
          getYoutubeChannelVideos({
            playListId,
            pageToken: pageInfo.nextPageToken,
          }),
        )
          .unwrap()
          .then(({ videoInfo }) => {
            const res = original.concat(videoInfo.sourceData)
            setOriginal([...res])
            setVideos(videoInfo.sourceData?.slice(0, 5))
          })
      }
    } else {
      sliced = original.slice(toCut, toCut + 5)
    }
    setVideos([...sliced])
  }

  useEffect(() => {
    if (videoIndex !== null) {
      setSelectedVideo(requests.filter((item) => item.id === videoIndex)[0])
    } else {
      setSelectedVideo(null)
    }
  }, [videoIndex])

  useEffect(() => {

    if (selectedVideo) {
      dispatch(
        setOneRequest({
          newRequest: selectedVideo,
        }),
      )
    }
  }, [selectedVideo])

  useEffect(() => {
    if (requests?.length) {
      const beforePayment = sessionStorage.getItem('beforePayment-2')
      sessionStorage.removeItem('beforePayment-2')
      if (beforePayment) {
        handleRequestPayment()
      }
    }
  }, [requests])

  useEffect(() => {
    if (url && errorMessage) {
      setErrorMessage(null)
    }
  }, [url])

  function getYotubeChannel(e) {
    /* TODO : 로그인여부 확인은 유튜브 api사용 허가 받은 뒤 없애기 */
    e.preventDefault()
    if (isLoggedIn) {
      window.location.href = `${BASEURL}/api/zarcian/youtube/caption/additional-consent`
    } else {
      setModal(<NavigateLoginModal />)
    }
  }

  function showErrorModal(msg: string) {
    setModal(<RequestAlert message={msg} />)
  }

  const handleAddYoutubeLink = () => {
      if (isLoggedIn) {
      if (requestsLength >= 10) {
        showErrorModal(`<p>${t(`request.text-37`)}</p>`)
        return
      }

      if (url) {
        dispatch(
          getYoutubeInfo({
            url,
          }),
        )
          .unwrap()
          .then(({ sourceData }) => {
            dispatch(
              setRequestVideoData({
                id: null,
                type: RequestSelected.YoutubeLink,
                sourceData,
                autoCaptionUpload,
                balance,
                rate: rates,
              }),
            )
          })
          .catch((error) => {
            // Sentry.captureException(error)
            if (error?.response?.status === 400) {
              setErrorMessage(t(`request.text-26`))
            } else {
              if (error?.message === 'NO_ITEMS') {
                setErrorMessage(t(`request.text-55`))
              } else if (error?.message === 'RUNTIME_IS_ZERO') {
                setErrorMessage(t(`request.text-56`))
              } else if (error?.message === 'OVER_20_MINUTES') {
                showErrorModal(t(`request.text-38`))
              } else {
                setErrorMessage(t(`request.text-57`))
              }
            }
          })
          .finally(() => {
            setUrl('')
          })
      } else {
        setErrorMessage(t(`request.text-58`))
      }
    } else {
      // 로그인 모달 띄우기
      setModal(<NavigateLoginModal />)
    }
  }

  const handleAddChannelVideo = (
    id: string,
    url: string,
    type: RequestSelected.MyChannel,
  ) => {
    if (isLoggedIn) {
      if (requestsLength >= 10) {
        showErrorModal(`<p>${t(`request.text-37`)}</p>`)
        return
      }

      if (url) {
        dispatch(
          getYoutubeInfo({
            url,
          }),
        )
          .unwrap()
          .then(({ sourceData }) => {
            dispatch(
              setRequestVideoData({
                id,
                type,
                sourceData,
                autoCaptionUpload,
                balance,
                rate: rates,
              }),
            )
          })
          .catch((error) => {
            // Sentry.captureException(error)
            if (error?.response?.status === 400) {
              setErrorMessage(t(`request.text-26`))
              if (error?.message === 'NO_ITEMS') {
                showErrorModal(`<p>${t(`request.text-55`)}</p>`)
              } else if (error?.message === 'RUNTIME_IS_ZERO') {
                showErrorModal(`<p>${t(`request.text-56`)}</p>`)
              } else if (error?.message === 'OVER_20_MINUTES') {
                showErrorModal(`<p>${t(`request.text-38`)}</p>`)
              } else {
                setErrorMessage(t(`request.text-57`))
              }
            }
          })
          .finally(() => {
            setUrl('')
          })
      } else {
        setErrorMessage(t(`request.text-58`))
      }
    } else {
      setModal(<NavigateLoginModal />)
    }
  }

  const handleDeleteRequest = (requestId, requestIndex) => {
    dispatch(
      deleteOneRequest({
        requestId,
      }),
    )
    if (requestId === videoIndex) handleSelectVideoIndex(null)
  }

  const handleSelectVideoIndex = (requestId) => {
    setVideoIndex(requestId)
  }

  const handleUpload = (subtitles, fileName) => {
    setSelectedVideo({
      ...selectedVideo,
      subtitles,
      fileName,
    })
  }

  // 15분 이내 48시간
  // 30분 72시간
  // 45분 96시간
  // 45분 이상은 별도 협의
  const getDueAt = (min: number) => {
    const createdAt = new Date()
    const dueAt = new Date()
    const hours = calWorkingTime(createdAt) //UTC 기준 시간임
    if (min <= 15) dueAt.setUTCHours(hours + 48)
    else if (min <= 30) dueAt.setUTCHours(hours + 72)
    else if (min <= 45) dueAt.setUTCHours(hours + 96)
    else dueAt.setUTCHours(hours + (24*7)) // 납기 협의 케이스, 운영팀에서 납기 협의 완료 후 관리자에서 납기 수정

    const weekendHours = calculateWeekendHoursInRange(createdAt, dueAt)

    // 단순 시간을 더하는 것이므로 로컬 타임에서 처리
    weekendHours === 0
     ? dueAt.setHours(dueAt.getHours() + 0)
     : weekendHours > 24
      ? dueAt.setHours(dueAt.getHours() + 48)
      : dueAt.setHours(dueAt.getHours() + 24)
    return dueAt
  }

  const handleRequestPayment = () => {
    if (!isLoggedIn) {
      sessionStorage.setItem('beforePayment', 'true')
      navigate('/creator/user/login')
      return
    }

    // const dueAt = new Date()
    // const hours = dueAt.getHours()
    // if (hours < 11) dueAt.setHours(hours + 12)
    // else dueAt.setHours(hours + 24)

    dispatch(
      createProjectGroup({
        projects: requests.map((request) => ({
          ...request,
          dueAt: getDueAt(request.runtime / 60),
        })),
      }),
    )
      .unwrap()
      .then((response) => {
        dispatch(setProjectGroupId({ projectGroupId: response.projectId }))
        dispatch(setDueAt({ dueAt: response.dueAt }))
        props.nextStep()
      })
      .catch((error) => {

      })
  }

  // const showLoginModal = () => {
  //     setModal(
  //       <SimpleModalWithCancel
  //         msg={`<p>${t(`common.login.login-required1`)}<br />${t(`common.login.login-required2`)}</p>`}
  //         OKLabel={t(`common.button.confirm`)}
  //         CancelLabel={t(`common.button.cencel`)}
  //         onClick={handleLogin}
  //       />,
  //     )
  // }
  const handleLogin = () => {
    navigate('/creator/user/login')
  }

  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const handleAddLocalFileLink = async (file: any) => {
    if(isLoggedIn) {
      const videoId = uuid()
      const uploadPath = `${userId}/${videoId}`
      dispatch(uploadLocalVideoProcess({uploadPath: uploadPath, data: file}))
      .unwrap()
      .then((sourceData) => {
        dispatch(
          setRequestVideoData({
            id: videoId,
            type: RequestSelected.LocalFile,
            sourceData,
            autoCaptionUpload,
            balance,
            rate: rates,
          }),
        )
      })
      .catch((error) => {
        // Sentry.captureException(error)
        if (error?.response?.status === 400) {
          setErrorMessage(t(`request.text-26`))
        } else {
          if (error?.message === 'NO_ITEMS') {
            setErrorMessage(t(`request.text-55`))
          } else if (error?.message === 'RUNTIME_IS_ZERO') {
            setErrorMessage(t(`request.text-56`))
          } else if (error?.message === 'OVER_20_MINUTES') {
            showErrorModal(t(`request.text-38`))
          } else {
            setErrorMessage(t(`request.text-57`))
          }
        }
      })
      .finally(() => {
        setUrl('')
      })
    } else {
      setModal(<NavigateLoginModal />)
    }
  }

  const requestHead = () => {
    if(requestType==='youtube') {
      return (                  
        <CardForLink>
          <p className="bold">{t(`request.text-26`)}</p>
          <p className="small">{t(`request.text-6`)}</p>
          <CardInputs
            style={{
              position: 'relative',
            }}
          >
            <input
              type="email"
              placeholder="https://www.youtube.com"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
            <span className="card-input-error">{errorMessage}</span>
            <button
              id="request-add-video"
              onClick={handleAddYoutubeLink}
            >
              {t(`request.text-25`)}
            </button>
          </CardInputs>
        </CardForLink>
      )
    } else if(requestType==='channel') {
      if(channelTitle) {
        return (
          <RequestMychannel
            channelTitle={channelTitle}
            sourceData={videos}
            pageInfo={pageInfo}
            handleAddYoutube={handleAddChannelVideo}
            requests={requests}
            handleDeleteRequest={handleDeleteRequest}
            page={page}
            handlePageChange={handlePageChange}
          />
        )
      } else {
        return (
          <CardForChannel>
            <Fragment>
              <TextBlock>
                <span className="bold item">{t(`request.text-5`)}&nbsp;</span>
                <span className="bold item">{t(`request.text-5-1`)}</span>
              </TextBlock>
              <p className="small">{t(`request.text-6`)}</p>
              <CardInputs
                className="youtube-inputs"
                style={{
                  position: 'relative',
                }}
              >
                <button onClick={getYotubeChannel}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icon/icon-link-youtube.svg`}
                    alt="link"
                  />
                </button>
                {t(`my.youtube.text-28`)}
              </CardInputs>
            </Fragment>
          </CardForChannel>
        )
      }
    } else if(requestType==='localfile') {
      return (
        <RequestLocalFile
          onVideoUpload={handleAddLocalFileLink}
        />
      )
    }
  }
  return (
    <Container id="request-container">
      {isLoading && <Loading />}
      <Header>
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/youtube-subtitle.png`}
          alt="request icon"
        />
        <h2>{t(`request.text-1`)}</h2>
      </Header>
      <Body>
        <Contents>
          <CardContainer>
            <Fragment>
              <CardTitle>{t(`request.text-2`)}</CardTitle>
              <Card>
                <ToggleBtn>
                  <div
                    className={`btn ${requestType==='channel' ? 'active' : ''}`}
                    onClick={() => {
                      setRequestType('channel')
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/icon-youtube.svg`}
                      alt="youtube"
                      id="request-toggle-mychannel-button"
                    />
                    <p className='hide-on-bush'>{t(`request.text-3`)}</p>
                  </div>
                  <div
                    className={`btn ${requestType==='youtube' ? 'active' : ''}`}
                    onClick={() => {
                      setRequestType('youtube')
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/icon-clip.svg`}
                      alt="link"
                      id="request-toggle-link-button"
                    />
                    <p className='hide-on-bush'>{t(`request.text-4`)}</p>
                  </div>
                  <div
                    className={`btn ${requestType==='localfile' ? 'active' : ''}`}
                    onClick={() => {
                      setRequestType('localfile')
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/icon-import-gray.svg`}
                      alt="local"
                      id="request-toggle-localfile-button"
                    />
                    <p className='hide-on-bush'>{t(`request.text-43`)}</p>
                  </div>
                </ToggleBtn>
                {requestHead()}
              </Card>
            </Fragment>

            <RequestList
              videoIndex={videoIndex}
              youtubeVideoList={requests}
              handleDeleteRequest={handleDeleteRequest}
              handleSelectVideoIndex={handleSelectVideoIndex}
            />
            <RequestYoutubeForm
              requestType={requestType}
              videoIndex={videoIndex}
              youtubeVideoList={requests}
              selectedVideo={selectedVideo}
              setSelectedVideo={setSelectedVideo}
              handleUpload={handleUpload}
              subtitles={subtitles}
            />
          </CardContainer>
        </Contents>

        <MobileBlank />

        {/* Summary */}

        <RequestSummary
          isAssign={isAssign}
          onRequestPayment={handleRequestPayment}
          isActivated={!!requests.length}
          isHasRequest={videoIndex === null}
          isMethod={false}
        />
      </Body>

      {isLoggedIn ? null : (
        <LoginButton className="hide-on-bush" onClick={handleLogin}>
          {t(`sign.in.signin`)}
        </LoginButton>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;

  @media (min-width: 375.02px) and (max-width: 744px) {
    padding: 0 20px;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (min-width: 959px) {
    margin: 50px auto;

    img {
      width: 56px;
      height: 56px;
      margin-right: 13px;
    }

    h2 {
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      color: #111111;
    }
  }

  @media screen and (max-width: 959px) {
    margin: 30px auto 40px;

    @media (max-width: 375px) {
      padding: 0 20px;
    }

    @media (min-width: 744.02px) {
      padding: 0 20px;
    }

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    h2 {
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      color: #111111;
    }
  }
`

const Body = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;

  // desktop
  @media screen and (min-width: 959px) {
  }

  // mobile
  @media screen and (max-width: 959px) {
    flex-direction: column;
  }
`

const Contents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  // desktop
  @media screen and (min-width: 959px) {
    gap: 30px;
  }

  // mobile
  @media screen and (max-width: 959px) {
  }
`

const CardContainer = styled.div`
  width: 100% !important;
  margin: 0 auto;
`

const LoginButton = styled.button`
  z-index: 2;
  position: absolute;
  top: -22px;
  right: 59px;
  border: none;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
`

const MobileBlank = styled.div`
  // desktop
  @media screen and (min-width: 959px) {
  }

  // mobile
  @media screen and (max-width: 959px) {
    height: 55px;
  }
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    .item {
      display: block;
      margin-bottom: 5px;
    }
  }
`