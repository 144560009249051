import React from 'react'
import { BASEURL } from 'src/App'
import styled from 'styled-components'

interface Props {}

export default function RequestBanner(props: Props) {
  return (
    <Container>
      <div>
        <div className="banner-title hide-on-bush">
          <img
            src={`${process.env.PUBLIC_URL}/assets/icon/icon-alert-white.svg`}
            alt="alert"
          />
          <span>안내</span>
        </div>
        <div className="banner-description">
          <p>
            이 페이지는 <strong>크레딧을 부여받은 유튜버 전용</strong> 요청
            페이지입니다.
          </p>
          <div className="description-row">
            <div>
              크레딧을 부여받지 않은 회원의 경우 GloZ 플랫폼에서 요청 바랍니다.
            </div>
            <div>
              <button
                className="only-mobile"
                onClick={() => {
                  window.location.href = BASEURL
                }}
              >
                GloZ 이동하기
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        className="hide-on-bush"
        onClick={() => {
          window.location.href = BASEURL
        }}
      >
        GloZ 플랫폼 이용하기
      </button>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  background: #8164f4;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  color: white;

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #ffffff;
    border-radius: 30px;
    background: transparent;
    color: #fff;
  }

  // desktop
  @media screen and (min-width: 959px) {
    padding: 24px 60px;

    & > div:first-child {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 30px;
    }

    .banner-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;

      img {
        margin-right: 3px;
      }
    }

    .banner-description {
      display: flex;
      gap: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    button {
      width: 162px;
      height: 32px;

      &:hover {
        color: #8164f4;
        background: #fff;
      }
    }

    button.only-mobile {
      display: none;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    padding: 15px;

    & > div:first-child {
      display: flex;
      align-items: baseline;
      gap: 7px;
    }

    .banner-description {
      .description-row {
        display: flex;
        align-items: flex-end;
        gap: 16px;

        & > div:last-child {
          flex-shrink: 0;

          button {
            padding: 7px 12px;
            height: 25px;
          }
        }
      }
    }
  }
`
