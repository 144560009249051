import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import {
  getAllAmount,
  getProjectGroupNo,
  getTranscriptionLength,
  getTranscriptionRequests,
  getTranslationLength,
  getTranslationRequests,
  initRequest,
} from 'src/features/request/request.slice'
import styled from 'styled-components'
import { Card } from '../styled/card'

interface Props {}

export default function RequestComplete(props: Props) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const translationLength = useAppSelector(getTranslationLength)
  const translationRequests = useAppSelector(getTranslationRequests)
  const transcriptionLength = useAppSelector(getTranscriptionLength)
  const transcriptionRequests = useAppSelector(getTranscriptionRequests)
  const allAmount = useAppSelector(getAllAmount)
  const projectGroupNo = useAppSelector(getProjectGroupNo)
  const { t } = useTranslation()

  const handleNavigate = () => {
    navigate('/creator/request/user')
  }
  useEffect(() => {
    sessionStorage.removeItem('requests')
    return () => {
      dispatch(initRequest())
    }
  }, [])

  return (
    <Container>
      {/* <Header>
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/payment-creditcard.svg`}
          alt="request icon"
        />
        <h2>{t(`payment.text-1`)}</h2>
      </Header> */}
      <Body>
        <Contents>
          <CardContainer>
            <Card>
              <ImageContainer>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icon/icon-payment-complete.svg`}
                  alt="complete icon"
                />
              </ImageContainer>
              <Strong>{t(`payment.text-15`)}</Strong>
              <Description>
                {t(`payment.text-16`)}
                {t(`payment.text-17`)}
                <br />
                {t(`payment.text-18`)}
                <br />
                {t(`payment.text-19`)}
              </Description>

              {/* <DateContainer>
                <span>{t(`payment.text-27`)}</span>
                <span>{moment(dueAt).format('YYYY-MM-DD, hh:mm a')}</span>
              </DateContainer> */}

              <ResultContainer>
                <ResultNumber>
                  {t(`payment.text-20`)} GloHub{projectGroupNo}
                </ResultNumber>
                {translationLength > 0 ? (
                  <ResultRequest>
                    <div>
                      <div className="chip translation-chip">
                        {t(`payment.text-21`)}
                      </div>
                      <div className="overflow">
                        {translationRequests[0].titleSource}
                      </div>
                    </div>
                    {translationLength > 1 ? (
                      <div>
                        {t(`payment.text-23`).replace(
                          '@',
                          (translationLength - 1).toString(),
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </ResultRequest>
                ) : (
                  ''
                )}

                {transcriptionLength > 0 ? (
                  <ResultRequest>
                    <div>
                      <div className="chip transcription-chip">
                        {t(`payment.text-22`)}
                      </div>
                      <div className="overflow">
                        {transcriptionRequests[0].titleSource}
                      </div>
                    </div>
                    {transcriptionLength > 1 ? (
                      <div>
                        {t(`payment.text-23`).replace(
                          '@',
                          (transcriptionLength - 1).toString(),
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </ResultRequest>
                ) : (
                  ''
                )}
                <ResultTotal>
                  <div>{t(`payment.text-24`)}</div>
                  <div>₩ {allAmount.toLocaleString()}</div>
                </ResultTotal>
              </ResultContainer>

              <PaymentButton onClick={handleNavigate}>
                {t(`payment.text-25`)}
              </PaymentButton>
            </Card>
          </CardContainer>
        </Contents>

        <DarkMatter />
      </Body>
    </Container>
  )
}

const Container = styled.div`
  @media (min-width: 744.02px) {
    margin-top: 20px;
    max-width: 1180px;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  // desktop
  @media screen and (min-width: 959px) {
    margin: 50px auto;

    img,
    h2 {
      display: none;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    margin: 30px auto 40px;
    padding: 0 20px;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    h2 {
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      color: #111111;
    }
  }
`

const Body = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;

  // desktop
  @media screen and (min-width: 959px) {
  }

  // mobile
  @media screen and (max-width: 959px) {
    flex-direction: column;
  }
`

const ImageContainer = styled.div`
  text-align: center;
`

const Strong = styled.p`
  margin-top: 20px;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #1a61f7;
`

const Description = styled.div`
  font-weight: 400;
  text-align: center;
  color: #444444;

  // desktop
  @media screen and (min-width: 959px) {
    margin-top: 20px;
    font-size: 12px;
    line-height: 20px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    margin: 20px auto;
    width: 82%;
    font-size: 14px;
    line-height: 22px;
  }
`

const ResultContainer = styled.div`
  margin-top: 30px;
`

const ResultNumber = styled.div`
  font-weight: 400;
  line-height: 20px;
  color: #444444;

  // desktop
  @media screen and (min-width: 959px) {
    font-size: 12px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    font-size: 14px;
  }
`

const ResultRequest = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
  border-top: 1px solid #cccccc;

  & > div {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #444444;

    @media (max-width: 375px) {
    }

    @media (min-width: 375.02px) {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  div.chip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 26px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    border-radius: 20px;

    @media (max-width: 375px) {
      padding: 3px 10px;
      width: fit-content;
    }

    @media (min-width: 375.02px) and (max-width: 744px) {
      width: 140px;
    }

    @media (min-width: 744.02px) {
      width: 182px;
    }
  }

  div.translation-chip {
    background: #3880f7;
  }

  div.transcription-chip {
    background: #2ccab0;
  }

  .overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media screen and (max-width: 375px) {
    align-items: flex-end;
    .overflow {
      margin-top: 5px;
      max-width: 164px;
    }
  }

  @media screen and (min-width: 375.02px) {
    align-items: center;
    height: 46px;
    .overflow {
      max-width: 280px;
    }
  }
`

const ResultTotal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  height: 40px;
  background: #f8f8f8;
  font-weight: 500;
  line-height: 20px;
  color: #111111;

  // desktop
  @media screen and (min-width: 959px) {
    font-size: 14px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    font-size: 16px;
  }
`

const Contents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const CardContainer = styled.div`
  width: 100% !important;
  margin: 0 auto;
  background: #fff;
  padding: 50px 0;
  border-radius: 8px;
`

const DarkMatter = styled.div`
  position: sticky;
  top: 50px;
  right: 0;
  width: 322px;
  height: fit-content;
`

const PaymentButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 48px;
  background: #111111;
  border: none;
  border-radius: 50px;
  margin: 60px auto;

  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #fff;
  text-transform: uppercase;

  // desktop
  @media screen and (min-width: 959px) {
    width: 216px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    width: 80%;
  }
`
