export const ACCESS_TOKEN = 'accessToken'

export const DEVICE_SIZES = {
  MOBILE: 375,
  TABLET: 744,
  LAPTOP: 1024,
}

export const LANGUAGE_LIST = [
  { label: '아랍어', value: 'ar' },
  { label: '네덜란드어', value: 'nl' },
  { label: '영어', value: 'en' },
  { label: '프랑스어', value: 'fr' },
  { label: '독일어', value: 'de' },
  { label: '일본어', value: 'ja' },
  { label: '한국어', value: 'ko' },
  { label: '러시아어', value: 'ru' },
  { label: '중국어 간체', value: 'zh-Hans' },
  { label: '중국어 번체', value: 'zh-Hant' },
  { label: '스웨덴어', value: 'sv' }, // Auto Transcription에는 제공이 안됨
  { label: '태국어', value: 'th' },
  { label: '터키어', value: 'tr' },
  { label: '베트남어', value: 'vi' },
  { label: '포르투갈어', value: 'pt' },
]

// 이 리스트 쓰려면 Creator, Pro, Admin에 장르정보가 모두 일치해야 함, 당분간 보류하고 언어 리스트만 추가
export const GENRE_LIST = [
  { label: '로맨스 ', value: 'Romance' },
  { label: '게임 ', value: 'Gaming' },
  { label: '공포 ', value: 'Horror' },
  { label: '서부시대 ', value: 'Western' },
  { label: '드라마 ', value: 'Social Drama' },
  { label: '과학기술 ', value: 'Science & Technology' },
  { label: '액션 ', value: 'Action' },
  { label: '역사 ', value: 'Historical' },
  { label: '스포츠 ', value: 'Sports' },
  { label: '코미디 ', value: 'Comedy' },
  { label: '범죄 ', value: 'Crime' },
  { label: '전기 ', value: 'Biography' },
  { label: '음악 ', value: 'Music' },
  { label: '공상과학 ', value: 'Science Fiction' },
  { label: '판타지 ', value: 'Fantasy' },
  { label: '교육 ', value: 'Education' },
  { label: '리얼리티 ', value: 'Reality' },
  { label: '뷰티/패션 ', value: 'Beauty & Fashion' },
  { label: '음식 ', value: 'Food' },
  { label: '광고 ', value: 'Ads' },
  { label: '자동차 ', value: 'Automotive' },
  { label: '건강 ', value: 'Health' },
  { label: '다큐멘터리 ', value: 'Documentary' },
  { label: '엔터테인먼트 ', value: 'Entertainment' },
  { label: '공학 ', value: 'Engineering' },
  { label: '법 ', value: 'Legal' },
  { label: '뮤지컬 ', value: 'Musical' },
  { label: '스탠드업 코미디 ', value: 'Stand-up comedy' },
  { label: '영화/애니메이션 ', value: ' Film & Animation' },
  { label: '여행/이벤트 ', value: 'Travel & Events' },
  { label: '비영리/사회운동 ', value: 'Nonprofits & Activism' },
  { label: '인물/블로그 ', value: 'People & Blogs' },
  { label: '뉴스/정치 ', value: 'News & Politics' },
  { label: '애완동물/동물 ', value: 'Pets & Animals' },
  { label: '노하우/스타일 ', value: 'How to & Style' },
  { label: '기타 ', value: 'Others' },
]

export const DEFAULT_PASSWORD_VALIDATION = [
  {
    id: 1,
    text: '9-20자 대소문자',
    checked: false,
  },
  {
    id: 2,
    text: '영문 대소문자 포함',
    checked: false,
  },
  {
    id: 3,
    text: '최소 1개 이상의 숫자와 특수문자 포함',
    checked: false,
  },
]

export const LOCALFILE_UPLOAD_BUCKET = process.env.REACT_APP_LOCALFILE_UPLOAD_BUCKET
export const LOCALFILE_UPLOAD_EXPIRES = process.env.REACT_APP_LOCALFILE_UPLOAD_EXPIRES
export const LOCALFILE_UPLOAD_TITLE_LANGTH = 50
export const LOCALFILE_UPLOAD_DESCRIPTION_LANGTH = 500