import React from 'react'
import { BASEURL } from 'src/App'
import RoundButton from 'src/common/components/button/round-button'
import {
  ModalButtonGroup,
  ModalWrapper,
} from 'src/common/modals/modal-container'
import styled from 'styled-components'

interface Props {}

export default function NoCreditDepositModal(props: Props) {
  const handleNavigatePlatform = () => {
    window.location.href = BASEURL
  }
  return (
    <ModalWrapper>
      <Body>
        <p>
          GloHub 전용 결제 페이지입니다.
          <br />
          GloZ 사용자는 GloZ 플랫폼을 이용해주세요.
        </p>
      </Body>
      <ModalButtonGroup>
        <RoundButton onClick={handleNavigatePlatform}>
          GloZ 플랫폼 바로가기
        </RoundButton>
      </ModalButtonGroup>
    </ModalWrapper>
  )
}

const Body = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #111111;
  text-align: center;
`
