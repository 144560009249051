import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { verifyCredit } from './credit.service'

type CreditType = {
  verified: boolean
  code: string
}

const initialState: CreditType = {
  verified: false,
  code: 'c4f8e75d-e345-4e25-ab07-2d030db7c83f',
}

const creditSlice = createSlice({
  name: 'credit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyCredit.pending, (state) => {})
      .addCase(verifyCredit.fulfilled, (state, { payload }: any) => {
        state.verified = payload.verify
        state.code = payload.code
      })
      .addCase(verifyCredit.rejected, (state, { payload, error }) => {
        state.verified = false
        state.code = 'c4f8e75d-e345-4e25-ab07-2d030db7c83f'
      })
  },
})

const selfSelector = (state: RootState) => state.credit
export const creditSelectors = createSelector(selfSelector, (state) => state)

export const getVerified = createSelector(selfSelector, (state) => ({
  verified: state.verified,
  code: state.code,
}))

export default creditSlice.reducer
