import React from 'react'
import styled, { css } from 'styled-components'

type VariantType = 'contained' | 'outlined'

type ColorType = 'primary' | 'secondary'

const PrimaryColor = '#2760DC'
const SecondaryColor = '#202020'

interface Props {
  variant: VariantType
  color: ColorType
  children: React.ReactNode
  leftIcon?: any
  onClick: any
  disabled?: boolean
}

/**
 * CTA Button
 * @typedef {VariantType} variant - default(contained)
 * @typedef {ColorType} color - default(primary)
 */
export default function RoundButton(props: Props) {
  const styles = () => {
    const color = props.color === 'primary' ? PrimaryColor : SecondaryColor
    switch (props.variant) {
      case 'outlined':
        return css`
          color: ${color};
          background-color: #fff;
          border: 1px solid ${color};
        `
      default:
        return css`
          color: #fff;
          background-color: ${color};
          border: none;
        `
    }
  }
  return (
    <Container
      styles={styles()}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children || ''}
    </Container>
  )
}

RoundButton.defaultProps = {
  variant: 'contained',
  color: 'primary',
}

const ButtonSize = css`
  min-width: 160px;

  @media (max-width: 375px) {
    height: 46px;
    min-height: 46px;
    padding-left: 27px;
    padding-right: 27px;
  }

  @media (min-width: 375.02px) and (max-width: 744px) {
    height: 46px;
    min-height: 46px;
    padding-left: 27px;
    padding-right: 27px;
  }

  @media (min-width: 744.02px) {
    height: 42px;
    min-height: 42px;
    padding-left: 24px;
    padding-right: 24px;
  }
`

const Container = styled.button<{ styles: any }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 19px;
  letter-spacing: 0.0025em;

  ${ButtonSize}
  ${(p) => p.styles}

  &:not(:disabled):hover {
    opacity: 0.6;
  }

  &:disabled {
    background-color: #eaeaea !important;
    color: #aaaaaa !important;
  }
`
