import React, { useContext } from 'react'
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser'
import { ModalContext } from 'src/providers/modal.provider'
interface Props {
  message?: string
  onOk?: Function
}

export default function RequestAlert(props: Props) {
  const { setModal } = useContext(ModalContext)
  return (
    <Container>
      {props.message ? ReactHtmlParser(props.message) : 'request error'}
      {props.message ? (
        <button
          onClick={() => {
            if (props.onOk) {
              props.onOk()
            }
            setModal(null)
          }}
        >
          확인
        </button>
      ) : null}
    </Container>
  )
}

const Container = styled.div`
  padding: 50px 57px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  background: #fff;
  border-radius: 8px;
  font-size: 20px;
  button {
    padding: 10px 10px;
    width: 200px;
    background: #3462ee;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    color: #fff;
  }

  p {
    text-align: center;
  }
  // mobile
  @media screen and (max-width: 959px) {
    padding: 40px 25px 28px 25px;
  }
`
