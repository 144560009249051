import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

interface Props {
  value: string
  max?: string
  min?: string
  onChange: (event) => void
}

export default function DateSelect(props: Props) {
  return (
    <Container>
      <DatePicker
        selected={props.value ? moment(props.value).toDate() : null}
        onChange={(date) => {
          const yyyymmddOrNull = date ? moment(date).format('YYYY-MM-DD') : date
          props.onChange(yyyymmddOrNull)
        }}
        placeholderText="YYYY-MM-DD"
        dateFormat="yyyy-MM-dd"
      />
      {props.value ? (
        <img
          width="15px"
          height="15px"
          src={`${process.env.PUBLIC_URL}/assets/icon/icon-cancel-black.svg`}
          alt="delete"
          onClick={() => props.onChange(null)}
        />
      ) : (
        <img
          width="24px"
          height="24px"
          src={`${process.env.PUBLIC_URL}/assets/icon/icon-calendar.svg`}
          alt="calendar"
        />
      )}
      {/* <DatePicker
        selected={props.value ? moment(props.value).toDate() : null}
        onChange={(date) => {
          const yyyymmddOrNull = date ? moment(date).format('YYYY-MM-DD') : date
          props.onChange(yyyymmddOrNull)
        }}
        placeholderText="YYYY-MM-DD"
        customInput={<CustomInput onClear={() => props.onChange(null)} />}
      /> */}
    </Container>
  )
}

const Container = styled.div`
  position: relative;

  .input-box {
    height: 40px;
    width: 100%;
    padding: 12px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;

    label {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #888888;
    }
  }

  img {
    position: absolute;
    right: 10px;

    &[alt='delete'] {
      top: 13px;
    }

    &[alt='calendar'] {
      top: 8px;
    }
  }

  // desktop
  @media screen and (min-width: 959px) {
    flex: 1;
  }

  // mobile
  @media screen and (max-width: 959px) {
    flex: 1;
  }
`
