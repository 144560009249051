import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'src/app/hooks'
import { getBalance } from 'src/features/profile/profile.slice'
import {
  getAllAmount,
  getAllDescription,
  getAllRuntime,
  getFinalAmount,
  getRequestsLength,
  getTranscriptionLength,
  getTranslationLength,
  getUsableBalance,
} from 'src/features/request/request.slice'
import { ModalContext } from 'src/providers/modal.provider'
import styled, { css } from 'styled-components'
import RequestConsent from './request-consent'
import { getCurrentLanguage } from 'src/features/main/main.slice'
import { CardItem } from '../styled/card'

interface Props {
  onRequestPayment: () => void
  isAssign: boolean
  isActivated?: boolean
  isHasRequest?: boolean
  isMethod?: boolean
}

interface LanguageProps {
  language: string
}

export default function RequestSummary(props: Props) {
  const { setModal } = useContext(ModalContext)
  const [openSummary, setOpenSummary] = useState(false)
  const translationLength = useAppSelector(getTranslationLength)
  const transcriptionLength = useAppSelector(getTranscriptionLength)
  const requestsLength = useAppSelector(getRequestsLength)
  const allRuntime = useAppSelector(getAllRuntime)
  const allAmount = useAppSelector(getAllAmount)
  const orderDescTranslation = useAppSelector(getAllDescription)
  const usableBalance = useAppSelector(getUsableBalance)
  const finalAmount = useAppSelector(getFinalAmount)
  const currentLanguage = useAppSelector(getCurrentLanguage)
  const { t } = useTranslation()

  const handleSummary = () => {
    setOpenSummary((prev) => !prev)
  }

  const updateFooterMargin = (margin: number) => {
    const mobileFooter: HTMLDivElement =
      window.document.querySelector('.mobile-footer')
    if (mobileFooter) {
      mobileFooter.style.marginBottom = margin + 'px'
    }
  }

  const cancelConsent = () => {
    setModal(
      <CancelModal>
        <h4>{t(`request.text-52`)}</h4>
        <p>{t(`request.text-53`)}</p>
        <div className="button-group">
          <button onClick={() => setModal(null)}>{t(`request.text-54`)}</button>
        </div>
      </CancelModal>,
    )
  }

  const getConsent = () => {
    setModal(
      <RequestConsent
        onOk={() => {
          setModal(null)
          props.onRequestPayment()
        }}
        onCancel={cancelConsent}
      />,
    )
  }

  useEffect(() => {
    updateFooterMargin(103)
    return () => updateFooterMargin(0)
  }, [])

  return (
    <Container
      className="summary-container"
      activate={props.isActivated}
      isMethod={props.isMethod}
    >
      <Summary>
        <SummaryTitle>{t(`request.text-19`)}</SummaryTitle>
        <SummaryDetails>
          <SummaryItem language={currentLanguage}>
            <span>{t(`request.text-20`)}</span>
            <span>
              {translationLength} {t(`request.text-59`)}
            </span>
          </SummaryItem>
          <SummaryItem language={currentLanguage}>
            <span>{t(`request.text-21`)}</span>
            <span>
              {transcriptionLength} {t(`request.text-59`)}
            </span>
          </SummaryItem>
          <SummaryItem language={currentLanguage}>
            <span>{t(`request.text-64`)}</span>
            <span>
              {orderDescTranslation} {t(`request.text-59`)}
            </span>
          </SummaryItem>
          <SummaryItem language={currentLanguage}>
            <span>{t(`request.text-65`)}</span>
            <span>
              {allRuntime} {t(`request.text-69`)}
            </span>
          </SummaryItem>
          <SummaryItem language={currentLanguage}>
            <span>{t(`request.text-66`)}</span>
            <span>
              {requestsLength} {t(`request.text-59`)}
            </span>
          </SummaryItem>
          <hr style={{ height: 2 }} />
          <SummaryItem language={currentLanguage}>
            <span>{t(`request.text-67`)}</span>
            <p>₩ {allAmount.toLocaleString()}</p>
          </SummaryItem>
          {!props.isHasRequest && (
            <>
              <SummaryItem language={currentLanguage}>
                <span>{t(`request.text-68`)}</span>
                <span className="credit">
                  - ₩ {usableBalance.toLocaleString()}
                </span>
              </SummaryItem>
              <SummaryTotal>
                <p>{t(`request.text-73`)}</p>
                <hr style={{ height: 15 }} />
                <h2>₩ {finalAmount.toLocaleString()}</h2>
              </SummaryTotal>
            </>
          )}
        </SummaryDetails>
      </Summary>
      {finalAmount > 0 ? (
        <PaymentMethodContainer>
          <div className="header-method">
            <p>{t('payment.text-30')}</p>
          </div>
          <CardItem isMethod={true}>
            <div>{t(`payment.text-28`)}</div>
            <div className="payment-method">
              <span className="amount">₩ {finalAmount.toLocaleString()}</span>
            </div>
          </CardItem>
        </PaymentMethodContainer>
      ) : null}
      <SummaryActions>
        <ActionButton
          isAssign={props.isAssign}
          onClick={getConsent}
          disabled={!props.isAssign}
          id="request-summary-payment"
        >
          {t(`request.text-23`)}
        </ActionButton>
      </SummaryActions>
    </Container>
  )
}

type ContainerProp = {
  activate: boolean
  isMethod?: boolean
}

const Container = styled.div<ContainerProp>`
  // desktop 959
  @media screen and (min-width: 959px) {
    position: sticky;
    top: 50px;
    right: 0;
    width: 322px;
    height: fit-content;

    ${({ isMethod }) => (isMethod ? 'display:none;' : 'display:block;')}
  }

  // mobile 959
  @media screen and (max-width: 959px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Summary = styled.div`
  // desktop
  @media screen and (min-width: 959px) {
    margin-left: 30px;
    width: 216px;
    /* height: 237px; */
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 8px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    width: 100%;
    user-select: none;
    max-height: 500px;
    background-color: #fff;
  }
`

const SummaryTitle = styled.div`
  font-weight: 700;
  line-height: 20px;
  color: #444444;

  // desktop
  @media screen and (min-width: 959px) {
    font-size: 16px;
    padding: 15px;
    border-bottom: 1px solid #ccc;
  }

  // mobile
  @media screen and (max-width: 959px) {
    font-size: 20px;
    padding: 30px 30px 20px;
    border-bottom: 1px solid #ccc;
  }
`

const SummaryDetails = styled.div`
  font-weight: 400;
  color: #444444;

  // desktop
  @media screen and (min-width: 959px) {
    padding: 15px;
    font-size: 12px;
    line-height: 12px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    padding: 30px;
    font-size: 16px;
    line-height: 12px;
  }
`

const SummaryItem = styled.div<LanguageProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  font-size: 14px;
  line-height: 15px;

  span {
    &:first-child {
      width: ${(props) => (props.language === 'en' ? '50%;' : '60%;')};
    }
    &:not(:first-child) {
      width: ${(props) => (props.language === 'en' ? '50%;' : '40%;')};

      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  p {
    font-weight: bold;
  }

  .credit {
    color: #ff6b00;
    font-weight: 700;
    font-size: 14px;
  }

  // desktop
  @media screen and (min-width: 959px) {
    &:not(:first-child) {
      margin-top: 10px;
    }
    &:nth-child(4) {
      margin-top: 20px;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
`

const SummaryTotal = styled.div`
  font-weight: 700;

  p {
    font-size: 14px;
    line-height: 12px;
    color: #444444;
  }

  h2 {
    font-weight: 700;
    line-height: 22px;
    text-align: right;
    color: #111111;
  }

  // desktop
  @media screen and (min-width: 959px) {
    margin-top: 15px;

    p {
      font-size: 14px;
    }

    h2 {
      font-size: 20px;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    margin-top: 20px;

    p {
      font-size: 16px;
    }

    h2 {
      font-size: 22px;
    }
  }
`

const SummaryActions = styled.div`
  // desktop
  @media screen and (min-width: 959px) {
    width: 216px;
    margin-top: 30px;

    button {
      margin-left: 30px;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 30px 20px 107px 20px;
    width: 100%;

    button {
      margin: 0;
      padding: 0;
    }
  }
`

const ActionButton = styled.button<{ isAssign: boolean }>`
  width: 100%;
  height: 48px;
  background: #111111;
  border-radius: 50px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  text-transform: uppercase;

  ${(p) =>
    p.isAssign
      ? css`
          color: #fff;
          cursor: pointer;

          &:hover {
            background: #444;
          }
        `
      : css`
          background: #414141;
          color: #7e7e7e;
        `}
`

const CancelModal = styled.div`
  padding: 50px 50px;
  margin: 25px;
  background: #fff;
  border-radius: 8px;
  text-align: center;
  h4 {
    font-size: 20px;
  }
  p {
    font-size: 15px;
  }
  .button-group {
    margin-top: 35px;
    button {
      width: 200px;
      padding: 10px;
      background: #3462ee;
      border: none;
      border-radius: 30px;
      color: #fff;
      font-weight: 500;
    }
  }
`

const PaymentMethodContainer = styled.div`
  width: 100%;
  margin-top: 54px;

  .header-method {
    padding: 22px 8px 18px 8px;

    display: flex;
    width: 100%;
    justify-content: space-between;

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;

      color: #111111;
    }
  }

  @media screen and (min-width: 959px) {
    display: none;
  }
  @media screen and (max-width: 959px) {
    margin-top: 30px;
    .header-method {
      padding: 22px 8px 18px 20px;
    }
  }
`
