import React, { useEffect, useState } from 'react'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { authenticationService, refreshToken } from 'src/service/auth/auth.service'

const BASEURL =
  process.env.REACT_APP_BASE_URL || `https://${window.location.hostname}:3000`

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data }) => {
    try {
      const result = await instance({ url, method, data })
      return { data: result.data }
    } catch (axiosError) {
      let err = axiosError as AxiosError
      return {
        error: { status: err.response?.status, data: err.response?.data },
      }
    }
  }

const instance = axios.create({
  baseURL: BASEURL,
  // baseURL: 'https://api-dev.gloground.com',
  headers: {
    Accept: 'application/json',
    Authorization: 'Bearer ' + sessionStorage.getItem('accessToken'),
  },
  timeout: 15000,
  withCredentials: true,
})

export const setHeaderToken = (token: string) => {
  window.sessionStorage.setItem('accessToken', token)
}

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = window.sessionStorage.getItem('accessToken')
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

instance.interceptors.response.use(
  async (response) => response,
  async (error) => {

    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      const token = await refreshToken()
      // 로그아웃 처리
      if(!token.accessToken || token.accessToken===null) {
        const beforeLogin = sessionStorage.getItem('beforeLogin')
        authenticationService.logout()
        sessionStorage.setItem('beforeLogin', beforeLogin)
        window.location.href = '/creator/user/login'
      }
      else {
        // 재호출
        originalRequest._retry = true 
        return instance(originalRequest);
      }
    }
    return Promise.reject(error);
  },
);

interface AxiosCustomRequestConfig extends AxiosRequestConfig {
  retryCount: number
}

// instance.interceptors.response.use(
//   (response: AxiosResponse) => {
//     return response
//   },
//   async (error) => {
//     const originalRequest = error.config
//     if (error.response?.status === UNAUTHORIZED && !originalRequest._retry) {
//     }
//     return Promise.reject(error)
//   },
// )

const onFulfilled = (response) => response

const onRejected = (error: AxiosError) => {
  const config = error.config as AxiosCustomRequestConfig
  // if (config) {
  //   if (retryCount < MAX_RETRY_COUNT) {
  //     return retry(error.config)
  //   }
  // }

  return Promise.reject(error)
}

instance.interceptors.response.use(onFulfilled, onRejected)

// instance.interceptors.response.use(undefined, (error: AxiosError) => {
//   const config = error.config as AxiosCustomRequestConfig
//   const retryCount = config.retryCount
//   console.log(config.retryCount)

//   config.retryCount = config.retryCount ?? 0

//   console.log('RETRY COUNT:', config.retryCount)

//   const shouldRetry = config.retryCount < MAX_RETRY_COUNT
//   if (shouldRetry) {
//     config.retryCount += 1
//     return axios.request(config)
//   }

//   return Promise.reject(error)
// })

export default instance
