import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'

type AuthState = {
  token: Nullable<string>
  isLoggedIn: boolean
}

const initialState: AuthState = {
  token: null,
  isLoggedIn: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { token, isLoggedIn } }: PayloadAction<AuthState>,
    ) => {
      state.token = token
      state.isLoggedIn = isLoggedIn
    },
  },
})

export const { setCredentials } = authSlice.actions

export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn

export default authSlice.reducer
