import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'src/app/axios-client'

export const authApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => {
    return {
      refreshAccessToken: builder.query({
        query: () => {
          return {
            url: '/api/pichu/auth/refresh-access-token?selects=originatorCredentials',
            method: 'get',
          }
        },
      }),
    }
  },
})

export const { useRefreshAccessTokenQuery } = authApi
