import {
  Card,
  CardInputs,
  CardRadioItem,
  CardRadioItemGroup,
  CardTitle,
} from '../styled/card'
import styled from 'styled-components'
import { Fragment } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
type Props = {
  videoIndex: string
  youtubeVideoList: any
  handleSelectVideoIndex: any
  handleDeleteRequest: any
}
export default function RequestList({
  videoIndex,
  youtubeVideoList,
  handleSelectVideoIndex,
  handleDeleteRequest,
}: Props) {
  // if (youtubeVideoList?.length === 0) return <Fragment />
  const { t } = useTranslation()

  const isDone = (request) => {
    return (
      request.titleSource &&
      request.sourceLanguageCode &&
      request.taskType &&
      request.targetLanguageCode.length
    )
  }

  const makeVideoTitle = (title) => {
    if (title.length > 26) {
      return ReactHtmlParser(
        title.substring(0, 14) + '<br/>' + title.substring(14, 26) + '...',
      )
    } else if (title.length > 14) {
      return ReactHtmlParser(
        title.substring(0, 14) + '<br/>' + title.substring(14),
      )
    }
    return title
  }

  return (
    <Container>
      <CardTitle>{t(`request.text-8`)}</CardTitle>
      <p className="semiTitle">{t(`request.text-33`)}</p>
      <Card>
        {!youtubeVideoList?.length ? (
          <RequestListContainer>
            <p>{t(`request.text-9`)}</p>
          </RequestListContainer>
        ) : (
          <CardRadioItemGroup className="scrollbar-open">
            {youtubeVideoList.map((request, requestIndex) => {
              return (
                <CardRadioItem
                  key={`request-translation-${requestIndex}`}
                  onClick={() => handleSelectVideoIndex(request.id)}
                  isActive={videoIndex === request.id}
                >
                  <div>
                    <p className="index">{requestIndex + 1}</p>
                    <p
                      className={`status-chip ${
                        isDone(request) ? 'done' : 'error'
                      }`}
                    >
                      {isDone(request)
                        ? t(`request.text-35`)
                        : t(`request.text-34`)}
                    </p>

                    <label>{request.titleSource}</label>
                  </div>
                  <img
                    style={{ float: 'right' }}
                    src={`${process.env.PUBLIC_URL}/assets/icon/icon-delete-project.svg`}
                    alt="delete project"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleDeleteRequest(request.id, requestIndex)
                    }}
                  />
                </CardRadioItem>
              )
            })}
          </CardRadioItemGroup>
        )}
      </Card>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 30px;
  .semiTitle {
    margin-bottom: 14px;
    color: #888;
  }
  @media screen and (max-width: 959px) {
    .semiTitle {
      margin-left: 20px;
    }
  }
`

const RequestListContainer = styled.div`
  p {
    text-align: center;
    color: #888;
    font-size: 16px;
  }
`
