import axios from 'src/app/axios-client'
import { BehaviorSubject } from 'rxjs'
const currentUserSubject = new BehaviorSubject(
  sessionStorage.getItem('accessToken'),
)

export const authenticationService = {
  login,
  logout,
  signUp,
  requestGGrant,
  requestLGrant,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value
  },
  get isLogin() {
    return (
      authenticationService.currentUserValue !== undefined &&
      authenticationService.currentUserValue !== null &&
      authenticationService.currentUserValue !== 'null' &&
      authenticationService.currentUserValue !== ''
    )
  },
}

function login(email, password) {
  return axios
    .post(`/api/pichu/auth/login`, { email, password })
    .then((response) => {
      sessionStorage.setItem('accessToken', response.data.accessToken)
      sessionStorage.setItem('isFirstLogin', response.data.isFirstLogin)
      sessionStorage.setItem(
        'originatorCredentials',
        response.data.originatorCredentials,
      )
      //originatorCredentials 이 값이 있으면 KPI메뉴 보여야 함
      sessionStorage.setItem('email', response.data.email)
      sessionStorage.setItem('role', response.data.role)
      currentUserSubject.next(response.data.accessToken)
      return response
    })
}

export function getGoogleToken() {
  return axios.get(`/api/pichu/auth/google/gat`).then((res) => {
    sessionStorage.setItem('gat', res.data?.gat)
  })
}

export function patchGoogleToken(gat, grt) {
  let body = grt === 'undefined' || !grt ? { at: gat } : { at: gat, rt: grt }
  return axios.patch(`/api/pika/user-internal/access/update`, body)
}

/* 회원가입 시 이메일 체크를 위한 verification코드 발송 */
export const sendEmailVerifycode = async (email) => {
  let response = await axios.post(
    `/api/pika/email-verification-code/glohub`,
    { email },
    {
      headers: {
        Authorization: 'Bearer ' + authToken(),
        'Content-Type': 'application/json',
      },
    },
  )
  return response.data
}

/* 이메일 verification코드 유효성 체크 */
export const verifyEmailCode = async (codeInfo) => {
  let response = await axios.post(`/api/pika/email-verification-code/verify`, {
    email: codeInfo.email,
    verificationCode: codeInfo.verificationCode,
  })
  return response.data
}

function signUp(formData) {
  return axios.post(`/api/pika/glohub/user`, formData).then((response) => {
    return response
  })
}

/* get referral id */
export function getReferralId(referralCode) {
  return axios
    .get(`/api/pika/public/unique-user?referralCode=${referralCode}&select=id`)
    .then((response) => {
      return response.data
    })
}

/* 회원가입한 유저가 role선택 */
export const putUserType = async (role) => {
  let response = await axios.put(
    `/api/pika/user/selected-user-type`,
    { selectedUserType: role },
    {
      headers: {
        Authorization: 'Bearer ' + authToken(),
        'Content-Type': 'application/json',
      },
    },
  )
  return response.data
}

/* 회원 user type 불러오기 */
export const getUserType = async () => {
  let response = await axios.get(`/api/pika/user/selected-user-type`, {
    headers: {
      Authorization: 'Bearer ' + authToken(),
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

function logout() {
  sessionStorage.clear()

  axios
    .post(`/api/pichu/auth/logout`, {
      headers: {
        Authorization: 'Bearer ' + authToken(),
        'Content-Type': 'application/json',
      },
    })
    .then((resp) => {
      currentUserSubject.next(null)
    })
}

export const authToken = () => {
  return sessionStorage.getItem('accessToken')
}

export const refreshToken = async () => {
  let response = await axios.get(
    `/api/pichu/auth/refresh-access-token?selects=email&selects=originatorCredentials`,
    {
      headers: {
        Authorization: 'Bearer ' + authToken(),
        'Content-Type': 'application/json',
      },
    },
  )
  sessionStorage.setItem('accessToken', response.data.accessToken)
  sessionStorage.setItem(
    'originatorCredentials',
    response.data.originatorCredentials,
  )

  return response.data
}

function requestGGrant(code) {
  return axios
    .get(`/api/pichu/auth/google/glohub`, {
      params: { code },
    })
    .then((resp) => {
      sessionStorage.setItem('accessToken', resp.data.accessToken)
      return resp
      // if (verifiedValue.isVerified) {
      //   axios
      //     .post(`/api/pika/glohub/user/social`, {
      //       // isVerified: verifiedValue.isVerified,
      //       code: verifiedValue.code,
      //     })
      //     .then((res) => {
      //       return resp
      //     })
      // } else {
      //   return resp
      // }
    })
}

function requestLGrant(code, verifiedValue) {
  return axios
    .get(`/api/pichu/auth/linkedin/glohub`, {
      params: { code },
    })
    .then((resp) => {
      sessionStorage.setItem('accessToken', resp.data.accessToken)
      if (verifiedValue.isVerified) {
        axios.post(`/api/pika/glohub/user/social`, {
          // isVerified: verifiedValue.isVerified,
          code: verifiedValue.code,
        })
      }

      return resp
    })
}

/* paypal login code */
var myHeaders = new Headers()
myHeaders.append(
  'Authorization',
  'Basic QWIyTEllLVRHdi1leGdkV1lpcDdiZzVkR0ktajVUNjZ0dmFaN1FmQmp5akFDd1drR2g3WGFveHFTTEp4TTFRVFB3RWR0dVluTE91WHUySUE6RU9wUFd1VHp6ckxxb0VEN1I0UFB3X1V5dmdGQldLLWFoT2ZIZThVejQ5dDlDWmo0WFpQYldoZHFmWmVNVXFWZVJPSTFqSmNocVZKZndjQlo=',
)
myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
myHeaders.append(
  'Cookie',
  'LANG=en_US%3BUS; cookie_check=yes; d_id=ba91893a6b214548bf62712da34b61b71629966091888; enforce_policy=ccpa; ts=vreXpYrS%3D1724661301%26vteXpYrS%3D1629968701%26vr%3D818cc66117b0a602060428fafa2d5601%26vt%3D818cc66117b0a602060428fafa2d5600%26vtyp%3Dnew; ts_c=vr%3D818cc66117b0a602060428fafa2d5601%26vt%3D818cc66117b0a602060428fafa2d5600; tsrce=unifiedloginnodeweb; x-cdn=fastly:ITM; x-pp-s=eyJ0IjoiMTYyOTk2NjkwMTY3MSIsImwiOiIwIiwibSI6IjAifQ',
)

var urlencoded = new URLSearchParams()
urlencoded.append('grant_type', 'client_credentials')

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: urlencoded,
  redirect: 'follow',
}

/* paypal login code */
export async function getPaypalToken(code) {
  return fetch(
    'https://api-m.sandbox.paypal.com/v1/oauth2/token',
    requestOptions,
  )
    .then((response) => response.text())
    .then((result) => {
      return result
    })
    .catch((error) => console.log())
}

/* get paypal personal information */
export async function getPaypalPersonalInfo(token) {
  let response = await axios.get(
    `https://api-m.sandbox.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    },
  )
  return response.data
}

//forgot password
export const forgotPassword = async (email) => {
  let response = await axios.post(
    `/api/pika/glohub/user/reset-password`,
    { email },
    {
      headers: {
        Authorization: 'Bearer ' + authToken(),
        'Content-Type': 'application/json',
      },
    },
  )
  return response.data
}

// Forgot password -> Reset password 에서 비밀번호 셋팅하기
export const resetPassword = async (newPassword, token) => {
  let response = await axios.put(`/api/pika/user/reset-password`, {
    newPassword,
    token,
  })
  return response.data
}

// 새로운 user 비밀번호 셋팅용 토큰 받기 (* sign up process 기획 수정 된 후로 사용하지 않고있음)
export const getTokenForSetPassword = async () => {
  let response = await axios.get(
    `/api/pichu/auth/refresh-access-token?selects=email&selects=hasPassword&selects=isSnsUser`,
  )
  return response.data
}

// 새로운 user가 Splash페이지에서 비밀번호 셋팅하기 (* sign up process 기획 수정 된 후로 사용하지 않고있음)
export const settingPwNewMember = async (pw) => {
  let response = await axios.post(
    `/api/pika/user/set-password`,
    { password: pw },
    {
      headers: {
        Authorization: 'Bearer ' + authToken(),
        'Content-Type': 'application/json',
      },
    },
  )
  return response.data
}

/* 회원가입 시 이메일 중복검사 */
export const checkEmailDuplication = async (value) => {
  let response = await axios.get(`/api/pika/user?email=${value}`)
  return response
}

export const isNeedToRedirect = () => {
  const beforeLogin = sessionStorage.getItem('beforeLogin')
  let response
  if(beforeLogin) {
    response = sessionStorage.getItem('beforeLogin')
    sessionStorage.removeItem('beforeLogin')
  } else {
    response = '/creator/request'
  }
  return response
}