import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import secondsToHhMmSsHelper from 'src/common/functions/time.function'
import {
  Card,
  CardFileUpload,
  CardItem,
  CardItemGroup,
  CardItemMulti,
  CountingStar,
  DescriptionCard,
} from '../styled/card'
import { AntSwitch, CustomRadio, RadioContainer } from '../styled/buttons'
import { ModalContext } from 'src/providers/modal.provider'
import UploadSubtitleModal from '../modals/upload-subtitle-modal'
import { useAppDispatch } from 'src/app/hooks'
import { RequestProject } from 'src/features/request/request.slice'
import TranslationLanguageSelect from 'src/common/components/select/translation-language-select'
import GenreSelect from 'src/common/components/select/genre-select'
import { Stack } from '@mui/material'
import TranscriptionLanguageSelect from 'src/common/components/select/transcription-language-select'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { RequestSelected } from './request-youtube'
import {
  downYoutubeSubtitle,
  getYoutubeSubtitle,
} from 'src/features/video/video.service'
import { SubtitleType } from 'src/features/video/video.slice'
import { CustomTooltipStyle } from 'src/common/components/styled/button.style'
import { useTranslation } from 'react-i18next'

import { LOCALFILE_UPLOAD_TITLE_LANGTH, LOCALFILE_UPLOAD_DESCRIPTION_LANGTH } from 'src/app/constants'

interface Props {
  requestType: string
  videoIndex: string
  youtubeVideoList: any
  selectedVideo: RequestProject
  setSelectedVideo: any
  handleUpload: any
  subtitles: SubtitleType[]
}

// @todo 부모 요소에 의존하는 요소 제거
export default function RequestYoutube(props: Props) {
  const dispatch = useAppDispatch()
  const { setModal } = useContext(ModalContext)
  const disabled = props.videoIndex === null
  const [showTooltip, setShowTooltip] = useState(false)
  const [showMoreDesc, setShowMoreDesc] = useState(false)

  const [titleInputCount, setTitleInputCount] = useState('');
  const [descriptionInputCount, setdescriptionInputCount] = useState('');
  const { t } = useTranslation()

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: CustomTooltipStyle,
  }))

  const handleTitleInputChange = (event) => {
    setTitleInputCount(event.target.value);
  };

  const handleDescriptionInputChange = (event) => {
    setdescriptionInputCount(event.target.value);
  };
  const handleRemoveFile = () => {
    props.setSelectedVideo({
      ...props.selectedVideo,
      subtitles: [],
      fileName: null,
    })
  }

  const handleShowDescriptionMore = () => {
    setShowMoreDesc(true)
  }

  const handleChangeTaskType = (e) => {
    if (e.target.id === 'TRANSCRIPTION') {
      handleRemoveFile()
      props.setSelectedVideo({
        ...props.selectedVideo,
        taskType: e.target.id,
        targetLanguageCode: props.selectedVideo?.sourceLanguageCode
          ? [props.selectedVideo?.sourceLanguageCode]
          : [],
      })
      /* translation */
    } else {
      dispatch(getYoutubeSubtitle({ url: props.selectedVideo?.sourceUrl }))
      props?.setSelectedVideo({
        ...props.selectedVideo,
        taskType: e.target.id,
      })
    }
  }

  const handleCheckOrderDescTranslation = (e) => {
    props.setSelectedVideo({
      ...props.selectedVideo,
      descriptionTranslationRequested: e.target.checked,
      descriptionPricePerLength:
        props.selectedVideo.descriptionSource.length <= 2000 &&
        props.selectedVideo.descriptionSource.length > 0
          ? 2000
          : props.selectedVideo.descriptionSource.length > 2000 && 4000,
    })
  }

  useEffect(() => {
    if (
      props.selectedVideo?.taskType === 'TRANSLATION' &&
      props.selectedVideo?.sourceLanguageCode &&
      props.selectedVideo?.type === RequestSelected.MyChannel
    ) {
      getSubtitleFile(props.selectedVideo.sourceLanguageCode)
    }
  }, [])

  const handleTranslationSourceLang = useCallback(
    (option) => {
      props?.setSelectedVideo({
        ...props.selectedVideo,
        sourceLanguageCode: option?.value || null,
        targetLanguageCode: [],
      })
      if (props.selectedVideo?.type === RequestSelected.MyChannel) {
        getSubtitleFile(option.value)
      }
    },
    [props.selectedVideo, props.subtitles],
  )

  function getSubtitleFile(sourceLang: string) {
    if (props.subtitles?.length) {
      const videoId = props.subtitles.filter(
        (subtitle) => subtitle.language === sourceLang,
      )[0]?.id
      videoId &&
        dispatch(downYoutubeSubtitle({ url: videoId }))
          .unwrap()
          .then((res) => {
            props.handleUpload(res, `${props.selectedVideo.titleSource}.srt`)
          })
          .catch((err) => {})
    }
  }

  // if (props.youtubeVideoList.length === 0) return <Fragment />
  return (
    <Fragment>
      <Card marginTop={30} disabled={disabled}>
        <CardItemGroup>
          <CardItemMulti>
            <RadioContainer>
              <div className="box">
                <CustomRadio htmlFor="TRANSLATION">
                  <input
                    type="radio"
                    id="TRANSLATION"
                    name="type"
                    disabled={disabled}
                    checked={
                      props.selectedVideo?.taskType === 'TRANSLATION' || false
                    }
                    onChange={handleChangeTaskType}
                  />
                  <span></span>
                </CustomRadio>
                <p className="title">{t(`request.text-10`)}</p>
              </div>
              <div className="box">
                <CustomRadio htmlFor="TRANSCRIPTION">
                  <input
                    type="radio"
                    id="TRANSCRIPTION"
                    name="type"
                    disabled={disabled}
                    checked={
                      props.selectedVideo?.taskType === 'TRANSCRIPTION' || false
                    }
                    onChange={handleChangeTaskType}
                  />
                  <span></span>
                </CustomRadio>
                <p className="title">{t(`request.text-11`)}</p>
              </div>
            </RadioContainer>

            <div className="multi-languages">
              <div>
                {t(`request.text-12`)}
                <CountingStar />
              </div>

              {props.selectedVideo?.taskType === 'TRANSCRIPTION' ? (
                <div className="card-item-input-container-multi">
                  <div>
                    <TranscriptionLanguageSelect
                      selectedLanguageCode={
                        props.selectedVideo?.sourceLanguageCode
                      }
                      disabled={disabled}
                      onChange={(option) => {
                        const value = option?.value || null
                        props.setSelectedVideo({
                          ...props.selectedVideo,
                          sourceLanguageCode: value,
                          targetLanguageCode: value ? [option.value] : [],
                        })
                      }}
                    />
                    <p>
                      {props.selectedVideo?.sourceLanguageCode || disabled
                        ? ''
                        : `${t(`request.text-36`)}`}
                    </p>
                  </div>
                  <div className="arrow">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icon/icon-arrow-right-grey.svg`}
                      alt="arrow"
                    />
                  </div>
                  <div>
                    <TranscriptionLanguageSelect
                      selectedLanguageCode={
                        props.selectedVideo?.sourceLanguageCode
                      }
                      placeholder={t(`request.text-61`)}
                      disabled={true}
                      onChange={(option) => {
                        const value = option?.value || null
                        props.setSelectedVideo({
                          ...props.selectedVideo,
                          sourceLanguageCode: value,
                          targetLanguageCode: value ? [option.value] : [],
                        })
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="card-item-input-container-multi">
                  <div>
                    <TranslationLanguageSelect
                      placeholder={t(`request.text-60`)}
                      type="Source"
                      selectedLanguageCode={
                        props.selectedVideo?.sourceLanguageCode
                      }
                      disabled={disabled}
                      onChange={(option) => {
                        handleTranslationSourceLang(option)
                      }}
                    />
                    <p>
                      {props.selectedVideo?.sourceLanguageCode || disabled
                        ? ''
                        : `${t(`request.text-36`)}`}
                    </p>
                  </div>
                  <div className="arrow">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icon/icon-arrow-right-grey.svg`}
                      alt="arrow"
                    />
                  </div>
                  <div>
                    <TranslationLanguageSelect
                      type="Target"
                      placeholder={t(`request.text-61`)}
                      disabled={disabled}
                      sourceLanguageCode={
                        props.selectedVideo?.sourceLanguageCode
                      }
                      selectedLanguageCode={
                        props.selectedVideo?.targetLanguageCode[0]
                      }
                      onChange={(option) => {
                        props.setSelectedVideo({
                          ...props.selectedVideo,
                          targetLanguageCode: option?.value
                            ? [option.value]
                            : [],
                        })
                      }}
                    />
                    <p>
                      {props.selectedVideo?.targetLanguageCode?.length ||
                      disabled
                        ? ''
                        : `${t(`request.text-36`)}`}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </CardItemMulti>

          <CardItem className="card-item-runtime-container">
            <div>{t(`request.text-13`)}</div>
            <div className="runtime">
              {secondsToHhMmSsHelper(props.selectedVideo?.runtime)}
            </div>
          </CardItem>

          {(props.selectedVideo?.taskType === 'TRANSLATION' || disabled) && (
            <>
            {props.selectedVideo?.type!==2 ? (
              <CardItem>
                <div>{t(`request.text-63`)}</div>
                <div className="card-item-input-container item-textarea">
                  <span className="title">
                    {props.selectedVideo?.titleSource}
                  </span>
                </div>
              </CardItem>
            ) : (
              <CardItem>
                <div>
                  {t(`request.text-63`)}
                  <CountingStar />
                </div>
                
                <div className="card-item-input-container item-textarea">
                  <span className="title">
                      <input
                        type="text"
                        maxLength={LOCALFILE_UPLOAD_TITLE_LANGTH}
                        placeholder={t(`request.localfile-title-placeholder`)}
                        value={props.selectedVideo?.titleSource}
                        disabled={disabled}
                        onChange={(text) => {
                          props.setSelectedVideo({
                            ...props.selectedVideo,
                            titleSource: text.target.value ? text.target.value : ''
                          })
                        }}
                      />
                  </span>
                  <div className="description-label">
                    {t(`request.localfile-title-description`)}
                    <p>{props.selectedVideo.titleSource?.length || 0} / {LOCALFILE_UPLOAD_TITLE_LANGTH}</p>
                  </div>
                </div>
              </CardItem>
            )}
              <DescriptionCard>
                {(
                  <div className="checkbox-area">
                    <input
                      type="checkbox"
                      id="request-translate-description"
                      onChange={(e) => handleCheckOrderDescTranslation(e)}
                      checked={
                        props.selectedVideo?.descriptionTranslationRequested
                      }
                      disabled={
                        props.selectedVideo?.descriptionSource === '' ||
                        disabled
                      }
                    />
                    <p
                      style={{
                        opacity:
                          props.selectedVideo?.descriptionSource === '' && 0.6,
                      }}
                    >
                      {t('request.text-70')}
                    </p>
                  </div>
                )}

                <CardItem isMore={showMoreDesc} className="description-box-padding">
                  <div className="description-title">
                    {t(`request.text-14`)}
                    {(
                      <div className="checkbox-area-mob">
                        <input
                          type="checkbox"
                          id="request-translate-description"
                          onChange={(e) => handleCheckOrderDescTranslation(e)}
                          checked={
                            props.selectedVideo?.descriptionTranslationRequested
                          }
                          disabled={
                            props.selectedVideo?.descriptionSource === '' ||
                            disabled
                          }
                        />
                        <p
                          style={{
                            opacity:
                              props.selectedVideo?.descriptionSource === '' &&
                              0.6,
                          }}
                        >
                          {t('request.text-70')}
                        </p>
                      </div>
                    )}
                  </div>
                  {props.selectedVideo?.type!==2 ? (
                  <div className="card-item-input-container item-textarea">
                    <div className="description">
                      {props.selectedVideo?.descriptionSource === ''
                        ? t('request.text-72')
                        : props.selectedVideo?.descriptionSource}
                    </div>

                    {props.selectedVideo?.descriptionSource !== '' &&
                      !disabled &&
                      !showMoreDesc && (
                        <div
                          className="open-more"
                          onClick={handleShowDescriptionMore}
                        >
                          {t('request.text-71')}
                        </div>
                      )}
                  </div>
                  ) : (
                    <div className="card-item-input-container item-textarea">
                    <textarea
                      maxLength={LOCALFILE_UPLOAD_DESCRIPTION_LANGTH}
                      placeholder={t(`request.localfile-description-placeholder`)}
                      value={props.selectedVideo?.descriptionSource}
                      disabled={disabled}
                      onChange={(text) => {
                        props.setSelectedVideo({
                          ...props.selectedVideo,
                          descriptionSource: text.target.value ? text.target.value : ''
                        })
                      }}
                    />
                    <div className="description-label">
                      {t('request.localfile-no-description')}
                      <p>{props.selectedVideo.descriptionSource?.length || 0} / {LOCALFILE_UPLOAD_DESCRIPTION_LANGTH}</p>
                    </div>
                  </div>
                  )}
                  {/* {props.selectedVideo?.descriptionSource !== '' ? null : (
                    <div className="checkbox-area-in">
                      <input
                        type="checkbox"
                        id="request-translate-description"
                        onChange={(e) => handleCheckOrderDescTranslation(e)}
                        checked={
                          props.selectedVideo?.descriptionTranslationRequested
                        }
                        disabled={
                          props.selectedVideo?.descriptionSource === '' ||
                          disabled
                        }
                      />
                      <p
                        style={{
                          opacity:
                            props.selectedVideo?.descriptionSource === '' &&
                            0.6,
                        }}
                      >
                        {t('request.text-70')}
                      </p>
                    </div>
                  )} */}

                </CardItem>
              </DescriptionCard>
            </>
          )}
          {props.selectedVideo?.type!==2 ? (
            props.selectedVideo?.type === RequestSelected.YoutubeLink ? (
              ''
            ) : (
              <CardItem className="card-item-input-container-toggle">
                <div>{t(`request.text-15`)}</div>
                <div className="card-item-input-container">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AntSwitch
                      checked={props.selectedVideo?.autoCaptionUpload}
                      onChange={(e) => {
                        props.setSelectedVideo({
                          ...props.selectedVideo,
                          autoCaptionUpload: e.target.checked,
                        })
                      }}
                      disabled={disabled}
                      inputProps={{ 'aria-label': 'ant design' }}
                    />
                  </Stack>
                </div>
              </CardItem>
            )
          ) : (null)}

          <CardItem
            style={{
              alignItems: 'center',
            }}
          >
            <div>{t(`request.text-16`)}</div>
            <div className="card-item-input-container">
              <GenreSelect
                placeholder={t(`request.text-16`)}
                selectedGenre={props.selectedVideo?.genre}
                disabled={disabled}
                onChange={(option) => {
                  props.setSelectedVideo({
                    ...props.selectedVideo,
                    genre: option?.value,
                  })
                }}
              />
            </div>
          </CardItem>
          {props.selectedVideo?.type!==2 ? (
            props.selectedVideo?.taskType === 'TRANSLATION' || disabled ? (
              <CardFileUpload>
                <div
                  className="title"
                  onClick={() => setShowTooltip(!showTooltip)}
                >
                  {t(`request.text-17`)}
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div>
                          <p style={{ fontWeight: 700 }}>
                            {t(`request.text-39-3`)}
                          </p>
                          <p>{t(`request.text-39-4`)}</p>
                        </div>
                        <div style={{ marginTop: '15px' }}>
                          <p style={{ fontWeight: 700 }}>
                            {t(`request.text-39-1`)}
                          </p>
                          <p>{t(`request.text-39-2`)}</p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icon/icon-info-black.svg`}
                      alt="info"
                      style={{ cursor: 'pointer' }}
                    />
                  </HtmlTooltip>
                </div>
                <div className="multi-files">
                  {props.selectedVideo?.fileName ? (
                    <div className="uploaded">
                      <div className="chip" onClick={handleRemoveFile}>
                        <p>{props.selectedVideo?.fileName}</p>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/icon/icon-delete-file.svg`}
                          alt="delete icon"
                        />
                      </div>
                    </div>
                  ) : (
                    <Fragment>
                      <span>{t(`request.text-62`)}</span>
                      <button
                        onClick={() =>
                          setModal(
                            <UploadSubtitleModal
                              onRemoveFile={handleRemoveFile}
                              onUpload={props?.handleUpload}
                              onCancel={() => setModal(null)}
                            />,
                          )
                        }
                        disabled={disabled}
                      >
                        {t(`request.text-18`)}
                      </button>
                    </Fragment>
                  )}
                </div>
              </CardFileUpload>
            ) : null
          ) : (null)}
        </CardItemGroup>
      </Card>
    </Fragment>
  )
}
