import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import {
  deleteProfileImage,
  editProfileImage,
  editUserLanguage,
  editUserName,
  getProfile,
  toggleCaptionUpload,
  turnEmailNotification,
} from './profile.service'

export interface Profile {
  balance: number
  id: number
  email: string
  nickname: string
  firstName: Nullable<string>
  lastName: Nullable<string>
  middleName: Nullable<string>
  phoneNumber: any
  countryPhoneCode: any
  countryPhoneNumber: any
  profileImageUrl: Nullable<string>
  emailNotification: boolean
  autoCaptionUpload: boolean
  isSnsUser: boolean
  serviceLanguage: 'ko' | 'en'
}

export interface ISidebar {}

interface ProfileState {
  profile: Nullable<Profile>
  sideBar: Nullable<ISidebar>
  isLoading: boolean
  error: any
}

const initialState: ProfileState = {
  profile: null,
  sideBar: null,
  isLoading: false,
  error: null,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setCredit: (state, action: PayloadAction<{ charge: number }>) => {
      const balance = state?.profile.balance
      if (balance) {
        state.profile.balance = balance - action.payload.charge
      }
    },
    setUserName: (
      state,
      action: PayloadAction<{ lastName: string; firstName: string }>,
    ) => {
      state.profile.lastName = action.payload.lastName
      state.profile.firstName = action.payload.firstName
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProfile.fulfilled, (state, { payload }: any) => {
        state.isLoading = false
        state.profile = payload.profile
      })
      .addCase(getProfile.rejected, (state, { payload, error }) => {
        state.isLoading = false
      })
      .addCase(editUserName.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editUserName.fulfilled, (state, { payload }: any) => {
        state.isLoading = false
        state.profile.lastName = payload.lastName
        state.profile.firstName = payload.firstName
      })
      .addCase(editUserName.rejected, (state, { payload, error }) => {
        state.isLoading = false
      })
      .addCase(toggleCaptionUpload.pending, (state) => {
        state.isLoading = true
      })
      .addCase(toggleCaptionUpload.fulfilled, (state, { payload }: any) => {
        state.isLoading = false
        state.profile.autoCaptionUpload = payload.autoCaptionUpload
      })
      .addCase(toggleCaptionUpload.rejected, (state, { payload, error }) => {
        state.isLoading = false
      })
      .addCase(editProfileImage.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editProfileImage.fulfilled, (state, { payload }: any) => {
        state.isLoading = false
        state.profile.profileImageUrl = payload.profileImage
      })
      .addCase(editProfileImage.rejected, (state, { payload, error }) => {
        state.isLoading = false
      })
      .addCase(deleteProfileImage.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteProfileImage.fulfilled, (state, { payload }: any) => {
        state.isLoading = false
        state.profile.profileImageUrl = ''
      })
      .addCase(deleteProfileImage.rejected, (state, { payload, error }) => {
        state.isLoading = false
      })
      .addCase(turnEmailNotification.pending, (state) => {
        state.isLoading = true
      })
      .addCase(turnEmailNotification.fulfilled, (state, { payload }: any) => {
        state.isLoading = false
        state.profile.emailNotification = payload.changedStatus
      })
      .addCase(turnEmailNotification.rejected, (state, { payload, error }) => {
        state.isLoading = false
      })
      .addCase(editUserLanguage.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        editUserLanguage.fulfilled,
        (
          state,
          { payload }: PayloadAction<{ serviceLanguage: 'ko' | 'en' }>,
        ) => {
          state.isLoading = false
          state.profile.serviceLanguage = payload.serviceLanguage
        },
      )
      .addCase(editUserLanguage.rejected, (state, { payload, error }) => {
        state.isLoading = false
      })
  },
})

export const { setCredit } = profileSlice.actions

const selfSelector = (state: RootState) => state.profile
export const profileSelectors = createSelector(selfSelector, (state) => state)

export const getNickname = createSelector(
  selfSelector,
  (state) => state.profile?.nickname || 'Guest',
)

export const getBalance = createSelector(
  selfSelector,
  (state) => state.profile?.balance || 0,
)

export const getUserName = createSelector(selfSelector, (state) => {
  return state.profile?.lastName && state.profile?.firstName
    ? state.profile.lastName + state.profile.firstName
    : '-'
})

export const getUserProfile = createSelector(
  selfSelector,
  (state) => state.profile?.profileImageUrl,
)

export const getUserId = createSelector(
  selfSelector,
  (state) => state.profile?.id,
)

export const getUserEmail = createSelector(
  selfSelector,
  (state) => state.profile?.email || 'no-email',
)

export const getEmailNotification = createSelector(
  selfSelector,
  (state) => state.profile?.emailNotification || false,
)

export const getUserAutoCaptionUpload = createSelector(
  selfSelector,
  (state) => state.profile?.autoCaptionUpload,
)

export const getIsSnsUser = createSelector(
  selfSelector,
  (state) => state.profile?.isSnsUser || false,
)

export const getServiceLanguage = createSelector(
  selfSelector,
  (state) => state.profile?.serviceLanguage || 'ko',
)

export default profileSlice.reducer
