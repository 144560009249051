import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import DateSelect from 'src/common/components/select/date-select'
import { useTranslation } from 'react-i18next'
import { FormData } from '../requests'

interface Props {
  defaultFormData: any
  typeList: {
    label: string
    value: string
  }[]
  projectStatusList: {
    label: string
    value: number
  }[]
  formData: {
    take: number
    skip: number
    type: any
    projectId: any
    projectStatus: any
    dateFrom: any
    dateTo: any
  }
  setFormData: React.Dispatch<React.SetStateAction<FormData>>
  onSearch: (formData) => void
  noFilter: boolean
  onClose: () => void
}

export default function MobileFilterPopup(props: Props) {
  const { t } = useTranslation()
  const projectIdRef = useRef(null)

  const handleClose = () => {
    props.onClose()
  }

  const handleClear = () => {
    props.setFormData(props.defaultFormData)
    projectIdRef.current.value = null
  }
  return (
    <Container>
      <Header>
        <h2>요청내역 검색</h2>
        <img
          src={`${process.env.PUBLIC_URL}/assets/icon/icon-cancel-black.svg`}
          alt="close filter"
          onClick={handleClose}
        />
      </Header>
      <Body>
        <div className="filter-box">
          <label>{t(`requests.text-1`)}</label>
          <Select
            value={
              props.typeList.find(
                (type) => type.value === props.formData.type,
              ) || null
            }
            options={props.typeList}
            onChange={(option) => {
              props.setFormData({
                ...props.formData,
                type: option?.value || null,
              })
            }}
            isClearable
            placeholder={t(`requests.text-34`)}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={{
              control: (base) => ({
                ...base,
                height: 40,
              }),
              valueContainer: (base) => ({
                ...base,
                height: 40,
                padding: '0 8px',
              }),
              indicatorsContainer: (base) => ({
                ...base,
                height: 40,
              }),
              input: (base) => ({
                ...base,
                margin: 0,
                padding: 0,
              }),
            }}
          />
        </div>

        <div className="filter-box">
          <label>{t(`requests.text-2`)}</label>
          <Select
            value={
              props.projectStatusList.find(
                (status) => status.value === props.formData.projectStatus,
              ) || null
            }
            options={props.projectStatusList}
            onChange={(option) => {
              props.setFormData({
                ...props.formData,
                projectStatus: option?.value || null,
              })
            }}
            isClearable
            placeholder={t(`requests.text-34`)}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={{
              control: (base) => ({
                ...base,
                height: 40,
                // width: 188,
              }),
              valueContainer: (base) => ({
                ...base,
                height: 40,
                padding: '0 8px',
              }),
              indicatorsContainer: (base) => ({
                ...base,
                height: 40,
              }),
              input: (base) => ({
                ...base,
                margin: 0,
                padding: 0,
              }),
            }}
          />
        </div>

        <div className="filter-box">
          <label>{t(`requests.text-3`)}</label>

          <input
            ref={projectIdRef}
            type="text"
            maxLength={4}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            value={props.formData.projectId}
            placeholder="No.0000"
            onChange={(e) => {
              props.setFormData({
                ...props.formData,
                projectId: e.target.value,
              })
            }}
          />
        </div>

        <div className="filter-box filter-box-date">
          <label>{t(`requests.text-4`)}</label>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <DateSelect
              value={props.formData.dateFrom}
              max={props.formData.dateTo}
              onChange={(date) => {
                props.setFormData({
                  ...props.formData,
                  dateFrom: date,
                })
              }}
            />
            {' ~ '}
            <DateSelect
              value={props.formData.dateTo}
              min={props.formData.dateFrom}
              onChange={(date) => {
                props.setFormData({
                  ...props.formData,
                  dateTo: date,
                })
              }}
            />
          </div>
        </div>
      </Body>
      <Footer>
        <button
          disabled={props.noFilter}
          className="negative"
          onClick={handleClear}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/icon/icon-refresh${
              props.noFilter ? '-disabled' : ''
            }.svg`}
          />
          {t(`requests.text-10`)}
        </button>
        <button className="positive" onClick={props.onSearch}>
          {t(`requests.text-11`)}
        </button>
      </Footer>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  margin: 0;

  display: flex;
  gap: 20px;

  .filter-box {
    display: flex;
    flex-direction: column;
    gap: 10px;

    label {
      margin: 0;
    }

    input[type='date']::before {
      content: attr(data-placeholder);
      width: 100%;
    }

    input[type='date']:focus::before,
    input[type='date']:valid::before {
      display: none;
    }
  }

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #444444;
  }

  input {
    padding: 12px 15px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }

  // desktop
  @media screen and (min-width: 959px) {
    input {
      width: 188px;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    flex-direction: column;

    input {
      width: 100%;
    }

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 48px;
      border: none;
      border-radius: 8px;
      background-color: #1a61f7;
      font-weight: 700;
      font-size: 16px;
      line-height: 48px;
      color: #fff;
    }
  }
`

const Header = styled.div`
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 0;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #111;
  }

  img {
    position: absolute;
    right: 20px;
    width: 18px;
    height: 18px;
  }
`

const Body = styled.div`
  height: calc(100vh - 62px - 64px - 66px);
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Footer = styled.div`
  border-top: 1px solid #cccccc;
  height: 66px;
  padding: 11px 20px;
  display: flex;
  gap: 10px;

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 46px;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 13px;
  }

  button.negative {
    gap: 5px;
    background: #cccccc;
    color: #444444;
    background: #ffffff;
    border: 1.5px solid #111111;

    &:disabled {
      color: #aaaaaa;
      background: #eaeaea;
      border: 1.5px solid #eaeaea;
    }
  }

  button.positive {
    flex: 1;
    background: #1a61f7;
    color: #ffffff;
  }

  @media (max-width: 375px) {
    button.negative {
      width: 96px;
    }
  }

  @media (min-width: 375.02px) {
    button.negative {
      width: 235px;
    }
  }
`
