import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import { Card, CardLabel } from './styled/card'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import Select from 'react-select'
import {
  getMyRequests,
  getMyRequestsSearch,
  getMyRequestSubtitles,
} from 'src/features/request/request.service'
import secondsToHhMmSsHelper from 'src/common/functions/time.function'
import { GENRE_LIST, LANGUAGE_LIST } from 'src/app/constants'
import DateSelect from 'src/common/components/select/date-select'
import { ModalContext } from 'src/providers/modal.provider'
import DownloadSubtitleModal from './modals/download-subtitle-modal'
import { saveAs } from 'file-saver'
import MobileFilterPopup from './popups/mobile-filter-popup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getCurrentLanguage } from 'src/features/main/main.slice'
import { LanguageType } from 'src/locale/i18n'
import TranslationCompleteModal from './modals/title-description-translation-complete-modal'

interface Props {}

const LIMIT_PROGRESS = 6
const DEFAULT_TAKE = 20
const DEFAULT_SKIP = 0

// const typeList = [
//   { label: '번역요청', value: 'TRANSLATION' },
//   { label: '번역없이 자막 요청', value: 'TRANSCRIPTION' },
// ]

// const projectStatusList = [
//   { label: '대기', value: 210 },
//   { label: '진행중', value: 300 },
//   { label: '완료', value: 400 },
// ]

enum MobileTabType {
  NONE = 0,
  REQUEST = 1,
  CREDIT = 2,
}

const DEFAULT_FORM_DATA: FormData = {
  take: DEFAULT_TAKE,
  skip: DEFAULT_SKIP,
  type: null,
  projectId: null,
  projectStatus: null,
  dateFrom: null,
  dateTo: null,
}

export interface FormData {
  take: number
  skip: number
  type: any
  projectId: any
  projectStatus: any
  dateFrom: any
  dateTo: any
}

type LanguageProps = {
  language: LanguageType
}

type RequestStatus = 'INIT' | 'SEARCH' | 'IDLE'

export default function Requests(props: Props) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { setModal, setScrollable } = useContext(ModalContext)
  const [mobileTab, setMobileTab] = useState<MobileTabType>(MobileTabType.NONE)
  const [openFilter, setOpenFilter] = useState(false)
  const [projects, setProjects] = useState([])
  const [tasks, setTasks] = useState([])
  const [openIndex, setOpenIndex] = useState(null)
  const [formData, setFormData] = useState<FormData>(DEFAULT_FORM_DATA)
  const [status, setStatus] = useState<RequestStatus>('INIT')
  const projectIdRef = useRef(null)
  const [searchList, setSearchList] = useState([])
  const currentLanguage = useAppSelector(getCurrentLanguage)

  const { t } = useTranslation()

  const typeList = [
    { label: t(`requests.text-5`), value: 'TRANSLATION' },
    { label: t(`requests.text-6`), value: 'TRANSCRIPTION' },
  ]

  const projectStatusList = [
    { label: t(`requests.text-7`), value: 210 },
    { label: t(`requests.text-8`), value: 300 },
    { label: t(`requests.text-9`), value: 400 },
  ]

  const genreList = [
    { label: t('common.genre.Gaming'), value: 'Gaming' },
    { label: t('common.genre.Science&Technology'), value: 'Science & Technology' },
    { label: t('common.genre.Education'), value: 'Education' },
    { label: t('common.genre.Howto&Style'), value: 'Howto & Style' },
    { label: t('common.genre.News&Politics'), value: 'News & Politics' },
    { label: t('common.genre.Nonprofits&Activism'), value: 'Nonprofits & Activism' },
    { label: t('common.genre.Sports'), value: 'Sports' },
    { label: t('common.genre.Pets&Animals'), value: 'Pets & Animals' },
    { label: t('common.genre.Entertainment'), value: 'Entertainment' },
    { label: t('common.genre.Travel&Events'), value: 'Travel & Events' },
    { label: t('common.genre.Film&Animation'), value: 'Film & Animation' },
    { label: t('common.genre.Music'), value: 'Music' },
    { label: t('common.genre.People&Blogs'), value: 'People & Blogs' },
    { label: t('common.genre.Autos&Vehicles'), value: 'Autos & Vehicles' },
    { label: t('common.genre.Comedy'), value: 'Comedy' },
    { label: t('common.genre.Others'), value: 'Others' },
  ]
  const noFilter = useMemo(() => {
    return (
      formData.type === null &&
      !formData.projectId &&
      formData.projectStatus === null &&
      formData.dateFrom === null &&
      formData.dateTo === null
    )
  }, [formData])

  const getProjects = () => {
    // @todo skip, take
    dispatch(
      getMyRequests({
        ...DEFAULT_FORM_DATA,
        skip: DEFAULT_SKIP,
        take: DEFAULT_TAKE,
      }),
    )
      .unwrap()
      .then((response) => {
        setProjects(response.projects)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setTasks([])
        setStatus('INIT')
        setOpenFilter(false)
      })
  }

  useEffect(() => {
    getProjects()
  }, [])

  const handleClickComplete = (
    category: string,
    source: string,
    target: string,
  ) => {
    setScrollable(false)
    setModal(
      <TranslationCompleteModal
        onClose={() => setModal(null)}
        categoryType={category}
        source={source}
        target={target}
      />,
    )
  }

  const getTasks = (data: typeof DEFAULT_FORM_DATA) => {
    dispatch(getMyRequestsSearch({ ...data }))
      .unwrap()
      .then((response) => {
        if (response.tasks.length === 0) {
          const searchObject = Object.keys(formData).reduce(
            (result, key: string) => {
              const value = formData[key]
              if (key !== 'take' && key !== 'skip' && value !== null) {
                if (key === 'type') {
                  result[key] = `'${t(`requests.text-1`)} ${
                    value === 'TRANSCRIPTION'
                      ? t(`requests.text-6`)
                      : t(`requests.text-5`)
                  }'`
                } else if (key === 'projectId') {
                  result[key] = `'${t(`requests.text-3`)} ${value}'`
                } else if (key === 'projectStatus') {
                  result[key] = `'${t(`requests.text-2`)} ${
                    value === 210
                      ? t(`requests.text-7`)
                      : value === 300
                      ? t(`requests.text-8`)
                      : t(`requests.text-9`)
                  }'`
                } else if (key === 'dateFrom') {
                  result['date'] = `'${t(`requests.text-4`)} ${value} ~ '`
                } else if (key === 'dateTo') {
                  const date = result['date']
                  if (date) {
                    result['date'] =
                      date.slice(0, date.length - 1) + `${value}'`
                  } else {
                    result['date'] = `'${t(`requests.text-4`)} ~ ${value}'`
                  }
                }
              }
              return result
            },
            {},
          )

          setSearchList(Object.values(searchObject))
        }

        setTasks(response.tasks)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setProjects([])
        setStatus('SEARCH')
        setOpenFilter(false)
      })
  }

  const handleReset = () => {
    setFormData(DEFAULT_FORM_DATA)
    projectIdRef.current.value = null
  }

  const handleClear = () => {
    handleReset()
    setStatus('INIT')
    getProjects()
  }

  const handleSearch = () => {
    getTasks(formData)
  }

  // @todo 안드로이드에서 resize 계속
  useEffect(() => {
    handleUpdateWindowWidth()
    window.addEventListener('resize', handleUpdateWindowWidth)
    return () => window.removeEventListener('resize', handleUpdateWindowWidth)
  }, [])

  const handleUpdateWindowWidth = () => {
    if (959.02 > window.innerWidth) {
      setMobileTab(MobileTabType.REQUEST)
    } else {
      setMobileTab(MobileTabType.NONE)
      setOpenFilter(false)
    }
  }

  const handleDetail = (index) => {
    setOpenIndex((prev) => (prev === index ? null : index))
  }

  const handleDownloadSubtitles = (projectId: number) => {
    dispatch(
      getMyRequestSubtitles({
        projectId,
      }),
    )
      .unwrap()
      .then(({ files }) => {
        setModal(
          <DownloadSubtitleModal
            files={files}
            onDownload={(file: { name: string; url: string }) => {
              saveAs(file.url, file.name)
            }}
            onClose={() => setModal(null)}
          />,
        )
      })
      .catch((error) => {
        console.error(error)
        alert(t(`requests.text-33`))
      })
  }

  const handleMobileFilter = () => {
    setOpenFilter(true)
  }

  const renderFilter = () => {
    if (mobileTab === MobileTabType.NONE) {
      return (
        <Card marginTop={38}>
          <FilterContainer>
            <div className="filter-box">
              <label>{t(`requests.text-1`)}</label>
              <Select
                value={
                  typeList.find((type) => type.value === formData.type) || null
                }
                options={typeList}
                onChange={(option) => {
                  setFormData({
                    ...formData,
                    type: option?.value || null,
                  })
                }}
                isClearable
                placeholder={t(`requests.text-34`)}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 40,
                    // width: 188,
                    width: '100%',
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    height: 40,
                    padding: '0 8px',
                  }),
                  indicatorsContainer: (base) => ({
                    ...base,
                    height: 40,
                  }),
                  input: (base) => ({
                    ...base,
                    margin: 0,
                    padding: 0,
                  }),
                }}
              />
            </div>

            <div className="filter-box">
              <label>{t(`requests.text-2`)}</label>
              <Select
                value={
                  projectStatusList.find(
                    (status) => status.value === formData.projectStatus,
                  ) || null
                }
                options={projectStatusList}
                onChange={(option) => {
                  setFormData({
                    ...formData,
                    projectStatus: option?.value || null,
                  })
                }}
                isClearable
                placeholder={t(`requests.text-34`)}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 40,
                    // width: 188,
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    height: 40,
                    padding: '0 8px',
                  }),
                  indicatorsContainer: (base) => ({
                    ...base,
                    height: 40,
                  }),
                  input: (base) => ({
                    ...base,
                    margin: 0,
                    padding: 0,
                  }),
                }}
              />
            </div>

            <div className="filter-box">
              <label>{t(`requests.text-3`)}</label>

              <input
                ref={projectIdRef}
                type="text"
                maxLength={4}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                value={formData.projectId}
                placeholder="No.0000"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    projectId: e.target.value,
                  })
                }}
              />
            </div>

            <div className="filter-box filter-box-date">
              <label>{t(`requests.text-4`)}</label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 10,
                }}
              >
                <DateSelect
                  value={formData.dateFrom}
                  max={formData.dateTo}
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      dateFrom: date,
                    })
                  }}
                />
                {' ~ '}
                <DateSelect
                  value={formData.dateTo}
                  min={formData.dateFrom}
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      dateTo: date,
                    })
                  }}
                />
              </div>
            </div>
          </FilterContainer>
          <ButtonContainer>
            <ResetButton
              onClick={handleReset}
              disabled={noFilter ? true : false}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/icon/icon-refresh${
                  noFilter ? '-disabled' : ''
                }.svg`}
              />
              {t(`requests.text-10`)}
            </ResetButton>
            <SearchButton onClick={handleSearch}>
              {t(`requests.text-11`)}
            </SearchButton>
          </ButtonContainer>
        </Card>
      )
    } else {
      return (
        <>
          {openFilter ? (
            <MobileFilterPopup
              defaultFormData={DEFAULT_FORM_DATA}
              typeList={typeList}
              projectStatusList={projectStatusList}
              formData={formData}
              setFormData={setFormData}
              onSearch={handleSearch}
              noFilter={noFilter}
              onClose={() => {
                setOpenFilter(false)
              }}
            />
          ) : null}
        </>
      )
    }
  }

  const renderProjects = () => {
    if (status !== 'INIT') return null

    return (
      <Fragment>
        {projects?.length ? (
          projects.map((data, index) => (
            <Card key={`request-${index}-${data.id}`} marginTop={27}>
              <RequestContainer>
                <RequestNumber onClick={() => handleDetail(index)}>
                  <div>
                    {t(`requests.text-16`)} GloHub
                    {data.id.toString().padStart(4, '0')}
                  </div>
                  <div>
                    <img
                      src={
                        openIndex === index
                          ? `${process.env.PUBLIC_URL}/assets/icon/icon-close-detail.svg`
                          : `${process.env.PUBLIC_URL}/assets/icon/icon-open-detail.svg`
                      }
                      alt="open detail"
                    />
                  </div>
                </RequestNumber>
                <RequestData onClick={() => handleDetail(index)}>
                  <div>
                    <div className="title-container">
                      <span className="overflow">
                        {data.projects[0].titleSource}
                      </span>
                      {data.count > 1 ? (
                        <span>
                          {t(`requests.text-20`).replace(
                            '@',
                            (data.count - 1).toString(),
                          )}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="chips">
                      {data.projects.find(
                        (project) => project.tasks[0].type === 'TRANSLATION',
                      ) ? (
                        <div className="chip translation-chip">
                          {t(`requests.text-18`)}{' '}
                          {
                            data.projects.filter(
                              (project) =>
                                project.tasks[0].type === 'TRANSLATION',
                            ).length
                          }
                          {t(`requests.text-200`)}
                        </div>
                      ) : (
                        ''
                      )}
                      {data.projects.find(
                        (project) => project.tasks[0].type === 'TRANSCRIPTION',
                      ) ? (
                        <div className="chip transcription-chip">
                          {t(`requests.text-19`)}{' '}
                          {
                            data.projects.filter(
                              (project) =>
                                project.tasks[0].type === 'TRANSCRIPTION',
                            ).length
                          }
                          {t(`requests.text-200`)}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {/* <RequestDataDueAt>
                    <div>{t(`requests.text-31`)}</div>
                    <div>
                      {moment(data.projects[0].dueAt).format(
                        'YYYY-MM-DD, hh:mm a',
                      )}
                    </div>
                  </RequestDataDueAt> */}
                </RequestData>
                <div className="request-result">
                  <div className="result-total">
                    <div>{t(`requests.text-17`)}</div>
                    <div className="price">
                      ₩ {data.totalPrice.toLocaleString()}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        color: data.totalProgress ? '#1a61f7' : '#888888',
                        fontWeight: 700,
                        fontSize: 12,
                        lineHeight: '20px',
                      }}
                    >
                      {data.totalProgress}%
                    </div>
                    <DotContainer>
                      {Array(
                        Math.floor((data.totalProgress / 100) * LIMIT_PROGRESS),
                      )
                        .fill(null)
                        .map((a, b) => (
                          <Dot key={`dot-blue-${b}`} blue />
                        ))}
                      {Array(
                        LIMIT_PROGRESS -
                          Math.floor(
                            (data.totalProgress / 100) * LIMIT_PROGRESS,
                          ),
                      )
                        .fill(null)
                        .map((a, b) => (
                          <Dot key={`dot-${b}`} />
                        ))}
                    </DotContainer>
                  </div>
                </div>
                {openIndex === index ? (
                  <RequestDetails>
                    {data.projects.map((project, projectIndex) => (
                      <DetailContainer
                        key={`detail-${projectIndex}-${project.id}`}
                      >
                        <DetailItem>
                          <DetailItemTypes>
                            <div className="project-no">
                              No.
                              {project.tasks[0].id.toString().padStart(4, '0')}
                            </div>
                            {project.tasks[0].type === 'TRANSLATION' ? (
                              <div className="chip translation-chip">
                                {t(`requests.text-18`)}
                              </div>
                            ) : (
                              <div className="chip transcription-chip">
                                {t(`requests.text-19`)}
                              </div>
                            )}
                            <DetailItemTypeProgress>
                              {/* 대기, 진행중, 완료 */}
                              {/* project - mobile */}
                              {project.status === 300 ? (
                                <div className="in-progress">
                                  {t(`requests.text-8`)}
                                </div>
                              ) : project.status === 400 ? (
                                <div
                                  className="complete"
                                  onClick={() =>
                                    handleDownloadSubtitles(project.id)
                                  }
                                >
                                  <div>{t(`requests.text-9`)}</div>
                                  <img
                                    src={`${process.env.PUBLIC_URL}/assets/icon/icon-subtitle-download.svg`}
                                    alt="download"
                                  />
                                </div>
                              ) : (
                                <div className="ready">
                                  {t(`requests.text-7`)}
                                </div>
                              )}
                            </DetailItemTypeProgress>
                          </DetailItemTypes>
                          <MobileDetailDueDate>
                            <p
                              style={{
                                fontWeight: '700',
                                fontSize: '14px',
                                lineHeight: '20px',
                                textAlign: 'center',
                                color: '#444444',
                              }}
                            >
                              {project.status === 400
                                ? t(`requests.text-32`)
                                : t(`requests.text-31`)}
                            </p>
                            <p
                              style={{
                                fontWeight: '700',
                                fontSize: '14px',
                                lineHeight: '20px',
                                textAlign: 'center',
                                color:
                                  project.status === 400
                                    ? '#1A61F7'
                                    : '#444444',
                              }}
                            >
                              {moment(project.dueAt).format(
                                'YYYY-MM-DD, hh:mm a',
                              )}
                            </p>
                          </MobileDetailDueDate>
                          <DetailItemData language={currentLanguage}>
                            <div className="data-line">
                              <div>{t(`requests.text-21`)}</div>
                              <div className="overflow">
                                {project.titleSource}
                                {project.titleTarget !== null &&
                                  project.tasks[0].type === 'TRANSLATION' &&
                                  project.status === 400 && (
                                    <button
                                      onClick={() =>
                                        handleClickComplete(
                                          t('requests.text-21'),
                                          project.titleSource,
                                          project.titleTarget,
                                        )
                                      }
                                    >
                                      <div>
                                        <img
                                          src={`${process.env.PUBLIC_URL}/assets/icon/icon-open.svg`}
                                          alt="complete"
                                        />
                                        <p>{t(`requests.text-9`)}</p>
                                      </div>
                                    </button>
                                  )}
                              </div>
                            </div>

                            <div className="data-line">
                              <div>{t(`requests.text-22`)}</div>
                              <div>
                                {secondsToHhMmSsHelper(project.runtime)}
                              </div>
                            </div>
                            <div className="data-line">
                              <div>{t(`requests.text-23`)}</div>
                              <div>
                                {currentLanguage === 'en'
                                  ? LANGUAGE_LIST.find(
                                      (language) =>
                                        language.value ===
                                        project.sourceLanguageCode,
                                    ).value
                                  : LANGUAGE_LIST.find(
                                      (language) =>
                                        language.value ===
                                        project.sourceLanguageCode,
                                    ).label}{' '}
                                {'→'}{' '}
                                {currentLanguage === 'en'
                                  ? LANGUAGE_LIST.find(
                                      (language) =>
                                        language.value ===
                                        project.targetLanguageCode[0],
                                    ).value
                                  : LANGUAGE_LIST.find(
                                      (language) =>
                                        language.value ===
                                        project.targetLanguageCode[0],
                                    ).label}
                              </div>
                            </div>
                            <div className="data-line">
                              <div>{t(`requests.text-24`)}</div>
                              <div>
                                {genreList.find(
                                  (genre) => genre.value === project.genre,
                                )?.label || '-'}
                              </div>
                            </div>
                            <div className="data-line">
                              <div>{t(`requests.text-25`)}</div>
                              <div>
                                {moment(project.createdAt).format(
                                  'YYYY-MM-DD, hh:mm a',
                                )}
                              </div>
                            </div>
                            <div className="data-line">
                              <div>{t(`requests.text-26`)}</div>
                              <div>
                                {project.fileName || t(`requests.text-35`)}
                              </div>
                            </div>

                            <div className="data-line">
                              <div>{t(`requests.text-38`)}</div>
                              <div className="description">
                                {project.descriptionSource === ''
                                  ? t('requests.text-40')
                                  : !project.descriptionTranslationRequested
                                  ? t('requests.text-39')
                                  : project.descriptionSource}

                                {project.descriptionTarget !== null &&
                                  project.tasks[0].type === 'TRANSLATION' &&
                                  project.status === 400 && (
                                    <button
                                      onClick={() =>
                                        handleClickComplete(
                                          t('requests.text-38'),
                                          project.descriptionSource,
                                          project.descriptionTarget,
                                        )
                                      }
                                    >
                                      <div>
                                        <img
                                          src={`${process.env.PUBLIC_URL}/assets/icon/icon-open.svg`}
                                          alt="complete"
                                        />
                                        <p>{t(`requests.text-9`)}</p>
                                      </div>
                                    </button>
                                  )}
                              </div>
                            </div>

                            <div className="data-line">
                              <div>{t(`requests.text-27`)}</div>
                              <div>
                                ₩{' '}
                                {parseInt(
                                  project.amountClient,
                                ).toLocaleString()}
                              </div>
                            </div>
                          </DetailItemData>
                        </DetailItem>
                        <DetailProgressContainer>
                          {/* 대기, 진행중, 완료 */}
                          {project.status === 300 ? (
                            <div className="in-progress">
                              {t(`requests.text-8`)}
                            </div>
                          ) : project.status === 400 ? (
                            <div
                              className="complete"
                              onClick={() =>
                                handleDownloadSubtitles(project.id)
                              }
                            >
                              <div>{t(`requests.text-9`)}</div>
                              <img
                                className="download-icon"
                                src={`${process.env.PUBLIC_URL}/assets/icon/icon-subtitle-download.svg`}
                                alt="download"
                              />
                            </div>
                          ) : (
                            <div className="ready">{t(`requests.text-7`)}</div>
                          )}
                          <div>
                            <p
                              style={{
                                fontWeight: '700',
                                fontSize: '14px',
                                lineHeight: '20px',
                                textAlign: 'center',
                                marginTop: '10px',
                                color: '#444444',
                              }}
                            >
                              {project.status === 400
                                ? t(`requests.text-32`)
                                : t(`requests.text-31`)}
                            </p>
                            <p
                              style={{
                                fontWeight: '700',
                                fontSize: '14px',
                                lineHeight: '20px',
                                textAlign: 'center',
                                minWidth: '140px',
                                color:
                                  project.status === 400
                                    ? '#1A61F7'
                                    : '#444444',
                              }}
                            >
                              {moment(project.dueAt).format(
                                'YYYY-MM-DD, hh:mm A',
                              )}
                            </p>
                          </div>
                        </DetailProgressContainer>
                      </DetailContainer>
                    ))}
                  </RequestDetails>
                ) : (
                  ''
                )}
              </RequestContainer>
            </Card>
          ))
        ) : (
          <NoRequests>
            {t(`requests.text-14`)}
            <button onClick={() => navigate('/creator/request')}>
              {t(`requests.text-15`)}
            </button>
          </NoRequests>
        )}
      </Fragment>
    )
  }

  const renderTasks = () => {
    if (status !== 'SEARCH') return null

    return (
      <Fragment>
        {tasks?.length ? (
          tasks.map((data, index) => (
            <Card key={`request-${index}-${data.taskId}`} marginTop={27}>
              <RequestContainer>
                <RequestDetails>
                  <DetailContainer>
                    <DetailItem>
                      <DetailItemTypes>
                        <div className="project-no">
                          No.
                          {data.taskId.toString().padStart(4, '0')}
                        </div>
                        {data.type === 'TRANSLATION' ? (
                          <div className="chip translation-chip">
                            {t(`requests.text-5`)}
                          </div>
                        ) : (
                          <div className="chip transcription-chip">
                            {t(`requests.text-6`)}
                          </div>
                        )}
                        <DetailItemTypeProgress>
                          {/* 대기, 진행중, 완료 */}
                          {/* task - mobile */}
                          {data.projectStatus === 300 ? (
                            <div className="in-progress">
                              {t(`requests.text-8`)}
                            </div>
                          ) : data.projectStatus === 400 ? (
                            <div
                              className="complete"
                              onClick={() =>
                                handleDownloadSubtitles(data.projectId)
                              }
                            >
                              <div>{t(`requests.text-9`)}</div>
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/icon/icon-subtitle-download.svg`}
                                alt="download"
                              />
                            </div>
                          ) : (
                            <div className="ready">{t(`requests.text-7`)}</div>
                          )}
                        </DetailItemTypeProgress>
                      </DetailItemTypes>
                      <MobileDetailDueDate>
                        <p
                          style={{
                            fontWeight: '700',
                            fontSize: '14px',
                            lineHeight: '20px',
                            textAlign: 'center',
                            color: '#444444',
                          }}
                        >
                          {data.projectStatus === 400
                            ? t(`requests.text-32`)
                            : t(`requests.text-31`)}
                        </p>
                        <p
                          style={{
                            fontWeight: '700',
                            fontSize: '14px',
                            lineHeight: '20px',
                            textAlign: 'center',
                            color:
                              data.projectStatus === 400
                                ? '#1A61F7'
                                : '#444444',
                          }}
                        >
                          {moment(data.dueDate).format('YYYY-MM-DD, hh:mm a')}
                        </p>
                      </MobileDetailDueDate>
                      <DetailItemData language={currentLanguage}>
                        <div className="data-line">
                          <div>{t(`requests.text-21`)}</div>
                          <div className="overflow">{data.title}</div>
                        </div>
                        <div className="data-line">
                          <div>{t(`requests.text-22`)}</div>
                          <div>{secondsToHhMmSsHelper(data.runtime)}</div>
                        </div>
                        <div className="data-line">
                          <div>{t(`requests.text-23`)}</div>
                          <div>
                            {
                              LANGUAGE_LIST.find(
                                (language) =>
                                  language.value === data.sourceLanguageCode,
                              ).label
                            }{' '}
                            {'→'}{' '}
                            {
                              LANGUAGE_LIST.find(
                                (language) =>
                                  language.value === data.targetLanguageCode,
                              ).label
                            }
                          </div>
                        </div>
                        <div className="data-line">
                          <div>{t(`requests.text-24`)}</div>
                          <div>
                            {genreList.find(
                              (genre) => genre.value === data.category,
                            )?.label || '-'}
                          </div>
                        </div>
                        <div className="data-line">
                          <div>{t(`requests.text-25`)}</div>
                          <div>
                            {moment(data.requestDate).format(
                              'YYYY-MM-DD, hh:mm a',
                            )}
                          </div>
                        </div>
                        <div className="data-line">
                          <div>{t(`requests.text-26`)}</div>
                          <div>
                            {data.subtitleFile || t(`requests.text-35`)}
                          </div>
                        </div>

                        <div className="data-line">
                          <div>{t(`requests.text-27`)}</div>
                          <div>
                            ₩ {parseInt(data.amountPaid).toLocaleString()}
                          </div>
                        </div>
                      </DetailItemData>
                    </DetailItem>
                    <DetailProgressContainer>
                      {/* 대기, 진행중, 완료 */}
                      {data.projectStatus === 300 ? (
                        <div className="in-progress">
                          {t(`requests.text-8`)}
                        </div>
                      ) : data.projectStatus === 400 ? (
                        <div
                          className="complete"
                          onClick={() =>
                            handleDownloadSubtitles(data.projectId)
                          }
                        >
                          <div>{t(`requests.text-9`)}</div>
                          <img
                            className="download-icon"
                            src={`${process.env.PUBLIC_URL}/assets/icon/icon-subtitle-download.svg`}
                            alt="download"
                          />
                        </div>
                      ) : (
                        <div className="ready">{t(`requests.text-7`)}</div>
                      )}

                      <div>
                        <p
                          style={{
                            fontWeight: '700',
                            fontSize: '14px',
                            lineHeight: '20px',
                            textAlign: 'center',
                            color: '#444444',
                          }}
                        >
                          {data.projectStatus === 400
                            ? t(`requests.text-32`)
                            : t(`requests.text-31`)}
                        </p>
                        <p
                          style={{
                            fontWeight: '700',
                            fontSize: '14px',
                            lineHeight: '20px',
                            textAlign: 'center',
                            minWidth: '140px',
                            color:
                              data.projectStatus === 400
                                ? '#1A61F7'
                                : '#444444',
                          }}
                        >
                          {moment(data.dueDate).format('YYYY-MM-DD, hh:mm a')}
                        </p>
                      </div>
                    </DetailProgressContainer>
                  </DetailContainer>
                </RequestDetails>
              </RequestContainer>
            </Card>
          ))
        ) : (
          <NoRequests className="no-tasks">
            <div className="search-list">
              {searchList.map((item) => {
                return <div>{item}</div>
              })}
            </div>
            <span>{t(`requests.text-36`)}</span>
            <button onClick={handleClear}>{t(`requests.text-37`)}</button>
          </NoRequests>
        )}
      </Fragment>
    )
  }

  const renderRequests = () => {
    if (openFilter || mobileTab === MobileTabType.CREDIT) return null
    return (
      <CardContainer marginTop={99} style={{ position: 'relative' }}>
        <CardLabel>{t(`requests.text-13`)}</CardLabel>
        <FilterButton onClick={handleMobileFilter}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/icon/filter.svg`}
            alt="filter"
          />
        </FilterButton>

        {/* GET projects (INIT) */}
        {renderProjects()}

        {/* GET tasks (SEARCH) */}
        {renderTasks()}
      </CardContainer>
    )
  }

  return (
    <Container>
      <Wrap>
        <Form>
          <Header>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icon/icon-my-requests.svg`}
              alt="request icon"
            />
            <h2>{t(`requests.text-12`)}</h2>
          </Header>
          <Body>
            <Contents>
              <CardContainer>
                {renderFilter()}
                {renderRequests()}
              </CardContainer>
            </Contents>

            <MobileBlank />
          </Body>
        </Form>
      </Wrap>
    </Container>
  )
}

const Container = styled.div`
  // desktop
  @media screen and (min-width: 959px) {
    margin: 0 50px;
    padding-bottom: 177px;
  }

  // mobile
  @media screen and (max-width: 959px) {
  }
`

const Wrap = styled.div`
  position: relative;
  max-width: 1180px;
  margin: 0 auto;
`

const Form = styled.div`
  @media screen and (min-width: 744.02px) {
    padding-bottom: 64px;
  }

  @media (min-width: 375.02px) and (max-width: 744px) {
    padding: 0 20px 64px 20px;
  }

  @media screen and (max-width: 375px) {
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (min-width: 959px) {
    margin: 50px auto;

    img {
      width: 56px;
      heigh: 56px;
      margin-right: 13px;
    }

    h2 {
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      color: #111111;
    }
  }

  @media screen and (max-width: 959px) {
    margin: 30px auto 0px;
    padding: 0 20px;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    h2 {
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      color: #111111;
    }
  }
`

const Body = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;

  // desktop
  @media screen and (min-width: 959px) {
  }

  // mobile
  @media screen and (max-width: 959px) {
    flex-direction: column;
  }
`

const Contents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

interface CardProps {
  marginTop?: number
  flex?: boolean
}

const CardContainer = styled.div<CardProps>`
  width: 100% !important;
  margin: 0 auto;

  ${(p) =>
    p.marginTop
      ? css`
          margin-top: ${p.marginTop}px;
        `
      : css``}

  ${(p) =>
    p.flex
      ? css`
          display: flex;
        `
      : css``}

  // desktop
  @media screen and (min-width: 959px) {
    gap: 30px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    flex-direction: column-reverse;
    gap: 87px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  .filter-box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;

    &.filter-box-date {
      flex: 2.2;
    }

    input[type='date']::before {
      content: attr(data-placeholder);
      width: 100%;
    }

    input[type='date']:focus::before,
    input[type='date']:valid::before {
      display: none;
    }
  }

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #444444;
  }

  input {
    padding: 12px 15px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }

  // desktop
  @media screen and (min-width: 959px) {
    input {
      width: 100%;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    flex-direction: column;

    input {
      width: 100%;
    }

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 48px;
      border: none;
      border-radius: 8px;
      background-color: #1a61f7;
      font-weight: 700;
      font-size: 16px;
      line-height: 48px;
      color: #fff;
    }
  }
`

const RequestContainer = styled.div`
  user-select: none;

  .request-result {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .result-total {
      display: flex;
      align-items: flex-end;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      & > div:first-child {
        color: #111111;
      }
    }
    .result-box {
      display: flex;
      gap: 10px;
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 15px;
    }

    .price {
      color: #1a61f7;
      text-align: end;
    }
  }
`

const RequestNumber = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
  line-height: 20px;
  color: #888888;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  // desktop
  @media screen and (min-width: 959px) {
    font-size: 12px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    font-size: 14px;
  }
`

const RequestData = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  .title-container {
    display: flex;
    align-items: center;
    gap: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #444444;

    .overflow {
      max-width: 390px;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  div.chips {
    display: flex;
    gap: 10px;

    div.chip {
      display: flex;
      width: 100px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 26px;
      font-weight: 500;
      line-height: 20px;
      color: #ffffff;
      border-radius: 20px;
    }

    div.translation-chip {
      background: #3880f7;
    }

    div.transcription-chip {
      background: #2ccab0;
    }
  }

  & > div {
    display: flex;
  }

  @media screen and (min-width: 959px) {
    & > div:first-child {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 26px;
    }

    .chip {
      padding: 3px 10px;
      font-size: 12px;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    // flex-direction: column;
    gap: 7px;

    & > div:first-child {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 26px;
    }

    .chip {
      padding: 3px 10px;
      font-size: 14px;
    }
    .title-container {
      .overflow {
        max-width: 150px;
      }
    }
  }
`

const RequestDetails = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`
const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;

  .project-no {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #888888;
  }

  // desktop
  @media screen and (min-width: 959px) {
    // height: 230px;
  }

  // mobile
  @media screen and (max-width: 959px) {
  }
`

const DetailItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;

  // desktop
  @media screen and (min-width: 959px) {
  }

  // mobile
  @media screen and (max-width: 959px) {
    flex-direction: column;
    flex: 1;
  }
`

const DetailItemTypes = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  gap: 10px;

  .chip {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    height: 26px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    border-radius: 20px;
  }

  .translation-chip {
    background: #3880f7;
  }

  .transcription-chip {
    background: #2ccab0;
  }

  // desktop
  @media screen and (min-width: 959px) {
    flex-direction: column;
    max-width: 170px;
    width: 100%;
    padding: 0 15px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
`

const MobileDetailDueDate = styled.div`
  // desktop
  @media screen and (min-width: 959px) {
    display: none;
  }

  // mobile
  @media screen and (max-width: 959px) {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #ccc;
    flex: 1 0 auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;
  }
`

const DetailItemTypeProgress = styled.div`
  // desktop
  @media screen and (min-width: 959px) {
    display: none;
  }

  // mobile
  @media screen and (max-width: 959px) {
    flex: 1;

    & > div {
      float: right;
      width: 65px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
    }

    .ready {
      background: #eaeaea;
      color: #444444;
    }

    .in-progress {
      background: #ffffff;
      border: 1px solid #1a61f7;
      color: #1a61f7;
    }

    .complete {
      background: #1a61f7;
      color: #fff;
    }
  }
`

const DetailItemData = styled.div<LanguageProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;

  .data-payment {
    display: flex;
    gap: 17px;
    .payment-info {
      font-size: 14px;
      & > div {
        margin-bottom: 6px;
      }
      &.amount {
        text-align: end;
      }
    }
    .sub-info {
      text-align: end;
      font-family: 'Pretendard';
      font-weight: 500;
      line-height: 22px;
      color: rgba(17, 17, 17, 0.5);
      &.title {
        margin-right: 7px;
      }
    }
  }

  .data-line {
    display: flex;
    // align-items: center;
    gap: 15px;
    font-weight: 500;
    font-size: 14px;

    width: 100%;

    .overflow {
      display: flex;
      flex-direction: column;
      gap: 11px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .description {
      display: flex;
      flex-direction: column;
      gap: 11px;
      max-width: 80%;
    }

    & > div:first-child {
      width: ${(props) => (props.language === 'en' ? '100px;' : '60px;')};
      min-width: ${(props) => (props.language === 'en' ? '100px;' : '60px;')};

      line-height: 22px;
      color: #111111;
    }

    & > div:last-child {
      line-height: 20px;
      color: #444444;
    }

    button {
      width: ${(props) => (props.language === 'en' ? '110px;' : '69px;')};
      height: 30px;

      background: #1a61f7;
      border-radius: 5px;
      border: none;

      div {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;

        img {
          width: 16px;
          height: 16px;
        }

        p {
          height: 20px;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;

          color: #ffffff;
        }
      }
    }
  }

  // desktop
  @media screen and (min-width: 959px) {
    border-left: 1px solid #ccc;
    padding: 20px 0 20px 50px;

    .overflow {
      max-width: 280px;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    padding: 20px;

    .overflow {
      max-width: 219px;
    }
  }
`

const DetailProgressContainer = styled.div`
  // desktop
  @media screen and (min-width: 959px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;

    & > div:first-child {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
    }

    .ready {
      background: #ccc;
      color: #444;
    }

    .in-progress {
      background: #fff;
      border: 1px solid #1a61f7;
      color: #1a61f7;
    }

    .complete {
      background: #1a61f7;
      color: #fff;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        .download-icon {
          width: 21px;
          height: 22px;
        }
      }
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    display: none;
  }
`

const DotContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const Dot = styled.div<{ blue?: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(p) => (p.blue ? '#1a61f7' : '#eaeaea')};
`

const MobileBlank = styled.div`
  // desktop
  @media screen and (min-width: 959px) {
  }

  // mobile
  @media screen and (max-width: 959px) {
    height: 103px;
  }
`

const MobileFilterContainer = styled.div`
  padding: 20px;

  .filter-tabs {
    display: flex;
    gap: 11px;

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 92px;
      height: 36px;
      border-radius: 30px;
      background: #111;
      color: #fff;
      opacity: 0.3;

      &.active {
        opacity: 1;
      }
    }
  }

  .filter-button-wrapper {
    margin-top: 20px;
    width: 100%;
  }

  .filter-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    height: 45px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #888888;
  }
`

const NoRequests = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  line-height: 16px;
  color: #444444;

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #3462ee;
    border-radius: 30px;
    border: 0;
    text-transform: uppercase;
    color: #ffffff;
  }

  &.no-tasks {
    .search-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #444444;
    }

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #888888;
    }

    button {
      margin-top: 10px;
      border: 1.5px solid #1a61f7;
      background: transparent;
      color: #1a61f7;
    }
  }

  @media screen and (min-width: 744px) {
    height: 440px;
    font-size: 16px;

    &:not(.no-tasks) {
      gap: 50px;
    }

    &.no-tasks {
      gap: 10px;
    }

    button {
      width: 200px;
      height: 42px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
  }

  @media (min-width: 375.02px) and (max-width: 744px) {
    gap: 15px;
    min-height: 544px;
    font-size: 14px;

    button {
      width: 200px;
      height: 46px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
  }

  @media screen and (max-width: 375px) {
    gap: 15px;
    height: 200px;
    font-size: 14px;

    button {
      width: 200px;
      height: 46px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
  }
`

const ButtonContainer = styled.div`
  margin-top: 20px;
  height: 36px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

const ResetButton = styled.button`
  padding: 9px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #444444;

  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #444444;
  cursor: pointer;

  &:disabled {
    background: #eaeaea;
    border: none;
    color: #aaaaaa;
  }

  img {
    width: 14px;
    height: 14px;
  }
`

const SearchButton = styled.button`
  width: 187px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1a61f7;
  border-radius: 4px;
  border: none;
  padding: 12px;

  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  color: #ffffff;
  cursor: pointer;
`

const FilterButton = styled.button`
  @media (max-width: 959px) {
    position: absolute;
    top: -42px;
    right: 20px;
    width: 34px;
    height: 34px;
    border: none;
    background: transparent;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 959.02px) {
    display: none;
  }
`
