import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'src/app/axios-client'

type PostInfo = {
  buyerId: number
  buyerName: string
  buyerEmail: string
  orderId: string
  orderedAt: string
  productId: number
  productType: string
  productName: string
  productPrice: number
  creditPrice: number
  totalPrice: number
  currency: string
}

export const getInicisPaymentInfo = createAsyncThunk(
  'payment/info',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/api/payment/purchase/inicis/info`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const postPaymentInfo = createAsyncThunk(
  'payment/postInfo',
  async (info: PostInfo, thunkAPI) => {
    try {
      const response = await axios.post(`/api/payment/attempt`, info)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
