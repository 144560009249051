import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { chargeCredit } from 'src/features/credit/credit.service'
import { getCredit } from 'src/features/profile/profile.service'
import { getBalance, setCredit } from 'src/features/profile/profile.slice'
import {
  getAllAmount,
  getAllRuntime,
  getFinalAmount,
  getProjectGroupId,
  getRequests,
  getRequestsLength,
  getTranscriptionLength,
  getTranscriptionRequests,
  getTranslationLength,
  getTranslationRequests,
} from 'src/features/request/request.slice'
import styled from 'styled-components'
import { CommonProps } from '../request'
import { CardItem } from '../styled/card'
import { ModalContext } from 'src/providers/modal.provider'
import NoCreditDepositModal from '../modals/no-credit-deposit-modal'
import OutOfBalanceModal from '../modals/out-of-balance-modal'
import {
  getInicisPaymentInfo,
  postPaymentInfo,
} from 'src/features/payment/payment.service'
import { InicisInfo } from 'src/features/payment/payment.slice'

import { ReactInicis } from 'src/common/components/inicis'
import paymentInfoTransformer from 'src/shared/transformer/paymentinfo.transformer'
import RequestAlert from './request-alert'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from 'src/features/main/main.slice'
import RequestSummary from './request-summary'

interface Props extends CommonProps {}

export default function RequestPayment(props: Props) {
  const host = window.location.host.includes('creator.gloground.com')
    ? 'https://creator.gloground.com'
    : window.location.host.includes('creator.glo-hub.com')
    ? 'https://creator.glo-hub.com'
    : 'https://creator-dev.gloground.com'
  const dispatch = useAppDispatch()
  const allAmount = useAppSelector(getAllAmount)
  const requests = useAppSelector(getRequests)
  const allRuntime = useAppSelector(getAllRuntime)
  const finalAmount = useAppSelector(getFinalAmount)
  const requestsLength = useAppSelector(getRequestsLength)
  const translationLength = useAppSelector(getTranslationLength)
  const translationRequests = useAppSelector(getTranslationRequests)
  const transcriptionLength = useAppSelector(getTranscriptionLength)
  const transcriptionRequests = useAppSelector(getTranscriptionRequests)
  const projectGroupId = useAppSelector(getProjectGroupId)
  const userBalance = useAppSelector(getBalance)
  const { profile } = useAppSelector((state) => state.profile)
  const { setModal } = useContext(ModalContext)
  const [isInicisPay, setIsInicisPay] = useState(false)
  const [payAmount, setPayAmount] = useState(0)
  const [payData, setPayData] = useState({
    productName: '',
    buyerName: '',
    buyerTel: '',
    buyerEmail: '',
    productPrice: payAmount,
    mid: '',
    mKey: '',
    oid: '',
    payStatus: 0,
    payViewType: 'popup',
    returnUrl: `${host}/api/payment/purchase/inicis/auth`,
    mobileReturnUrl: `${host}/api/payment/purchase/inicis/auth/mobile`,
    closeUrl: `${host}/creator/request/payment/close`,
  })

  const [currPaymentMethod, setCurrPaymentMethod] = useState<
    'CREDIT' | 'INICIS'
  >('CREDIT')

  const [openPaymentInfo, setOpenPaymentInfo] = useState<boolean>(false)

  const { t } = useTranslation()
  const currentLanguage = useAppSelector(getCurrentLanguage)

  const isAssign = useMemo(() => {
    return (
      allAmount &&
      requestsLength &&
      !requests.some(
        (request) =>
          !(
            request.titleSource &&
            request.sourceLanguageCode &&
            request.taskType &&
            request.targetLanguageCode.length
          ),
      )
    )
  }, [requestsLength, requests])
  useEffect(() => {
    if (isCardPayment()) {
      setPayAmount(allAmount - userBalance)
      setCurrPaymentMethod('INICIS')
    } else {
      setPayAmount(allAmount)
      setCurrPaymentMethod('CREDIT')
    }
  }, [userBalance, allAmount])

  function isCardPayment() {
    return userBalance === 0 || userBalance < allAmount
  }

  function saveInicisPaymenInfo(isCreditUsed: boolean) {
    const info = {
      translationLength,
      translationRequests,
      transcriptionLength,
      transcriptionRequests,
      isCreditUsed,
      allAmount,
      payAmount,
      projectGroupId,
    }
    sessionStorage.setItem('payCompleteInfo', JSON.stringify(info))
  }

  const makeProjectName = () => {
    return currentLanguage === 'ko'
      ? translationRequests.length
        ? translationRequests.length > 1
          ? `번역요청 ${translationRequests[0].titleSource}외 ${translationRequests.length}건`
          : `번역요청 ${translationRequests[0].titleSource}`
        : '' + transcriptionRequests.length
        ? transcriptionRequests.length > 1
          ? `자막요청 ${transcriptionRequests[0].titleSource}외 ${transcriptionRequests.length}건`
          : `자막요청 ${transcriptionRequests[0].titleSource}`
        : ''
      : translationRequests.length
      ? translationRequests.length > 1
        ? `Translation Request ${translationRequests[0].titleSource}, ${translationRequests.length} other request(s)`
        : `Translation Request ${translationRequests[0].titleSource}`
      : '' + transcriptionRequests.length
      ? transcriptionRequests.length > 1
        ? `Transcription Request ${transcriptionRequests[0].titleSource}, ${transcriptionRequests.length} other request(s)`
        : `Transcription Request ${transcriptionRequests[0].titleSource}`
      : ''
  }

  const onOrderRequest = async (info: InicisInfo) => {
    const timestamp = new Date().getTime()
    const orderId = `${info.mid}_${timestamp}`
    const goodname = makeProjectName()

    const data = { ...payData }
    data.productName = goodname //buyerName
    data.buyerName = profile.nickname
    data.buyerTel = !profile?.countryPhoneNumber
      ? '000'
      : profile?.countryPhoneNumber
    data.buyerEmail = profile.email
    data.productPrice = payAmount
    // data.productPrice = 1000
    data.mid = info.mid
    data.oid = orderId
    data.mKey = info.signkey

    const payInfo = paymentInfoTransformer(profile, {
      orderId: orderId,
      creditPrice: allAmount - payAmount,
      totalPrice: allAmount,
      productPrice: payAmount,
      productId: projectGroupId,
      productName: goodname,
    })

    dispatch(postPaymentInfo(payInfo))
      .unwrap()
      .then(() => {
        setPayData({ ...data })
        setIsInicisPay(true)
      })
  }

  const handlePayment = async () => {
    // const checked = paymentMethods.filter((item) => item.checked)[0]

    switch (currPaymentMethod) {
      case 'CREDIT':
        dispatch(getCredit())
          .unwrap()
          .then(() => {
            const payInfo = paymentInfoTransformer(profile, {
              orderId: `GlozPay_${new Date().getTime()}`,
              creditPrice: payAmount,
              totalPrice: allAmount,
              productPrice: 0,
              productId: projectGroupId,
              productName: makeProjectName(),
            })
            dispatch(postPaymentInfo(payInfo))
              .unwrap()
              .then(() => {
                // 결제 후 이동
                dispatch(
                  chargeCredit({
                    projectGroupId,
                    amountToPayByCredit: payAmount,
                    pgType: null,
                    amountToPayByPg: 0,
                  }),
                )
                  .unwrap()
                  .then((response) => {
                    dispatch(setCredit({ charge: payAmount }))
                    props.nextStep()
                  })
                  .catch((error) => {
                    console.error(error)
                    // error.response.status === 500
                    // error.response.message === Not enough credit
                    setModal(<OutOfBalanceModal />)
                  })
              })
          })
          .catch((error) => {
            console.error(error)
            if (error?.response?.status === 500) {
              setModal(<NoCreditDepositModal />)
            } else {
              setModal(<OutOfBalanceModal />)
            }
          })
        break
      case 'INICIS':
        dispatch(getInicisPaymentInfo())
          .unwrap()
          .then((info: InicisInfo) => {
            onOrderRequest(info)
            saveInicisPaymenInfo(userBalance === 0 ? false : true)
          })
          .catch((e) => {
            setModal(
              <RequestAlert message={`<p>${t(`payment.text-26`)}</p>`} />,
            )
          })
        break
    }
  }

  function localeString(amount: number) {
    return amount.toLocaleString('ko-KR')
  }

  return (
    <Container>
      <div className="test-test"></div>
      <Header>
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/payment-creditcard.svg`}
          alt="request icon"
        />
        <h2>{t(`payment.text-1`)}</h2>
      </Header>
      <Body>
        <Contents>
          <PaymentInfoContainer>
            <div className="header">
              <p>{t('payment.text-29')}</p>
              <div className="accordion-payment-info">
                {t(`payment.text-4`).replace('@', requestsLength.toString())}
                <div onClick={() => setOpenPaymentInfo(!openPaymentInfo)}>
                  <img
                    src={
                      openPaymentInfo
                        ? `${process.env.PUBLIC_URL}/assets/icon/icon-close-detail.svg`
                        : `${process.env.PUBLIC_URL}/assets/icon/icon-open-detail.svg`
                    }
                    alt="open detail"
                  />
                </div>
              </div>
            </div>
            {openPaymentInfo && (
              <PaymentInfoListContainer>
                {requests.map((value) => {
                  return (
                    <>
                      <PaymentInfoList>
                        <div className="thumbnail">
                          <img src={value.thumbUrl || `${process.env.PUBLIC_URL}/assets/img/no-thumbnail.png`} alt="Thumbnail" />
                        </div>
                        <div className="title">
                          <div className="chip-title">
                            <div
                              className={`chip ${value.taskType.toLowerCase()}-chip`}
                            >
                              {t(
                                `${
                                  value.taskType === 'TRANSCRIPTION'
                                    ? 'my.credit.text-12'
                                    : 'my.credit.text-11'
                                }`,
                              )}
                            </div>
                            <p>{value.titleSource}</p>
                          </div>

                          {value.descriptionTranslationRequested && (
                            <div className="description-request">
                              <div></div>
                              <p>{t('payment.text-31')}</p>
                            </div>
                          )}
                        </div>

                        <div className="runtime">
                          <p>
                            {Math.ceil(value.runtime / 60)}
                            {t('payment.text-10')}
                          </p>
                        </div>
                        <div className="amount-individual">
                          <p>₩ {value.finalAmount.toLocaleString()}</p>
                          {value.descriptionTranslationRequested && (
                            <p>
                              + ₩
                              {value.descriptionPricePerLength.toLocaleString()}
                            </p>
                          )}
                        </div>
                      </PaymentInfoList>
                      <PaymentInfoListMob>
                        <div className="title-mob">
                          <div className="chip-title-mob">
                            <div
                              className={`chip-mob ${value.taskType.toLowerCase()}-chip-mob`}
                            >
                              {t(
                                `${
                                  value.taskType === 'TRANSCRIPTION'
                                    ? 'my.credit.text-12'
                                    : 'my.credit.text-11'
                                }`,
                              )}
                            </div>
                            <p>{value.titleSource}</p>
                          </div>
                        </div>
                        <div className="second-content">
                          <div className="thumbnail-mob">
                            <img src={value.thumbUrl} alt="dd" />
                          </div>
                          <div className="second-wrapper">
                            <div className="runtime-mob">
                              <p>
                                {Math.ceil(value.runtime / 60)}
                                {t('payment.text-10')}
                              </p>
                            </div>
                            <div className="amount-mob">
                              <p>₩ {value.amount.toLocaleString()}</p>
                            </div>
                            <div className="description-mob">
                              {value.descriptionTranslationRequested && (
                                <div className="description-request-mob">
                                  <p>({t('payment.text-31')})</p>
                                </div>
                              )}
                            </div>
                            <div className="description-charge-mob">
                              {value.descriptionTranslationRequested && (
                                <p>
                                  + ₩&nbsp;
                                  {value.descriptionPricePerLength.toLocaleString()}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </PaymentInfoListMob>
                    </>
                  )
                })}
              </PaymentInfoListContainer>
            )}
          </PaymentInfoContainer>

          <RequestSummary
            isAssign={isAssign}
            onRequestPayment={handlePayment}
            isActivated={!!requests.length}
            isHasRequest={false}
            isMethod={true}
          />

          {finalAmount > 0 ? (
            <PaymentMethodContainer>
              <div className="header-method">
                <p>{t('payment.text-30')}</p>
              </div>
              <CardItem isMethod={true}>
                <div>{t(`payment.text-28`)}</div>
                <div className="payment-method">
                  <span className="amount">
                    ₩ {finalAmount.toLocaleString()}
                  </span>
                </div>
              </CardItem>
            </PaymentMethodContainer>
          ) : null}
        </Contents>
        <ReactInicis
          payData={payData}
          isPurchase={isInicisPay}
          changePayStatus={setIsInicisPay}
        />
        <MobileBlank />

        <div className="summary-payment">
          <RequestSummary
            isAssign={isAssign}
            onRequestPayment={handlePayment}
            isActivated={!!requests.length}
            isHasRequest={false}
            isMethod={false}
          />
        </div>

        {/* <DarkMatter /> */}
      </Body>
    </Container>
  )
}

const Container = styled.div`
  position: relative;

  @media (min-width: 375.02px) and (max-width: 744px) {
    padding: 0 20px;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (min-width: 959px) {
    margin: 50px auto;

    img {
      width: 56px;
      heigh: 56px;
      margin-right: 13px;
    }

    h2 {
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      color: #111111;
    }
  }

  @media screen and (max-width: 959px) {
    margin: 30px auto 40px;
    padding: 0 20px;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    h2 {
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      color: #111111;
    }
  }
`

const Body = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;

  // desktop
  @media screen and (min-width: 959px) {
  }

  // mobile
  @media screen and (max-width: 959px) {
    flex-direction: column;

    .summary-payment {
      display: none;
    }
  }
`

const Contents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  // desktop
  @media screen and (min-width: 959px) {
    gap: 30px;
  }

  // mobile
  @media screen and (max-width: 959px) {
  }
`

const PaymentInfoContainer = styled.div`
  width: 100%;
  padding: 0px;
  gap: 10px;

  background: #ffffff;
  border-radius: 8px;

  @media screen and (max-width: 959px) {
    margin-bottom: 26px;
    border-radius: 0;
  }

  .header {
    padding: 20px 30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #cccccc;

    .accordion-payment-info {
      display: flex;
      align-items: center;

      gap: 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;

      color: #111111;

      img {
        cursor: pointer;
      }
    }

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;

      color: #111111;
    }
  }
`

const PaymentMethodContainer = styled.div`
  width: 100%;
  margin-top: 54px;

  .header-method {
    padding: 22px 8px 18px 8px;

    display: flex;
    width: 100%;
    justify-content: space-between;

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;

      color: #111111;
    }
  }
  @media screen and (max-width: 959px) {
    display: none;
  }
`

const PaymentInfoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 959px) {
    gap: 0;
  }
`
const PaymentInfoList = styled.div`
  display: flex;
  gap: 18px;
  width: 100%;
  padding: 20px 30px;
  font-size: 14px;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  @media screen and (max-width: 959px) {
    display: none;
  }

  &:last-child {
    border: none;
  }

  .thumbnail {
    width: 160px;
    height: 92px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .title {
    display: flex;
    flex: 12;
    flex-direction: column;
    gap: 13px;

    justify-content: center;
    align-items: start;

    .description-request {
      display: flex;
      gap: 10px;
      align-items: center;

      padding-left: 3px;

      div {
        box-sizing: border-box;

        width: 8px;
        height: 8px;

        border-width: 0px 0px 1px 1px;
        border-style: solid;
        border-color: #cccccc;
      }
    }

    .chip-title {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    p {
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .chip {
      min-width: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px 10px;
      height: 26px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #ffffff;
      border-radius: 20px;
    }

    .translation-chip {
      background: #3880f7;
    }

    .transcription-chip {
      background: #2ccab0;
    }
  }

  .runtime {
    display: flex;

    flex: 4;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      // text-align: right;

      color: #444444;
    }
  }

  .amount-individual {
    display: flex;
    flex: 3;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 9px;
  }
`

const PaymentInfoListMob = styled.div`
  display: none;
  @media screen and (max-width: 959px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px 20px;
    margin-bottom: 26px;

    border-bottom: 1px solid #cccccc;

    .thumbnail-mob {
      width: 154px;
      height: 88px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .second-content {
      display: flex;
      gap: 9px;

      .second-wrapper {
        display: flex;
        flex-direction: column;
      }
    }

    .title-mob {
      display: flex;
      flex-direction: column;
      gap: 13px;

      justify-content: center;
      align-items: start;

      .description-request-mob {
        display: flex;
        gap: 10px;
        align-items: center;

        padding-left: 3px;

        div {
          box-sizing: border-box;

          width: 8px;
          height: 8px;

          border-width: 0px 0px 1px 1px;
          border-style: solid;
          border-color: #cccccc;
        }
      }

      .chip-title-mob {
        display: flex;
        gap: 5px;
        align-items: center;
      }

      p {
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .chip-mob {
        min-width: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 10px;
        height: 26px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        border-radius: 20px;
      }

      .translation-chip-mob {
        background: #3880f7;
      }

      .transcription-chip-mob {
        background: #2ccab0;
      }
    }

    .runtime-mob {
      p {
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        text-align: left;

        color: #888888;
      }
    }

    .amount-mob {
      p {
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        text-align: left;

        color: #444444;
      }
    }

    .description-mob {
      margin-top: 8px;
      p {
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        text-align: left;

        color: #888888;
      }
    }

    .description-charge-mob {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;

      color: #444444;

      transform: matrix(1, 0, 0, 1, 0, 0);
    }
  }
`

const MobileBlank = styled.div`
  // desktop
  @media screen and (min-width: 959px) {
  }

  // mobile
  @media screen and (max-width: 959px) {
    height: 103px;
  }
`
