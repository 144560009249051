import styled, { css } from 'styled-components'

interface CardProps {
  marginTop?: number
  disabled?: boolean
}

interface CardItemProps {
  isMore?: boolean
  isMethod?: boolean
}

export const Card = styled.div<CardProps>`
  position: relative;
  border: none;
  background-color: #fff;
  opacity: ${({ disabled }) => (disabled ? `0.5` : 1)};

  ${(p) =>
    p.marginTop
      ? css`
          margin-top: ${p.marginTop}px;
        `
      : css``}

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  // desktop
  @media screen and (min-width: 959px) {
    padding: 30px;
    border-radius: 8px;

    .card-item-input-container {
      width: calc(100% - 56px - 19px);
    }

    .card-item-input-container-multi {
      width: calc(100% - 56px - 19px);
    }

    &.credit-usage {
      width: 70%;
    }

    &.credit-status {
      width: 30%;
      height: 100%;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    padding: 20px;

    .card-item-input-container {
      width: 100%;
    }

    .card-item-input-container-multi {
    }

    &.credit-usage {
      width: 100%;
    }

    &.credit-status {
      width: 100%;
    }
  }
`

/**
 * @param top - ex) 33px
 */
export const CardLabel = styled.label<{ top?: string }>`
  position: absolute;
  top: ${(p) => p?.top || '-33px'};
  left: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #111111;

  & > span {
    display: block;
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #888;
  }

  // desktop
  @media screen and (min-width: 959px) {
  }

  // mobile
  @media screen and (max-width: 959px) {
    margin-left: 20px;
  }
`

export const CardTitle = styled.p`
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  // mobile
  @media screen and (max-width: 959px) {
    margin-left: 20px;
  }
`

export const ToggleBtn = styled.div`
  width: 402px;
  height: 42px;
  padding: 4px 0;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6faff;
  border-radius: 100px;

  .btn {
    font-size: 15px;
    width: 145px;
    height: 34px;
    display: flex;
    gap: 6px;
    justify-content: center;

    border-radius: 100px;
    font-weight: bold;
    color: #87a8df;
    &.active {
      color: #fff;
      background: #3880f7;
      img {
        filter: brightness(2);
      }
    }
  }
  // mobile
  @media screen and (max-width: 959px) {
    width: 150px;
    .btn {
      width: 50px;
    }
  }
`

export const CardForLink = styled.div`
  .bold {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .small {
    font-size: 16px;
    color: #888888;
  }
`

export const CardForChannel = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .bold {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .br {
    display: none;
  }
  .small {
    font-size: 16px;
    color: #888888;
  }

  // mobile
  @media screen and (max-width: 959px) {
    .br {
      display: inline;
    }
  }
`

export const CardInputs = styled.div`
  display: flex;

  input {
    height: 40px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 12px 10px;
    outline: none;

    font-size: 14px;
    line-height: 16px;
  }

  button {
    padding: 14px;
    background: #111111;
    border-radius: 7.15596px;
    min-width: 125px;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;

    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: #ffffff;
    img {
      width: 72px;
      height: 16px;
    }
  }

  &.youtube-inputs {
    flex-direction: column;
    align-items: center;
    gap: 15px;

    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #000000;

    button {
      @media screen and (min-width: 959px) {
        width: 200px;
        height: 40px;
        padding: 12px;
      }

      @media screen and (max-width: 959px) {
        width: 335px;
        height: 46px;
        padding: 15px;
      }
    }
  }

  .card-input-error {
    font-size: 12px;
    line-height: 16px;
    color: rgb(26, 97, 247);
  }

  // desktop
  @media screen and (min-width: 959px) {
    margin-top: 30px;
    gap: 10px;

    button {
      height: 40px;
    }

    .card-input-error {
      position: absolute;
      bottom: -20px;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    margin-top: 20px;
    flex-direction: column;

    button {
      height: 44px;
      width: 100%;
    }

    .card-input-error {
      margin: 10px 0;
    }
  }
`
type RadioProp = {
  isActive: boolean
}
export const CardRadioItemGroup = styled.div`
  width: 100%;
  max-height: 225px;
  background: #ffffff;
  overflow: auto;
`

export const CardRadioItem = styled.div<RadioProp>`
  width: 100%;
  /* height: 54px; */
  padding: 15px 33px;
  background: #f8f8f8;
  user-select: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ isActive }) =>
    isActive
      ? css`
          border-radius: 8px;
          border: 1px solid #3880f7;
        `
      : ''}

  & > div {
    display: flex;
    align-items: center;
  }

  & > * {
    cursor: pointer;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }
  .index {
    margin-right: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  label {
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 0 0 18px;
  }

  .status-chip {
    padding: 5px 8px;
    min-width: 48px;

    border-radius: 20px;
    text-align: center;
    font-size: 12px;
    &.done {
      background: rgba(3, 207, 94, 0.1);
      color: #2eae4e;
    }
    &.error {
      background: rgba(210, 21, 21, 0.1);
      color: #d21515;
    }
  }

  // desktop
  @media screen and (min-width: 959px) {
    border-radius: 8px;
    label {
      max-width: 280px;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    label {
      max-width: 220px;
    }
  }
`

export const CardItemGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #ffffff;

  input,
  textarea {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 12px 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #444444;
  }

  textarea {
    height: 108px;
    max-height: 108px;
  }
`

export const DescriptionCard = styled.div`
  background: #f8f8f8;
  display: flex;
  gap: 17px;
  flex-direction: column;
  .checkbox-area {
    width: 100%;

    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
    padding-top: 31px;
    padding-right: 30px;

    input {
      width: 24px;
      height: 24px;
    }

    p {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      color: #000000;
    }
  }
  .description-box-padding {
    padding-top: 0px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    .checkbox-area {
      display: none;
    }
    .description-box-padding {
      padding-top: 20px;
    }
  }
`

export const CardItem = styled.div<CardItemProps>`
  background: #f8f8f8;

  display: flex;
  gap: 19px;

  ${(props) =>
    props.isMethod && `justify-content: space-between; background: #ffffff;`}

  & > div:first-child {
    min-width: 130px;
    width: 130px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #111111;
  }

  .runtime {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #111111;
  }

  .payment-method {
    .amount {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;

      color: #1a61f7;
    }
  }
  .checkbox-area-in {
    width: 100%;

    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;

    input {
      width: 24px;
      height: 24px;
    }

    p {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      color: #000000;
    }
  }

  div.item-textarea {
    width: 100%;
    .description {
      ${(props) =>
        props.isMore
          ? ``
          : `word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;`}
    }
    .open-more {
      width: max-content;
      margin-top: 24px;
      text-decoration: underline;
      cursor: pointer;
    }
    textarea {
      overflow: auto;
    }
  }

  .card-item-input-container {
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #1a61f7;
    }
  }

  .description-title {
    display: flex;
    justify-content: space-between;
  }

  .description-label {
    font-weight: 400;
    font-size: 12px;
    p {
      position: absolute;
      bottom: unset !important;
      right: 60px;
      margin-top: -20px;
    }
  }
  // desktop
  @media screen and (min-width: 959px) {
    padding: 30px;
    border-radius: 8px;

    .card-item-input-container {
      p {
        position: absolute;
        bottom: -21px;
      }
    }

    div.item-textarea__count {
      bottom: 10px;
    }

    .checkbox-area-in {
      display: flex;
    }

    .checkbox-area-mob {
      display: none;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    padding: ${(props) => (props.isMethod ? '18px 30px 18px 30px;' : '20px;')};

    & > div:first-child {
      width: unset;
    }
    &:not(.card-item-runtime-container) {
      flex-direction: ${(props) => (props.isMethod ? 'row;' : 'column;')};
    }

    gap: 10px;
    align-items: unset !important;

    .card-item-input-container {
      p {
        margin-top: 5px;
      }
    }
    &.card-item-input-container-toggle {
      flex-direction: row;
      justify-content: space-between;
      .card-item-input-container {
        width: unset;
      }
    }

    div.item-textarea__count {
      bottom: -17px;
    }

    .checkbox-area-in {
      display: none;
    }

    .checkbox-area-mob {
      display: flex;

      gap: 10px;
      justify-content: flex-end;
      align-items: center;

      input {
        width: 24px;
        height: 24px;
      }

      p {
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;

        color: #000000;
      }
    }
    .description-label {
      width: 80%;
      max-width: 100%;
      word-break: keep-all;
      display: flex;
      align-items: flex-start;
      p {
        position: absolute;
        bottom: unset !important;
        right: 45px;
      }
    }
  }
`

export const CardItemMulti = styled.div`
  background: #f8f8f8;
  .multi-languages {
    display: flex;

    & > div:first-child {
      width: 130px;
    }
  }
  .card-item-input-container {
    p {
      margin-top: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #d21515;
    }
  }
  .card-item-input-container-multi {
    display: flex;

    & > div:not(:nth-child(2)) {
      flex: 1;

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #d21515;
      }
    }
  }

  // desktop
  @media screen and (min-width: 959px) {
    padding: 30px;
    border-radius: 8px;

    hr {
      margin-top: 47px;
    }

    .multi-languages {
      align-items: center;
      gap: 19px;

      .card-item-input-container-multi {
        align-items: center;
        gap: 20px;

        & > div:not(:nth-child(2)) {
          position: relative;

          p {
            position: absolute;
            bottom: -21px;
          }
        }
      }
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    padding: 20px;

    hr {
      height: 25px;
    }

    .multi-languages {
      flex-direction: column;
      gap: 10px;

      .card-item-input-container-multi {
        flex-direction: column;
        gap: 10px;
        .arrow {
          align-self: center;
          transform: rotate(90deg);
        }
        & > div:not(:nth-child(2)) {
          p {
            margin-top: 5px;
          }
        }
      }
    }
  }
`
export const CardFileUpload = styled.div`
  background: #f8f8f8;
  display: flex;
  gap: 19px;
  .title {
    width: 130px;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .multi-files {
    display: flex;
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #111111;
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #fff;
      border: 1px solid #1a61f7;
      color: #1a61f7;
    }

    .uploaded {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .chip {
        display: flex;
        align-items: center;
        gap: 10px;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 8px 11px;
        background-color: #ccc;
        color: #444;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        cursor: pointer;
        user-select: none;
      }
    }
  }

  // desktop
  @media screen and (min-width: 959px) {
    padding: 30px;
    border-radius: 8px;
    .multi-files {
      gap: 30px;
      justify-content: end;
      align-items: center;

      button {
        border-radius: 4px;
        width: 125px;
        height: 40px;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    padding: 20px;
    flex-direction: column;
    .multi-files {
      flex-direction: column;
      gap: 10px;

      button {
        width: 100%;
        height: 44px;
        border-radius: 8px;
      }
    }
  }
`

export const CardUl = styled.ul`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #888888;
  margin: 0;
  padding: 0;
  margin-left: 15px;
  user-select: none;
`

export const CardAssign = styled.div`
  margin-top: 30px;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #f8f8f8;
  user-select: none;

  cursor: pointer;
  & > * {
    cursor: pointer;
  }

  label {
    margin: 0;
  }
`

// Table

interface TextProps {
  textAlign?: 'left' | 'right'
}

export const CardTable = styled.table`
  width: 100%;
`

export const CardTableHeader = styled.thead`
  height: 40px;
  background: #f8f8f8;

  // mobile
  @media screen and (max-width: 959px) {
    display: table;
    width: 100%;
  }
`

export const CardTableHead = styled.th<TextProps>`
  text-align: ${(p) => p.textAlign || 'left'};
  padding: 10px 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #111111;
`

export const CardTableBody = styled.tbody`
  & > tr {
    height: 40px;
    border-bottom: 1px solid #cccccc;
  }

  @media screen and (max-width: 959px) {
    width: 100%;
    max-height: 185px;
    overflow: auto;
    display: block;

    & > tr {
      display: table;
      width: 100%;
    }
  }
`

export const CardTableRow = styled.tr``

export const CardTableColumn = styled.td<TextProps>`
  text-align: ${(p) => p.textAlign || 'left'};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  padding: 10px 15px;

  span.overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  &.last-column {
    width: 1%;
    white-space: nowrap;
  }

  // desktop
  @media screen and (min-width: 959px) {
    span.overflow {
      max-width: 280px;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    span.overflow {
      max-width: 163px;
    }
  }
`

const FlexBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 10px 15px;
`

export const CardBorder = styled(FlexBetweenDiv)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #111111;

  &:not(:last-child) {
    border-top: 1px solid #ccc;
  }
`

export const CardBorderWCredit = styled.div`
  margin-right: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 10px 10px 15px 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #111111;

  .credit {
    margin-left: 20px;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  & > div {
    font-family: 'Pretendard';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: rgba(0, 0, 0, 0.5);
  }

  &:not(:last-child) {
    border-top: 1px solid #ccc;
  }
`

export const CardResult = styled(FlexBetweenDiv)`
  background: #ebf4ff;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  & > div:first-child {
    color: #111111;
  }

  & > div:last-child {
    color: #1a61f7;
  }
`

type CursorType = {
  cursor?: 'default' | 'pointer'
}
export const CardPaymentMethodBox = styled.div<CursorType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0px 15px 30px;
  gap: 15px;
  height: 54px;
  background: #ebf4ff;

  cursor: ${({ cursor }) => cursor ?? 'pointer'};
  & > * {
    cursor: ${({ cursor }) => cursor ?? 'pointer'};
  }

  label {
    margin: 3px 0 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #111111;
  }
  .credit-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
    .amount {
      color: #1a61f7;
    }
  }
`

export const CardCurrentCredit = styled.div`
  display: flex;
  align-items: center;
  line-height: 21px;

  & > span:nth-child(2) {
    margin-left: 5px;
    font-weight: 500;
    color: #111111;
  }

  & > span:last-child {
    margin-left: 15px;
    font-weight: 700;
    color: #1a61f7;
  }

  // desktop
  @media screen and (min-width: 959px) {
    margin-top: 5px;
    justify-content: end;
    font-size: 12px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    margin-top: 15px;
    font-size: 14px;
  }
`

export const CountingStar = () => {
  return (
    <span
      style={{
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '22px',
        color: '#1a61f7',
      }}
    >
      *
    </span>
  )
}
