import TablePagination from '@mui/material/TablePagination'
import styled from 'styled-components'
import { VideoInfoType } from 'src/features/video/video.slice'
import moment from 'moment'
import { RequestSelected } from './request-youtube'
import React, { useEffect, useState } from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { CustomTooltipStyle } from 'src/common/components/styled/button.style'
import { useTranslation } from 'react-i18next'

type Props = {
  channelTitle: string
  sourceData: VideoInfoType
  pageInfo: {
    totalResults: number
    resultsPerPage: number
    nextPageToken?: string
    prevPageToken?: string
  }
  handleAddYoutube: any
  requests: any
  handleDeleteRequest: any
  page: number
  handlePageChange: any
}
export default function RequestMychannel({
  channelTitle,
  pageInfo,
  sourceData,
  handleAddYoutube,
  requests,
  handleDeleteRequest,
  page,
  handlePageChange,
}: Props) {
  const { t } = useTranslation()

  const [showTooltip, setShowTooltip] = useState(false)

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: CustomTooltipStyle,
  }))

  const onCheckVideo = (e, video) => {
    if (e.target.checked) {
      handleAddYoutube(video.id, video.url, RequestSelected.MyChannel)
    } else {
      handleDeleteRequest(video.id)
    }
  }

  const onClickVideo = (e, video) => {
    if (e.target?.checked !== undefined) return
    const checked = requests.some((item) => item.id === video.id)
    if (!checked) {
      handleAddYoutube(video.id, video.url, RequestSelected.MyChannel)
    } else {
      handleDeleteRequest(video.id)
    }
  }

  const onAllCheckVideos = (e) => {
    if (e.target.checked) {
      sourceData.forEach((item) => {
        handleAddYoutube(item.id, item.url, RequestSelected.MyChannel)
      })
    } else {
      sourceData.forEach((item) => {
        handleDeleteRequest(item.id)
      })
    }
  }
  useEffect(() => {
    handleCheckAllChecked()
  }, [requests])

  const handleCheckAllChecked = () => {
    if (!requests?.length) {
      return true
    } else {
      const requestIds = requests
        .filter((request) => request.type === RequestSelected.MyChannel)
        .map((request) => request.id)
      return sourceData.some((item) => !requestIds.includes(item.id))
    }
  }

  const makeChannelTitle = (title) => {
    if (title.length > 34) {
      return title.substring(0, 34) + '...'
    }
    return title
  }

  return (
    <Container>
      <h4>
        {makeChannelTitle(channelTitle)}
        {t(`request.text-27`)}
      </h4>
      <MiddleBox>
        <div className="checkbox">
          <input
            type="checkbox"
            id="check-all"
            onChange={onAllCheckVideos}
            checked={!handleCheckAllChecked()}
          />
          <p>
            {t(`request.text-28`)}{' '}
            <strong>{`(${requests?.length + '/' + 10})`}</strong>
          </p>
        </div>
        <div className="search desktop" style={{ textAlign: 'right' }}>
          {t(`request.text-29`)}
          <br />
          {t(`request.text-40`)}
        </div>
        <div
          className="search mobile"
          onClick={() => setShowTooltip(!showTooltip)}
        >
          {t(`request.text-29`)}
          <HtmlTooltip
            title={<React.Fragment>{t(`request.text-40`)}</React.Fragment>}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/icon/icon-info-black.svg`}
              alt="info"
              style={{ cursor: 'pointer' }}
            />
          </HtmlTooltip>
        </div>
      </MiddleBox>

      <hr />
      <ListBox isEmpty={!sourceData?.length}>
        {!sourceData?.length ? (
          <p className="no-list">{t(`request.text-30`)}</p>
        ) : (
          sourceData.map((video) => {
            return (
              <div
                className={`list ${
                  requests.some((item) => item.id === video.id) ? 'checked' : ''
                }`}
                key={video.id}
                onClick={(e) => onClickVideo(e, video)}
              >
                <div className="content">
                  <input
                    type="checkbox"
                    checked={requests.some((item) => item.id === video.id)}
                    onChange={(e) => onCheckVideo(e, video)}
                  />
                  <img src={video.thumbnail} alt="thumbnail" />

                  <div className="desc">
                    <div>
                      <p className="title">{video.title}</p>
                      <p className="date">
                        {moment(video.publishedAt).format('YYYY/MM/DD')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        )}
      </ListBox>

      {!sourceData?.length ? null : (
        <TablePagination
          component="div"
          count={pageInfo.totalResults}
          page={page}
          onPageChange={(e, page) => {
            handlePageChange(e, page)
          }}
          rowsPerPage={5}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => ``}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  text-align: left;
  h4 {
    margin-bottom: 14px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  input[type='checkbox'] {
    width: 18px;
    height: 18px;
  }
`

const MiddleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  color: #888;
  .checkbox {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    strong {
      font-weight: 700;
      color: #111;
    }
  }
  .search {
    display: flex;
    align-items: flex-end;
    gap: 30px;
    font-size: 12px;
    &.mobile {
      display: none;
    }
    // mobile
    @media screen and (max-width: 959px) {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
`

type ListBoxProp = {
  isEmpty: boolean
}
const ListBox = styled.div<ListBoxProp>`
  overflow-y: auto;
  height: ${({ isEmpty }) => (isEmpty ? '50px' : '330px')};
  .no-list {
    margin-top: 29px;
    text-align: center;
    color: #888;
  }
  .list {
    margin-top: 10px;
    padding: 15px 30px;
    background-color: #f8f8f8;
    .content {
      display: flex;
      align-items: flex-start;
      gap: 15px;
      img {
        width: 210px;
        height: 111px;
        border-radius: 8px;
        object-fit: cover;
      }
      .title {
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        /* margin: 0 0 0 18px; */
      }
      .date {
        color: #888;
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 959px) {
    height: ${({ isEmpty }) => (isEmpty ? '50px' : '600px')};
    .list {
      border-radius: 8px;
      padding: 15px 20px;
      &.checked {
        border: 1px solid #1a61f7;
      }
      .content {
        flex-direction: column;
        img {
          width: 100%;
          height: 159px;
          object-fit: cover;
        }
      }
      input[type='checkbox'] {
        display: none;
      }
    }
  }
  @media screen and (max-width: 360px) {
    .list {
      .content {
        .title {
          max-width: 200px;
        }
      }
    }
  }
`
