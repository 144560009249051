import styled from 'styled-components'
import Select, { createFilter } from 'react-select'
import { useAppSelector } from 'src/app/hooks'
import { getCurrentLanguage } from 'src/features/main/main.slice'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  selectedGenre: Nullable<string>
  onChange: (option) => void
  disabled?: boolean
  placeholder: string
}

/** TODO 추후 아래 genreList로 변경 예정. pro와 싱크 맞춰야 하므로 대기중 */
// const genreList = [
//   { label: '게임 ', value: 'Gaming' },
//   { label: '과학기술 ', value: 'Science & Technology' },
//   { label: '교육 ', value: 'Education' },
//   { label: '노하우/스타일 ', value: 'Howto & Style' },
//   { label: '뉴스/정치 ', value: 'News & Politics' },
//   { label: '비영리/사회운동 ', value: 'Nonprofits & Activism' },
//   { label: '스포츠 ', value: 'Sports' },
//   { label: '애완동물/동물 ', value: 'Pets & Animals' },
//   { label: '엔터테인먼트 ', value: 'Entertainment' },
//   { label: '여행/이벤트 ', value: 'Travel & Events' },
//   { label: '영화/애니메이션 ', value: 'Film & Animation' },
//   { label: '음악 ', value: 'Music' },
//   { label: '인물/블로그 ', value: 'People & Blogs' },
//   { label: '자동차/교통 ', value: 'Autos & Vehicles' },
//   { label: '코미디 ', value: 'Comedy' },
//   { label: '기타 ', value: 'Others' },
// ]

// const genreListEn = [
//   { label: 'Gaming ', value: 'Gaming' },
//   { label: 'Science & Technology ', value: 'Science & Technology' },
//   { label: 'Education ', value: 'Education' },
//   { label: 'Howto & Style ', value: 'Howto & Style' },
//   { label: 'News & Politics ', value: 'News & Politics' },
//   { label: 'Nonprofits & Activism ', value: 'Nonprofits & Activism' },
//   { label: 'Sports ', value: 'Sports' },
//   { label: 'Pets & Animals ', value: 'Pets & Animals' },
//   { label: 'Entertainment ', value: 'Entertainment' },
//   { label: 'Travel & Events ', value: 'Travel & Events' },
//   { label: 'Film & Animation ', value: 'Film & Animation' },
//   { label: 'Music', value: 'Music' },
//   { label: 'People & Blogs', value: 'People & Blogs' },
//   { label: 'Autos & Vehicles', value: 'Autos & Vehicles' },
//   { label: 'Comedy ', value: 'Comedy' },
//   { label: 'Others ', value: 'Others' },
// ]

export default function GenreSelect(props: Props) {
  const { t } = useTranslation()
  const genreList = [
    { label: t('common.genre.Gaming'), value: 'Gaming' },
    { label: t('common.genre.Science&Technology'), value: 'Science & Technology' },
    { label: t('common.genre.Education'), value: 'Education' },
    { label: t('common.genre.Howto&Style'), value: 'Howto & Style' },
    { label: t('common.genre.News&Politics'), value: 'News & Politics' },
    { label: t('common.genre.Nonprofits&Activism'), value: 'Nonprofits & Activism' },
    { label: t('common.genre.Sports'), value: 'Sports' },
    { label: t('common.genre.Pets&Animals'), value: 'Pets & Animals' },
    { label: t('common.genre.Entertainment'), value: 'Entertainment' },
    { label: t('common.genre.Travel&Events'), value: 'Travel & Events' },
    { label: t('common.genre.Film&Animation'), value: 'Film & Animation' },
    { label: t('common.genre.Music'), value: 'Music' },
    { label: t('common.genre.People&Blogs'), value: 'People & Blogs' },
    { label: t('common.genre.Autos&Vehicles'), value: 'Autos & Vehicles' },
    { label: t('common.genre.Comedy'), value: 'Comedy' },
    { label: t('common.genre.Others'), value: 'Others' },
  ]
  // const currentLanguage = useAppSelector(getCurrentLanguage)
  // const [options, setOptions] = useState(genreList)
  // useEffect(() => {
  //   setOptions(currentLanguage === 'ko' ? genreList : genreListEn)
  // }, [currentLanguage])
  return (
    <Select
      options={genreList}
      value={
        genreList.find((genre) => genre.value === props.selectedGenre) || null
      }
      isDisabled={props?.disabled || false}
      onChange={props.onChange}
      placeholder={props.placeholder}
      filterOption={createFilter({
        matchFrom: 'start',
        ignoreCase: true,
      })}
      styles={{
        control: (base) => {
          return {
            ...base,
            borderColor: `${props.selectedGenre ? '#1a61f7' : '#aaaaaa'}`,
          }
        },
      }}
    />
  )
}

const Container = styled.div``
