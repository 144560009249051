import React, { useState, useContext, useEffect } from 'react'
import { useAppDispatch } from 'src/app/hooks'
import { setCredit } from 'src/features/profile/profile.slice'

import RequestBanner from 'src/pages/request/components/request-banner'
import InicisRequestComplete from './completed-info'
import OutOfBalanceModal from 'src/pages/request/modals/out-of-balance-modal'
import NoCreditDepositModal from 'src/pages/request/modals/no-credit-deposit-modal'

import { authenticationService } from 'src/service/auth/auth.service'

import { chargeCredit } from 'src/features/credit/credit.service'
import { getCredit } from 'src/features/profile/profile.service'
import { ModalContext } from 'src/providers/modal.provider'
import styled from 'styled-components'
import { RequestError } from 'src/pages/request/components'
import Loading from '../loading/loading'

enum RequestStep {
  Pending = 0,
  Complete = 1,
  Credit = 2,
  Error = 3,
}

function CompletedInicisPayment() {
  const dispatch = useAppDispatch()
  const [isLoggedIn, setIsLoggedIn] = useState(authenticationService.isLogin)
  const [step, setStep] = useState(0)
  const { setModal } = useContext(ModalContext)
  const paymentInfo = JSON.parse(sessionStorage.getItem('payCompleteInfo')) || {
    translationLength: 0,
    translationRequests: null,
    transcriptionLength: 0,
    transcriptionRequests: null,
    isCreditUsed: true,
    allAmount: 0,
    payAmount: 0,
    projectGroupId: 0,
  }
  const { isCreditUsed, allAmount, payAmount, projectGroupId } = paymentInfo

  useEffect(() => {
    //크레딧결제 진행
    dispatch(getCredit())
      .unwrap()
      .then(() => {
        // 결제 후 이동
        dispatch(
          chargeCredit({
            projectGroupId,
            amountToPayByCredit: isCreditUsed ? allAmount - payAmount : 0,
            pgType: 'INICIS',
            amountToPayByPg: payAmount,
          }),
        )
          .unwrap()
          .then((response) => {
            dispatch(setCredit({ charge: allAmount - payAmount }))
            // 미디어컨버트 호출

            setStep(RequestStep.Complete)
          })
          .catch((error) => {
            setStep(RequestStep.Error)
            setModal(<OutOfBalanceModal />)
          })
      })
      .catch((error) => {
        console.error(error)
        setStep(RequestStep.Error)
        if (error?.response?.status === 500) {
          setModal(<NoCreditDepositModal />)
        } else {
          setModal(<OutOfBalanceModal />)
        }
      })
  }, [isCreditUsed])

  const renderBanner = () => {
    if (isLoggedIn) return null
    return <RequestBanner />
  }

  const renderStepContents = () => {
    switch (step) {
      case RequestStep.Pending:
        return <Loading />
      case RequestStep.Complete:
        return <InicisRequestComplete paymentInfo={paymentInfo} />
      default:
        return <RequestError />
    }
  }

  return (
    <Container>
      <Wrap>
        {renderBanner()}
        <Form>{renderStepContents()}</Form>
      </Wrap>
    </Container>
  )
}

export default CompletedInicisPayment

const Container = styled.div``

const Wrap = styled.div`
  position: relative;
`

// form -> div
const Form = styled.div`
  // desktop
  @media screen and (min-width: 959px) {
    padding: 0 24px 64px;
  }

  // mobile
  @media screen and (max-width: 959px) {
  }
`
