import React from 'react'
import { useTranslation } from 'react-i18next'
import { BASEURL } from 'src/App'
import styled from 'styled-components'

interface Props {
  categoryType: string
  source: string
  target: string
  onClose: any
}

interface ContainerProps {
  categoryType: string
}

export default function TranslationCompleteModal(props: Props) {
  const { t } = useTranslation()

  const doCopy = (text) => {
    // 흐음 1.
    if (navigator.clipboard) {
      // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert(t('common.clipboard.copyToClipboardSuccess'))
        })
        .catch(() => {
          alert(t('common.clipboard.copyToClipboardFailed'))
        })
    } else {
      // 흐름 2.
      if (!document.queryCommandSupported('copy')) {
        return alert(t('common.clipboard.notSupportedToCopy'))
      }

      // 흐름 3.
      const textarea = document.createElement('textarea')
      textarea.value = text
      textarea.style.top = '0'
      textarea.style.left = '0'
      textarea.style.position = 'fixed'

      // 흐름 4.
      document.body.appendChild(textarea)
      // focus() -> 사파리 브라우저 서포팅
      textarea.focus()
      // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
      textarea.select()
      // 흐름 5.
      document.execCommand('copy')
      // 흐름 6.
      document.body.removeChild(textarea)
      alert(t('common.clipboard.copyToClipboardSuccess'))
    }
  }

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation()
      }}
      categoryType={props.categoryType}
    >
      <Header>
        <p>
          {props.categoryType === t('requests.text-21')
            ? t('requests.text-41')
            : props.categoryType === t('requests.text-38') &&
              t('requests.text-42')}
        </p>

        <p>{t('requests.text-43')}</p>
      </Header>
      <CloseContainer>
        <img
          src={`${process.env.PUBLIC_URL}/assets/icon/icon-modal-close.svg`}
          alt="modal-close"
          onClick={props.onClose}
        />
      </CloseContainer>
      <Body>
        <Contents>
          <SourceContainer categoryType={props.categoryType}>
            <div className="title">
              <p>{t('requests.text-44')}</p>
            </div>

            <div className="contents">{props.source}</div>
          </SourceContainer>
          <IconContainer>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icon/icon-to.svg`}
              alt="arrow"
            />
          </IconContainer>
          <TargetContainer categoryType={props.categoryType}>
            <div className="title">
              <p>{t('requests.text-45')}</p>
            </div>
            <div className="contents">{props.target}</div>
            <div className="button-container">
              <button onClick={() => doCopy(props.target)}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icon/icon-copy.svg`}
                  alt="copy"
                />
                <p>{t('requests.text-46')}</p>
              </button>
            </div>
          </TargetContainer>
        </Contents>
      </Body>
      {/* <Footer>
          <PositiveButton>GloZ 플랫폼 바로가기</PositiveButton>
        </Footer> */}
    </Container>
  )
}

const BlockOverlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
`

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  gap: 30px;
  width: 859px;

  background: #f8faff;
  border-radius: 8px;
  padding: 50px 50px 50px 50px;
  position: relative;

  // mobile
  @media screen and (max-width: 750px) {
    padding: 40px 20px;
    width: 338px;
    height: 738px;
  }
`

const CloseContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  @media screen and (max-width: 750px) {
    top: 20px;
    right: 20px;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  font-family: 'Pretendard';
  font-style: normal;

  color: #000000;

  & > p:first-child {
    font-size: 24px;
    line-height: 20px;
    font-weight: 700;
  }

  & > p:last-child {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #888888;
  }

  @media screen and (max-width: 750px) {
    & > p:first-child {
      font-size: 20px;
      line-height: 20px;
      font-weight: 700;
    }

    & > p:last-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      color: #888888;
    }
  }
`

const Body = styled.div`
  font-weight: 500;
  line-height: 34px;
  height: 100%;
  color: #111111;
  text-align: center;

  @media screen and (max-width: 750px) {
    overflow: scroll;
  }
`

const Contents = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  height: 100%;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    gap: 20px;
  }
`

const SourceContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;

  min-height: ${(props) =>
    props.categoryType === 'Title' || props.categoryType === '제목'
      ? '238px;'
      : '525px;'};

  max-height: ${(props) =>
    props.categoryType === 'Title' || props.categoryType === '제목'
      ? '238px;'
      : '525px;'};

  width: 298px;
  height: ${(props) =>
    props.categoryType === 'Title' || props.categoryType === '제목'
      ? '238px;'
      : '100%;'};

  background: #f8f8f8;
  border: 1px solid #cccccc;
  border-radius: 5px;

  .title {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #888888;
  }

  .contents {
    overflow: scroll;
    width: 100%;
    text-align: left;
    overflow: scroll;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    word-wrap: break-word;

    color: #444444;
  }

  @media screen and (max-width: 750px) {
    .contents {
      line-height: 22px;
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 33px;
    height: 33px;
  }
  @media screen and (max-width: 750px) {
    transform: rotate(90deg);
  }
`

const TargetContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px 50px 20px;
  gap: 10px;
  position: relative;
  min-height: ${(props) =>
    props.categoryType === 'Title' || props.categoryType === '제목'
      ? '238px;'
      : '525px;'};
  max-height: ${(props) =>
    props.categoryType === 'Title' || props.categoryType === '제목'
      ? '238px;'
      : '525px;'};
  width: 298px;
  height: ${(props) =>
    props.categoryType === 'Title' || props.categoryType === '제목'
      ? '238px;'
      : '100%;'};

  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;

  .title {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #888888;
  }

  .contents {
    width: 100%;
    text-align: left;
    overflow: scroll;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    word-wrap: break-word;

    color: #444444;

    max-height: ${(props) =>
      props.categoryType === 'Title' || props.categoryType === '제목'
        ? '104px;'
        : '400px;'};
  }

  .button-container {
    position: absolute;

    bottom: 20px;
    right: 20px;
    button {
      display: flex;
      align-items: center;

      padding: 5px 10px;
      gap: 5px;

      width: 98px;
      height: 34px;

      background: #1a61f7;
      border-radius: 5px;
      border: none;

      img {
        width: 24px;
        height: 24px;
      }
      p {
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        color: #ffffff;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .contents {
      line-height: 22px;
      padding-bottom: 20px;
    }

    .button-container {
      button {
        p {
          font-size: 14px;
        }
      }
    }
  }
`

const Footer = styled.div``

const PositiveButton = styled.button`
  width: 220.48px;
  height: 50.14px;
  background: #3462ee;
  border-radius: 34.3423px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  border: none;
`
