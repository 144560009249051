import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RoundButton from 'src/common/components/button/round-button'
import {
  ModalButtonGroup,
  ModalWrapper,
} from 'src/common/modals/modal-container'
import styled from 'styled-components'

interface Record {
  taskId: number
  csv?: {
    fileName: string
    fileUrl: string
  }
  srt?: {
    fileName: string
    fileUrl: string
  }
}

interface File {
  name: string
  url: string
}

interface Props {
  onClose: any
  onDownload: any
  files: Record[]
}

export default function DownloadSubtitleModal(props: Props) {
  const [selected, setSelected] = useState<File>(null)
  const [files, setFiles] = useState<File[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    initFiles()
  }, [props.files])

  const convertFile = (raw: Record['csv' | 'srt']) => {
    return {
      name: raw.fileName,
      url: raw.fileUrl,
    }
  }

  const initFiles = () => {
    if (!props.files || props.files.length === 0) return
    const result = []
    const file = props.files[0]
    if (file?.csv) result.push(convertFile(file.csv))
    if (file?.srt) result.push(convertFile(file.srt))
    setFiles(result)
  }

  const handleChangeFile = (file: File) => {
    setSelected(file)
  }

  const handleDownload = () => {
    props.onDownload(selected)
    props.onClose()
  }

  return (
    <ModalWrapper>
      <Title>{t(`requests.subTitleDownload`)}</Title>
      <Body>
        {files.length === 0 ? (
          <span style={{ fontSize: 14 }}>{t(`requests.noSubtitle`)}</span>
        ) : (
          ''
        )}
        {files.map((file) => (
          <div onClick={() => handleChangeFile(file)}>
            <input
              type="radio"
              name="file"
              checked={selected?.name === file.name}
              onChange={() => {}}
            />
            <label
              style={{
                marginLeft: '1em',
              }}
            >
              {file.name}
            </label>
          </div>
        ))}
      </Body>
      <ModalButtonGroup>
        <RoundButton variant="outlined" onClick={props.onClose}>
          {t(`request.modal.load-session.negative`)}
        </RoundButton>
        <RoundButton
          disabled={selected ? false : true}
          onClick={handleDownload}
        >
          {t(`request.modal.load-session.positive`)}
        </RoundButton>
      </ModalButtonGroup>
    </ModalWrapper>
  )
}

const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
`

const Body = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #111111;
  text-align: center;
`
