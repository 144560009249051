import React, { lazy, Suspense, useEffect } from 'react'
import './App.scss'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Requests from './pages/request/requests'
import Request from './pages/request/request'
import ProtectedRoute from './routes/protected-route'

const Layout = lazy(() => import('./common/layout'))
// const ProtectedRoute = lazy(() => import('./routes/protected-route'))
// const Request = lazy(() => import('./pages/request/request'))
const ForgotPassword = lazy(
  () => import('./pages/auth/forgot-password/forgot-password'),
)
const SentPasswordEmail = lazy(
  () => import('./pages/auth/forgot-password/sent-password-email'),
)
const ResetPassword = lazy(
  () => import('./pages/auth/reset-password/reset-password'),
)
const FinishResetPassword = lazy(
  () => import('./pages/auth/reset-password/finish-reset-password'),
)

const SelectMethod = lazy(
  () => import('./pages/auth/before-sign-up/select-method2'),
)

const SignUp = lazy(() => import('./pages/auth/signup/signup2'))
const FinishSignUp = lazy(() => import('./pages/auth/signup/finish-signup'))
const RequestGGrantComponent = lazy(
  () => import('./pages/auth/signup/request-g-grant'),
)

const RequestLGrantComponent = lazy(
  () => import('./pages/auth/signup/request-l-grant'),
)
// const Requests = lazy(() => import('./pages/request/requests'))
const PageTracking = lazy(() => import('./features/ga'))
const Mypage = lazy(() => import('./pages/user/mypage'))
const ChangePassword = lazy(() => import('./pages/user/change-password'))
const Leave = lazy(() => import('./pages/user/leave'))
const LeaveFinish = lazy(() => import('./pages/user/leave-finish'))
const Mycredit = lazy(() => import('./pages/user/mycredit'))
const MainPage = lazy(() => import('./pages/main'))
const SignIn = lazy(() => import('./pages/auth/login/login'))
const Close = lazy(() => import('./close'))
const FailedInicisPayment = lazy(
  () => import('./common/components/inicis/failed'),
)
const SignUpAgreement = lazy(
  () => import('./pages/auth/signup/components/signup-last-mirror'),
)
const CompletedInicisPayment = lazy(
  () => import('./common/components/inicis/completed'),
)
const CompletedInicisPaymentExternal = lazy(
  () => import('./common/components/inicis/completed-external'),
)
const FailedInicisPaymentExternal = lazy(
  () => import('./common/components/inicis/failed-external'),
)

export const BASEURL =
  process.env.REACT_APP_BASE_URL || `https://${window.location.hostname}`

function App() {
  // useEffect(() => {
  //   sessionStorage.setItem('beforeLogin', window.location.pathname)
  // }, [window.location.pathname])
  // if(sessionStorage.getItem('beforeLogin')) sessionStorage.removeItem('beforeLogin')
  return (
    <BrowserRouter>
      <Suspense>
        <PageTracking />

        <Routes>
          <Route path="/creator/main" element={<MainPage />} />
          <Route path="/creator/request/*" element={<Layout />}>
            <Route path="" element={<Request />} />
            <Route
              path="user"
              element={<ProtectedRoute element={<Requests />} />}
            />
            <Route
              path={`payment/inicis/success`}
              element={<CompletedInicisPayment />}
            />
            <Route
              path={`payment/inicis/failed`}
              element={<FailedInicisPayment />}
            />

            <Route
              path={'payment/inicis/external/success'}
              element={<CompletedInicisPaymentExternal />}
            />
            <Route
              path={'payment/inicis/exteranl/failed'}
              element={<FailedInicisPaymentExternal />}
            />

            <Route path="payment/close" element={<Close />} />
            <Route
              path="*"
              element={<Navigate to="/creator/request" replace />}
            />
          </Route>

          {/* auth */}
          <Route path="/creator/auth/agreement" element={<SignUpAgreement />} />
          <Route path="/creator/user/login" element={<SignIn />} />
          <Route
            path="/creator/user/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="/creator/user/finish/sent-email"
            element={<SentPasswordEmail />}
          />
          <Route
            path="/creator/user/reset-password"
            element={<ResetPassword />}
          />
          <Route
            path="/creator/user/finish/reset-password"
            element={<FinishResetPassword />}
          />

          <Route
            path="/creator/user/select-method"
            element={<SelectMethod />}
          />
          <Route path="/creator/user/signup" element={<SignUp />} />
          <Route
            path="/creator/user/finish/signup"
            element={<FinishSignUp />}
          />
          <Route
            path={'/creator/user/request-g-grant'}
            element={<RequestGGrantComponent />}
          />
          <Route
            path={`/creator/user/request-l-grant`}
            element={<RequestLGrantComponent />}
          />

          {/* account */}
          <Route path="/creator/user/*" element={<Layout />}>
            <Route
              path="account"
              element={<ProtectedRoute element={<Mypage />} />}
            />
            <Route
              path="credit"
              element={<ProtectedRoute element={<Mycredit />} />}
            />
            <Route
              path="password"
              element={<ProtectedRoute element={<ChangePassword />} />}
            />
            <Route
              path="leave"
              element={<ProtectedRoute element={<Leave />} />}
            />
            {/* <Route path="leave/finish" element={<LeaveFinish />} /> */}
            <Route
              path="*"
              element={<Navigate to="/creator/user/account" replace />}
            />
          </Route>
          <Route
            path="*"
            element={<Navigate to="/creator/main" replace />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
