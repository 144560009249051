export default function calWorkingTime(date: Date) {
  const hours = date.getUTCHours() + 9; // KST 시간계산을 쉽게 하기 위해 +9 처리
  let returnHours = 0

  if (hours >= 9 && hours < 15) {
    // 업무시간 내, 그대로 리턴
    returnHours = hours

  } else if (hours >= 15 && hours < 24) {
    // 당일 업무 마감이후 요청(15시 ~ 23시), 익일 9시에 시작
    returnHours = 24 + 9

  } else if (hours < 9 || (hours >= 24 && hours < 34)) {
    // 당일 아침에 시작, UTC에 +9를 했기 때문에 1시이면 25로 리턴됨 (15시 ~ 33시(익일 오전 8시)))
    returnHours = hours < 9 ? 9 : 33 // 당일 9시로 만듬, 대신 들어오는 값에 따라 9시 표기가 달라짐

  }

  return returnHours - 9 // 계산을 쉽게하기 위해 +9 한것을 원복
}

export function calculateWeekendHoursInRange(startDate: Date, endDate: Date) {
  const oneHour = 60 * 60 * 1000; // milliseconds in an hour

  const startUTCDate = new Date(startDate.setUTCHours(startDate.getUTCHours() + 9))
  const endUTCDate = new Date(endDate.setUTCHours(endDate.getUTCHours() + 9))

  let totalWeekendHours = 0;

  let currentDate = startUTCDate
  while (currentDate <= endUTCDate) {
    const dayOfWeek = currentDate.getUTCDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      totalWeekendHours += 1; // Add 24 hours for each weekend day
    }
    currentDate = new Date(currentDate.getTime() + oneHour);
  }

  // 사용했던 Date 객체 시간 원복 안해주면 함수 호출한 쪽 Date 객체 시간에 영향을 줌
  startDate.setUTCHours(startDate.getUTCHours() - 9)
  endDate.setUTCHours(endDate.getUTCHours() - 9)
  return totalWeekendHours;
}

  // KST 기준 요일 계산을 정확히 하기 위해 입력받은 값에 +9를 하여 UTC 조건으로 바꾸고 getUTCDay()로 요일 계산을 함
  // const checkWeekend = new Date(date.setHours(date.getHours()+9))
  // if (checkWeekend.getUTCDay() === 6 || checkWeekend.getUTCDay() === 0) {
  //   const daysUntilMonday = checkWeekend.getUTCDay() === 6 ? 2 : 1; // 2 for Saturday, 1 for Sunday
  //   timeUntilMonday = (daysUntilMonday * 24 - (checkWeekend.getHours()-9));
  // }