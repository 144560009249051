import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'src/app/hooks'
import { initRequest } from 'src/features/request/request.slice'
import { Card } from 'src/pages/request/styled/card'
import styled from 'styled-components'

interface Props {
  paymentInfo: {
    translationLength: number
    translationRequests: any[]
    transcriptionLength: number
    transcriptionRequests: any[]
    isCreditUsed: boolean
    allAmount: number
    payAmount: number
    projectGroupId: number
  }
}

export default function InicisRequestComplete({ paymentInfo }: Props) {
  const {
    translationLength,
    translationRequests,
    transcriptionLength,
    transcriptionRequests,
    isCreditUsed,
    allAmount,
    payAmount,
    projectGroupId,
  } = paymentInfo
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const projectGroupNo = projectGroupId?.toString().padStart(4, '0') || '0000'
  const { t } = useTranslation()

  const handleNavigate = () => {
    navigate('/creator/request/user')
  }

  useEffect(() => {
    sessionStorage.removeItem('requests')
    return () => {
      dispatch(initRequest())
      sessionStorage.removeItem('payCompleteInfo')
    }
  }, [])

  return (
    <Container>
      <Header>
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/payment-creditcard.svg`}
          alt="request icon"
        />
        <h2>{t(`payment.text-1`)}</h2>
      </Header>
      <Body>
        <Contents>
          <CardContainer>
            <Card>
              <ImageContainer>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icon/icon-payment-complete.svg`}
                  alt="complete icon"
                />
              </ImageContainer>
              <Strong>{t(`payment.text-15`)}</Strong>
              <Description>
                {t(`payment.text-16`)}
                {t(`payment.text-17`)}
                <br />
                {t(`payment.text-18`)}
                <br />
                {t(`payment.text-19`)}
              </Description>

              {/* <DateContainer>
                <span>{t(`payment.text-27`)}</span>
                <span>{moment(dueAt).format('YYYY-MM-DD, hh:mm a')}</span>
              </DateContainer> */}

              <ResultContainer>
                <ResultNumber>
                  {t(`payment.text-20`)} GloHub{projectGroupNo}
                </ResultNumber>
                {translationLength > 0 ? (
                  <ResultRequest>
                    <div>
                      <div className="chip translation-chip">
                        {t(`payment.text-21`)}
                      </div>
                      <div className="overflow">
                        {translationRequests[0].titleSource}
                      </div>
                    </div>
                    {translationLength > 1 ? (
                      <div>
                        {t(`payment.text-23`).replace(
                          '@',
                          (translationLength - 1).toString(),
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </ResultRequest>
                ) : (
                  ''
                )}

                {transcriptionLength > 0 ? (
                  <ResultRequest>
                    <div>
                      <div className="chip transcription-chip">
                        {t(`payment.text-22`)}
                      </div>
                      <div className="overflow">
                        {transcriptionRequests[0].titleSource}
                      </div>
                    </div>
                    {transcriptionLength > 1 ? (
                      <div>
                        {t(`payment.text-23`).replace(
                          '@',
                          (transcriptionLength - 1).toString(),
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </ResultRequest>
                ) : (
                  ''
                )}
                <ResultTotal>
                  <div>{t(`payment.text-24`)}</div>
                  <div>₩ {allAmount.toLocaleString()}</div>
                </ResultTotal>
              </ResultContainer>
            </Card>
          </CardContainer>

          <PaymentButton onClick={handleNavigate}>
            {t(`payment.text-25`)}
          </PaymentButton>
        </Contents>

        <DarkMatter />
      </Body>
    </Container>
  )
}

const Container = styled.div``

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  // desktop
  @media screen and (min-width: 959px) {
    margin: 50px auto;

    img,
    h2 {
      display: none;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    margin: 30px auto 40px;
    padding: 0 20px;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    h2 {
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      color: #111111;
    }
  }
`

const Body = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;

  // desktop
  @media screen and (min-width: 959px) {
  }

  // mobile
  @media screen and (max-width: 959px) {
    flex-direction: column;
  }
`

const ImageContainer = styled.div`
  text-align: center;
`

const Strong = styled.p`
  margin-top: 20px;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #1a61f7;
`

const Description = styled.div`
  font-weight: 400;
  text-align: center;
  color: #444444;

  // desktop
  @media screen and (min-width: 959px) {
    margin-top: 20px;
    font-size: 12px;
    line-height: 20px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    margin: 20px auto;
    width: 82%;
    font-size: 14px;
    line-height: 22px;
  }
`

const DateContainer = styled.div`
  width: 100%;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 15px;
  background: #f5f5f5;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  & > span:first-child {
    color: #111111;
  }

  & > span:last-child {
    color: #1a61f7;
  }

  // desktop
  @media screen and (min-width: 959px) {
    margin-top: 30px;
  }

  // mobile
  @media screen and (max-width: 959px) {
  }
`

const ResultContainer = styled.div`
  margin-top: 30px;
`

const ResultNumber = styled.div`
  font-weight: 400;
  line-height: 20px;
  color: #444444;

  // desktop
  @media screen and (min-width: 959px) {
    font-size: 12px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    font-size: 14px;
  }
`

const ResultRequest = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  height: 46px;
  border-top: 1px solid #cccccc;

  & > div {
    display: flex;
    gap: 10px;
    align-items: center;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #444444;
  }

  div.chip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 26px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    border-radius: 20px;
  }

  div.translation-chip {
    background: #3880f7;
  }

  div.transcription-chip {
    background: #2ccab0;
  }

  .overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  // desktop
  @media screen and (min-width: 959px) {
    .overflow {
      max-width: 280px;
    }
  }

  // mobile
  @media screen and (max-width: 959px) {
    .overflow {
      max-width: 164px;
    }
  }
`

const ResultTotal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  height: 40px;
  background: #f8f8f8;
  font-weight: 500;
  line-height: 20px;
  color: #111111;

  // desktop
  @media screen and (min-width: 959px) {
    font-size: 14px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    font-size: 16px;
  }
`

const Contents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const CardContainer = styled.div`
  width: 100% !important;
  margin: 0 auto;
`

const DarkMatter = styled.div`
  position: sticky;
  top: 50px;
  right: 0;
  width: 322px;
  height: fit-content;
`

const PaymentButton = styled.button`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 48px;
  background: #111111;
  border: none;
  border-radius: 50px;
  margin: 0 auto;

  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #fff;
  text-transform: uppercase;

  // desktop
  @media screen and (min-width: 959px) {
    width: 216px;
  }

  // mobile
  @media screen and (max-width: 959px) {
    width: 80%;
  }
`
