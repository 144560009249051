import axios from 'src/app/axios-client'
import originAxios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectType, RequestProject, TaskType } from './request.slice'
import moment from 'moment'
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { LOCALFILE_UPLOAD_BUCKET, LOCALFILE_UPLOAD_EXPIRES } from 'src/app/constants'
interface ProjectAmountParams {
  projectType: ProjectType
  taskType: TaskType
  runtime: number
  sourceLanguageCode: string
  targetLanguageCode: string[]
}

export const getPayment = createAsyncThunk(
  'request/get-payment',
  async (params: ProjectAmountParams) => {
    const response = await axios.post(`/api/pika/projects/amount`, params)
    const data = response.data
    return {
      amount: data.amountClient,
      tasks: data.tasks,
    }
  },
)

interface CreateProjectGroupParams {
  projects: RequestProject[]
}

export const createProjectGroup = createAsyncThunk(
  'request/create-project-group',
  async (params: CreateProjectGroupParams) => {
    // if (window.location.href.includes('localhost')) {
    //   return {
    //     projectId: 1,
    //     dueAt: null,
    //   }
    // } else {
    const response = await axios.post(`/api/pika/project-group`, params, {
      timeout: 0,
    })
    const data = response.data?.data || response.data
    return {
      projectId: data.id,
      dueAt: params.projects[0].dueAt,
      ...params,
    }
    // }
  },
)

interface GetProjectAmountParams {
  projectType: string
  taskType: string
  runtime: number
  sourceLanguageCode: string
  targetLanguageCode: string[]
  descriptionSource: string
  descriptionTranslationRequested: boolean
}

export const getProjectAmount = createAsyncThunk(
  'request/project-amount',
  async (params: GetProjectAmountParams) => {
    const response = await axios.post(`/api/pika/projects/amount`, params)

    return response.data
  },
)

interface MyRequestsParams {
  take: number
  skip: number
  type?: 'TRANSLATION' | 'TRANSCRIPTION'
  projectId?: number | string
  dateFrom?: Date
  dateTo?: Date
}

export const getMyRequests = createAsyncThunk(
  'request/my-request',
  async (params: MyRequestsParams) => {
    const response = await axios.get(`/api/pika/project-group/my-request`, {
      params: {
        ...params,
        dateTo: params.dateTo
          ? moment(params.dateTo).add(1, 'days').format('YYYY-MM-DD')
          : null,
      },
    })
    const data = response.data?.data || response.data
    return {
      projects: data,
    }
  },
)

interface MyRequestsSearchParams extends MyRequestsParams {
  projectStatus?: 210 | 300 | 400
  taskId?: number | string
}

export const getMyRequestsSearch = createAsyncThunk(
  'request/my-request/search',
  async (params: MyRequestsSearchParams) => {
    const response = await axios.get(
      `/api/pika/project-group/my-request/search`,
      {
        params: {
          ...params,
          dateTo: params.dateTo
            ? moment(params.dateTo).add(1, 'days').format('YYYY-MM-DD')
            : null,
        },
      },
    )

    const data = response.data?.data || response.data
    return {
      tasks: data,
    }
  },
)

interface MyRequestSubtitlesParams {
  projectId: number
}

export const getMyRequestSubtitles = createAsyncThunk(
  'request/my-request-subtitles',
  async (params: MyRequestSubtitlesParams) => {
    const response = await axios.get(
      `/api/pika/projects/${params.projectId}/results`,
    )
    return {
      files: response.data?.records || [],
    }
  },
)

export const getRates = createAsyncThunk('request/amountRates', async () => {
  const response = await axios.get(
    `/api/pika/p/project-task-rate/rates?projectType=SUBTITLE_HUB`,
  )

  const res = {
    translation: response.data.data.filter(
      (value) => value.taskType === 'TRANSLATION',
    )[0].rate,
    transcription: response.data.data.filter(
      (value) => value.taskType === 'TRANSCRIPTION',
    )[0].rate,
    descLess: response.data.data.filter(
      (value) => value.taskType === 'DESCRIPTION_TRANSLATION',
    )[0].rate,
  }

  // return response.data
  return res
})

export const getPresignedUrl = async (uploadPath: string) => {
  // https://api-dev.gloground.com/api/media-convert/glosub/presigned-url?mode=put&bucketName=glo-admin-test&keyName=test.mov&expires=600
  const { data } = await axios.get(
    `/api/media-convert/glosub/presigned-url?mode=put&bucketName=${LOCALFILE_UPLOAD_BUCKET}&keyName=${uploadPath}&expires=${LOCALFILE_UPLOAD_EXPIRES}`,
  )

  return data
}

export const uploadLocalVideoFile = async (presignURL: string, file: any) => {
  // const [progress, setProgress] = useState<number[]>([])
  // let index = 0
  // let newArr = [...progress]

  const config = {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: (progressEvent) => {
      // return progressEvent
      const progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100,
      )
    }
  }
  const { data } = await originAxios.put(
    presignURL, file, config
  )
  return data
}

export const getVideoDuration = async (uploadPath: string) => {
  const { data } = await axios.get(
    `/api/media-convert/glosub/video-runtime?bucketName=${LOCALFILE_UPLOAD_BUCKET}&uploadPath=${uploadPath}`,
  )
  return data
}

interface localUploadParams {
  uploadPath: string
  data: any
}

export const uploadLocalVideoProcess = createAsyncThunk(
  'request/request-localfile', async (params: localUploadParams) => {
    const presignURL = await getPresignedUrl(params.uploadPath)
    const response = await uploadLocalVideoFile(presignURL, params.data)
    const runtime = await getVideoDuration(params.uploadPath)
    // upload 성공실패 체크는 어떻게 하지?
    
    return {
      id: null,
      kind: null,
      etag: null,
      title: params.data.name,
      description: '',
      descriptionLength: 0,
      publishedAt: null,
      channelId: null,
      categoryId: null,
      thumbnail: null,
      defaultLanguage: null,
      defaultAudioLanguage: null,
      runtime,
      caption: null,
      url: `${params.uploadPath}`
    }
  },
)