import styles from './loading.module.scss'

export default function Loading() {
  return (
    <div className={styles.overlay}>
      <div className={styles.loading}>
        <div className={styles.wrap}>
          <div className={styles['loading-img']}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/logo-creator.svg`}
              alt="gloz-logo"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
