import styled from 'styled-components'

export const ModalWrapper = styled.div`
  margin: 25px;
  background: #fff;
  border-radius: 8px;

  @media (max-width: 375px) {
    padding: 40px 20px 28px;
    width: 100%;
  }

  @media (min-width: 375.02px) and (max-width: 744px) {
    padding: 40px 35px 28px;
  }

  @media (min-width: 744.02px) {
    padding: 50px 57px 35px;
  }
`

export const ModalButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  button {
    width: 100%;
  }

  @media (max-width: 744px) {
    margin-top: 28px;
    flex-direction: column;
  }

  @media (min-width: 744.02px) {
    margin-top: 35px;
  }
`
