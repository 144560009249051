import { createContext, ReactNode, useEffect, useState } from 'react'
import { setHeaderToken } from 'src/app/axios-client'
import { useAppDispatch } from 'src/app/hooks'
import { useRefreshAccessTokenQuery } from 'src/features/auth/auth.service'
import { getProfile, postSocial } from 'src/features/profile/profile.service'

const INTERVAL_OPTIONS = {
  OFF: 0,
  TEN_MINUTES: 600000,
  HOUR: 3600000,
  DAY: 86400000,
}

interface Props {
  children: ReactNode
}

type AuthContextType = {
  isLoggedIn: boolean
  isCreator: boolean
  setIsLoggedIn: Nullable<React.Dispatch<React.SetStateAction<boolean>>>
  setPollingInterval: Nullable<React.Dispatch<React.SetStateAction<number>>>
}

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  isCreator: true,
  setIsLoggedIn: null,
  setPollingInterval: null,
})

export default function AuthProvider(props: Props) {
  const [pollingInterval, setPollingInterval] = useState(
    INTERVAL_OPTIONS.TEN_MINUTES,
  )
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isCreator, setIsCreator] = useState(false)
  const { data, isSuccess, refetch, isError } = useRefreshAccessTokenQuery(
    null,
    {
      pollingInterval,
    },
  )
  const dispatch = useAppDispatch()
  const isVerified = sessionStorage.getItem('isVerified')
  const promotionCode = sessionStorage.getItem('promotionCode')

  const checkSocialSign = () => {
    return (
      sessionStorage.getItem('socialSign') === 'true' && isVerified === 'true'
    )
  }

  const initProfile = () => {
    dispatch(getProfile({ update: true }))
      .unwrap()
      .then((res) => {
        setIsCreator(true)
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          setIsCreator(false)
          sessionStorage.removeItem('gat')
        }
      })
  }

  useEffect(() => {
    // console.log("useEffect - isSuccess, data : " + data)
    if (data?.accessToken) {
      setHeaderToken(data.accessToken)
      setIsLoggedIn(true)
    } else {
      const token = sessionStorage.getItem('accessToken')
      if (token) {
        setHeaderToken(token)
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)
      }
    }
  }, [isSuccess, data])

  useEffect(() => {
    if (isLoggedIn) {
      if (checkSocialSign()) {
        dispatch(postSocial({ code: promotionCode }))
          .then((res) => {
            initProfile()
          })
          .finally(() => {})
      } else {
        initProfile()
      }
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isError) {
      setIsLoggedIn(false)
    }
  }, [isError])

  // Add the following to re-try the query when it fails with 401 error
  useEffect(() => {
    if (!isSuccess) {
      refetch()
    }
  }, [isSuccess])

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isCreator,
        setIsLoggedIn,
        setPollingInterval,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
