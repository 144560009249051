import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import request from 'src/features/request/request.slice'
import youtube from 'src/features/youtube/youtube.slice'
import credit from 'src/features/credit/credit.slice'
import auth from 'src/features/auth/auth.slice'
import video from 'src/features/video/video.slice'
import profile from '../features/profile/profile.slice'
import payment from 'src/features/payment/payment.slice'
import main from 'src/features/main/main.slice'

import { authApi } from 'src/features/auth/auth.service'

export const store = configureStore({
  reducer: {
    auth,
    [authApi.reducerPath]: authApi.reducer,
    request,
    youtube,
    credit,
    profile,
    video,
    payment,
    main,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
