import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import {
  disconnectYoutube,
  getYoutubeChannelInfo,
  getYoutubeChannelVideos,
  getYoutubeSubtitle,
} from './video.service'

export type VideoInfoType = Array<{
  id: string
  kind: string
  etag: string
  title: string
  description: string
  publishedAt: string
  channelId: string
  thumbnail: string
  url: string
}>

export type SubtitleType = {
  id: string
  videoId: string
  language: string
}

type VideoState = {
  playListId: string
  sourceData: VideoInfoType
  subtitles: SubtitleType[]
  channelTitle: string
  profileImage: string
  pageInfo: {
    totalResults: number
    resultsPerPage: number
    nextPageToken?: string
    prevPageToken?: string
  }
  isLoading: boolean
  error: any
}

const initialState: VideoState = {
  playListId: null,
  sourceData: [],
  subtitles: [],
  isLoading: false,
  error: null,
  channelTitle: '',
  profileImage: '',
  pageInfo: {
    totalResults: 0,
    resultsPerPage: 0,
  },
}

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getYoutubeChannelVideos.pending, (state, { payload }) => {
      state.isLoading = true
      state.error = false
    })
    builder.addCase(
      getYoutubeChannelVideos.fulfilled,
      (state, { payload }: any) => {
        state.sourceData = payload.videoInfo.sourceData
        state.channelTitle = payload.videoInfo.channelTitle
        state.pageInfo = payload.videoInfo.pageInfo
        state.isLoading = false
        state.error = false
      },
    )
    builder.addCase(getYoutubeChannelVideos.rejected, (state, action) => {
      state.sourceData = initialState.sourceData
      state.channelTitle = ''
      state.pageInfo = initialState.pageInfo
      state.isLoading = false
      state.error = true
    })
    /* get subtitles */
    builder.addCase(getYoutubeSubtitle.pending, (state, { payload }) => {
      state.subtitles = []
      state.isLoading = true
      state.error = false
    })
    builder.addCase(getYoutubeSubtitle.fulfilled, (state, { payload }: any) => {
      state.subtitles = payload.subtitles
      state.isLoading = false
      state.error = false
    })
    builder.addCase(getYoutubeSubtitle.rejected, (state, action) => {
      state.subtitles = []
      state.channelTitle = ''
      state.isLoading = false
      state.error = true
    })
    builder.addCase(disconnectYoutube.pending, (state) => {
      state.isLoading = true
      state.error = false
    })
    builder.addCase(disconnectYoutube.fulfilled, (state, { payload }: any) => {
      state = {
        ...initialState,
      }
    })
    builder.addCase(disconnectYoutube.rejected, (state, action) => {
      state.isLoading = false
      state.error = true
    })
    /* get info */
    builder.addCase(getYoutubeChannelInfo.pending, (state) => {
      state.isLoading = true
      state.error = false
    })
    builder.addCase(
      getYoutubeChannelInfo.fulfilled,
      (state, { payload }: any) => {
        state.playListId = payload.channel.playListId
        state.channelTitle = payload.channel.title
        state.profileImage = payload.channel.profileImage
        state.isLoading = false
        state.error = false
      },
    )
    builder.addCase(getYoutubeChannelInfo.rejected, (state, action) => {
      state.playListId = null
      state.channelTitle = ''
      state.isLoading = false
      state.error = true
    })
  },
})

const selfSelector = (state: RootState) => state.video

export const getChannelTitle = createSelector(
  selfSelector,
  (state) => state.channelTitle,
)

export const getChannelProfileImage = createSelector(
  selfSelector,
  (state) => state.profileImage,
)

export default videoSlice.reducer
