import { createSlice } from '@reduxjs/toolkit'

import { getInicisPaymentInfo } from './payment.service'

export type InicisInfo = {
  mid: string
  alg: string
  signkey: string
}

export interface PaymentInfo {
  inicisInfo?: InicisInfo
  error?: any
  loading: boolean
}

const initialState: PaymentInfo = {
  inicisInfo: {
    mid: null,
    alg: null,
    signkey: null,
  },
  error: false,
  loading: false,
}

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInicisPaymentInfo.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(getInicisPaymentInfo.fulfilled, (state, { payload }: any) => {
        state.inicisInfo = payload
        state.loading = false
        state.error = false
      })
      .addCase(getInicisPaymentInfo.rejected, (state, { payload, error }) => {
        state.inicisInfo = initialState.inicisInfo
        state.loading = false
        state.error = error
      })
  },
})

export default paymentSlice.reducer
