import { Switch } from '@mui/material'
import styled from 'styled-components'
import { styled as muiStyled } from '@mui/material/styles'

export const RadioContainer = styled.div`
  margin-bottom: 18px;
  display: flex;
  gap: 13px;
  justify-content: end;
  .box {
    display: flex;
    gap: 8px;
    .title {
      font-weight: 500;
      font-size: 14px;
    }
  }
`
export const CustomRadio = styled.label`
  margin-left: 4px;
  position: relative;
  min-width: 18px;
  height: 18px;
  /* border: solid 1px #ccc; */
  border-radius: 25px;
  align-self: center;
  input:checked + span {
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    background-color: $white;
    border-radius: 25px;
  }
`

export const AntSwitch = muiStyled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))
