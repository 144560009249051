import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'src/app/axios-client'

interface VerifyCreditParams {
  code: string
}

export const verifyCredit = createAsyncThunk(
  'credit/verify',
  async (params: VerifyCreditParams, thunkAPI) => {
    try {
      const response = await axios.post(
        `api/pika/glohub/credit/verify/${params.code}`,
      )

      return {
        verify: response.data,
        code: params.code,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

interface GetCreditHistoryParams {}

export const getCreditHistory = createAsyncThunk(
  'credit/history',
  async (params: GetCreditHistoryParams) => {
    const response = await axios.get(`/api/pika/glohub/credit/history`)

    return {
      histories: response.data || [],
    }
  },
)

interface ChargeCreditParams {
  projectGroupId: number
  amountToPayByCredit: number
  amountToPayByPg?: number
  pgType?: 'INICIS' | null
}

export const chargeCredit = createAsyncThunk(
  'credit/charge',
  async (params: ChargeCreditParams, thunkAPI) => {
    const { projectGroupId, amountToPayByCredit, amountToPayByPg, pgType } =
      params

    try {
      const response = await axios.post(
        `/api/pika/glohub/credit/${params.projectGroupId}/charge`,
        { projectGroupId, amountToPayByCredit, amountToPayByPg, pgType },
      )

      return {
        result: response.data,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
